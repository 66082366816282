export default function RegProgressBar({ page = 0 }) {
  const Completed = () => {
    return (
      <li className="flex items-center justify-center gap-2 text-brand-primary">
        <span className="rounded bg-green-50 p-1.5 text-green-600">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-3 w-3"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </span>
      </li>
    );
  };

  const InProgress = ({ title, page = 1 }) => {
    return (
      <li className="flex items-center justify-center gap-2 text-main">
        <span className="h-6 w-6 rounded bg-blue-50 text-center text-[10px] font-bold leading-6">
          {page}
        </span>

        <span className="text-lg"> {title}</span>
      </li>
    );
  };
  const NotStarted = ({ title, page = 1 }) => {
    return (
      <li className="flex items-center justify-end gap-2">
        <span className="h-6 w-6 rounded bg-gray-50 text-center text-[10px] font-bold leading-6 text-gray-600">
          {page}
        </span>

        <span> {title} </span>
      </li>
    );
  };

  return (
    <div className="flex justify-center my-6">
      <ol className="flex items-center gap-2 text-xs font-medium text-gray-500 sm:gap-4">
        {page === 1 ? (
          <InProgress title="Account Details" page={1} />
        ) : (
          <Completed />
        )}
        {page === 2 ? (
          <InProgress title="User Details" page={2} />
        ) : page > 2 ? (
          <Completed />
        ) : (
          <NotStarted title="User Details" page={2} />
        )}
        {page === 3 ? (
          <InProgress title="Professional Details" page={3} />
        ) : page > 3 ? (
          <Completed />
        ) : (
          <NotStarted title="Professional Details" page={3} />
        )}
      </ol>
    </div>
  );
}
