import React, { useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const instance = axios.create();

const AxiosInterceptor = ({ children }) => {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();
  const isMountedRef = useRef(true);

  useEffect(() => {
    const resInterceptor = (response) => {
      return response;
    };

    const errInterceptor = (error) => {
      if (error.response.status === 301) {
        if (isMountedRef.current) {
          console.log("navigate to login");
          //setAuth({ ...auth, isAuthenticated: false });
          localStorage.clear();
          navigate("/");
        }
      }
      return Promise.reject(error);
    };

    const interceptor = instance.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );

    return () => {
      instance.interceptors.response.eject(interceptor);
      isMountedRef.current = false;
    };
  }, []);

  return children;
};

export { AxiosInterceptor };
export default instance;
