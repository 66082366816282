import instance from "../../components/AxiosInterceptor";
const API_URL = "/api/admin/";

const getOrganisations = async () => {
  const token = JSON.parse(localStorage.getItem("user")).token;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await instance.get(API_URL + "organisations", config);

  return response.data;
};

const getSingleOrganisation = async (id) => {
  const token = JSON.parse(localStorage.getItem("user")).token;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await instance.get(API_URL + "organisation/" + id, config);

  return response.data;
};

const getCountUsers = async () => {
  const token = JSON.parse(localStorage.getItem("user")).token;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await instance.get(API_URL + "new-users", config);

  return response.data;
};

const getAllOrgs = async () => {
  const token = JSON.parse(localStorage.getItem("user")).token;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await instance.get(API_URL + "all-organisations", config);

  return response.data;
};

const adminService = {
  getOrganisations,
  getCountUsers,
  getAllOrgs,
  getSingleOrganisation,
};

export default adminService;
