import { saveAs } from "file-saver";
import { Buffer } from "buffer";
import {
  AlignmentType,
  Document,
  Header,
  ImageRun,
  Packer,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  TextRun,
  WidthType,
  convertInchesToTwip,
  HeightRule,
  Footer,
} from "docx";
import imageToBase64 from "image-to-base64/browser";
import moment from "moment";

const generateProfile = async (staff) => {
  const basicLifeSupport = staff.staff.trainings.find(
    (training) => training.name == "Basic Life Support (HCW)"
  );
  const immediateLifeSupport = staff.staff.trainings.find(
    (training) => training.name == "Immediate Life support (RMN)"
  );
  const manualHandling = staff.staff.trainings.find(
    (training) => training.name == "Manual Handling"
  );
  const healthSafety = staff.staff.trainings.find(
    (training) => training.name == "Health and Safety"
  );
  const breakAway = staff.staff.trainings.find(
    (training) => training.name == "Breakaway"
  );
  const violenceAggression = staff.staff.trainings.find(
    (training) => training.name == "(GSA) Management of violence and aggression"
  );
  const infectionControl = staff.staff.trainings.find(
    (training) => training.name == "Infection Control"
  );
  const safeguard = staff.staff.trainings.find(
    (training) => training.name == "Safeguarding (adults and children Level 3)"
  );
  const dataProtection = staff.staff.trainings.find(
    (training) => training.name == "GDPR"
  );
  const mentalAct = staff.staff.trainings.find(
    (training) => training.name == "Mental Health Act"
  );
  const informationGovernance = staff.staff.trainings.find(
    (training) => training.name == "Information Governance"
  );
  const firstAid = staff.staff.trainings.find(
    (training) => training.name == "First Aid Awareness"
  );

  const prevent = staff.staff.trainings.find(
    (training) => training.name == "Prevent Radicalisation"
  );

  const gdpr = staff.staff.trainings.find(
    (training) => training.name == "GDPR"
  );

  const conflict = staff.staff.trainings.find(
    (training) => training.name == "Conflict Management and Complaints Handling"
  );
  const depravation = staff.staff.trainings.find(
    (training) => training.name == "Deprivation of Liberties Safeguards"
  );
  const foodHygiene = staff.staff.trainings.find(
    (training) => training.name == "Food and Hand Hygiene"
  );
  const equality = staff.staff.trainings.find(
    (training) => training.name == "Equality and Diversity"
  );
  const loneworker = staff.staff.trainings.find(
    (training) => training.name == "Lone Worker"
  );
  const coshh = staff.staff.trainings.find(
    (training) => training.name == "Coshh"
  );
  const personCentered = staff.staff.trainings.find(
    (training) => training.name == "Person Centered Care"
  );
  const riddor = staff.staff.trainings.find(
    (training) => training.name == "Riddor"
  );
  const knife = staff.staff.trainings.find(
    (training) => training.name == "Ligature Knife Training"
  );
  const observations = staff.staff.trainings.find(
    (training) => training.name == "Observations"
  );
  const selfharm = staff.staff.trainings.find(
    (training) => training.name == "Working with those who self-harm"
  );
  const riskAssessment = staff.staff.trainings.find(
    (training) => training.name == "Risk Assessment"
  );
  const medicalManagement = staff.staff.trainings.find(
    (training) => training.name == "Medication Management"
  );

  const righttowork = staff.staff.documents.find(
    (document) => document.doctype == "Right to Work"
  );
  const cv = staff.staff.documents.some((document) => document.doctype == "CV");

  const companyId = staff.staff.documents.find(
    (document) => document.doctype == "Company ID"
  );

  const references = staff.staff.references.filter(
    (reference) => reference.terms == "accepted"
  );

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  function mandatoryTableRow(title, value, secondValue = "") {
    return new TableRow({
      children: [
        new TableCell({
          width: {
            size: 50,
            type: WidthType.PERCENTAGE,
          },
          margins: { top: 50, bottom: 50, left: 50 },

          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: title,
                  font: "Calibri",
                  size: 22,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          width: {
            size: 25,
            type: WidthType.PERCENTAGE,
          },
          margins: { top: 50, bottom: 50, left: 50 },

          children: [
            new Paragraph({
              alignment: AlignmentType.LEFT,
              children: [
                new TextRun({
                  text: value,
                  font: "Calibri",
                  size: 22,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          width: {
            size: 60,
            type: WidthType.PERCENTAGE,
          },
          margins: { top: 50, bottom: 50, left: 50 },

          children: [
            new Paragraph({
              alignment: AlignmentType.LEFT,
              children: [
                new TextRun({
                  text: secondValue,
                  font: "Calibri",
                  size: 22,
                }),
              ],
            }),
          ],
        }),
      ],
    });
  }

  function trainingTableRow(title = "", value) {
    return new TableRow({
      children: [
        new TableCell({
          width: {
            size: 50,
            type: WidthType.PERCENTAGE,
          },
          margins: { top: 50, bottom: 50, left: 50 },

          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: title,
                  font: "Calibri",
                  size: 22,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          width: {
            size: 50,
            type: WidthType.PERCENTAGE,
          },
          margins: { top: 50, bottom: 50, left: 50 },

          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: value,
                  font: "Calibri",
                  size: 22,
                }),
              ],
            }),
          ],
        }),
      ],
    });
  }

  function singleTableRow(title = "") {
    return new TableRow({
      children: [
        new TableCell({
          width: {
            size: 100,
            type: WidthType.PERCENTAGE,
          },
          margins: { top: 50, bottom: 50, left: 50 },

          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: title,
                  font: "Calibri",
                  size: 22,
                }),
              ],
            }),
          ],
        }),
      ],
    });
  }

  const logo =
    "https://res.cloudinary.com/dhefsiizy/image/upload/v1682702748/logos/autism-logo-1cbdc36ccf_xxbohx.png";

  const imageLogo = await imageToBase64(logo) // Image URL
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error); // Logs an error if there was one
    });

  function experinceTableRow(
    title = "",
    value = "",
    secondValue = "",
    thirdValue = ""
  ) {
    return new TableRow({
      children: [
        new TableCell({
          width: {
            size: 50,
            type: WidthType.PERCENTAGE,
          },
          margins: { top: 50, bottom: 50, left: 50 },

          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: title,
                  font: "Calibri",
                  size: 16,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          width: {
            size: 25,
            type: WidthType.PERCENTAGE,
          },
          margins: { top: 50, bottom: 50, left: 50 },

          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: value,
                  font: "Calibri",
                  size: 16,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          width: {
            size: 60,
            type: WidthType.PERCENTAGE,
          },
          margins: { top: 50, bottom: 50, left: 50 },

          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: secondValue,
                  font: "Calibri",
                  size: 16,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          width: {
            size: 60,
            type: WidthType.PERCENTAGE,
          },
          margins: { top: 50, bottom: 50, left: 50 },

          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: thirdValue,
                  font: "Calibri",
                  size: 16,
                }),
              ],
            }),
          ],
        }),
      ],
    });
  }

  const imageBase64 = await imageToBase64(staff.image.path) // Image URL
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error); // Logs an error if there was one
    });

  function textRun(text, bold = false) {
    return new TextRun({
      font: "Calibri",
      size: 20,
      text: text,
      bold: bold,
    });
  }

  function paragraph(text, bold = false) {
    return new Paragraph({
      children: [textRun(text, bold)],
    });
  }

  const title = (text) => {
    return new Paragraph({
      border: {
        bottom: {
          color: "ADD8E6",
          space: 1,
          style: "single",
          size: 5,
        },
      },
      alignment: AlignmentType.RIGHT,
      children: [
        new TextRun({
          text: text,
          font: "Calibri",
          size: 24,
          alignment: AlignmentType.RIGHT,
          italics: true,
          bold: true,
        }),
      ],
    });
  };

  const doc = new Document({
    sections: [
      {
        properties: {
          page: {
            margin: {
              right: convertInchesToTwip(0.5),
              left: convertInchesToTwip(0.5),
              bottom: convertInchesToTwip(0.2),
              top: convertInchesToTwip(0.5),
            },
          },
          column: {
            space: 708,
            count: 2,
          },
        },

        headers: {
          default: new Header({
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "AISPF25102021",
                    font: "Calibri",
                    size: 20,
                    color: "D3D3D3",
                  }),
                ],
                indent: {
                  left: convertInchesToTwip(0.5),
                },
              }),
              new Paragraph({
                indent: {
                  left: convertInchesToTwip(2),
                },
                children: [
                  new ImageRun({
                    data: Buffer.from(imageLogo, "base64"),
                    transformation: {
                      width: 160,
                      height: 80,
                    },
                  }),
                ],
              }),
            ],
          }),
        },
        footers: {
          default: new Footer({
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `Issue Date: ${moment().format("DD/MM/YYYY")}`,
                  }),
                  new TextRun({
                    text: "                                         ",
                  }),
                  new TextRun({
                    //one year from issue date
                    text: `Review date: ${moment()
                      .add(1, "years")
                      .format("DD/MM/YYYY")}`,
                  }),
                ],
              }),
            ],
          }),
        },
        children: [
          paragraph(""),
          new Paragraph({
            children: [
              new TextRun({
                text: "Staff Profile Form",
                font: "Calibri",
                size: 44,
                bold: true,
              }),
            ],
          }),
          paragraph(""),
          title("Personal Details"),
          paragraph(""),
          new Table({
            width: {
              size: 30,
              type: WidthType.PERCENTAGE,
            },
            rows: [
              new TableRow({
                height: {
                  value: convertInchesToTwip(1),
                  rule: HeightRule.EXACTLY,
                },
                children: [
                  new TableCell({
                    borders: {
                      top: {
                        color: "0000FF",
                        space: 1,
                        style: "single",
                      },
                      left: {
                        color: "0000FF",
                        space: 1,
                        style: "single",
                      },
                      right: {
                        color: "0000FF",
                        space: 1,
                        style: "single",
                      },
                      bottom: {
                        color: "0000FF",
                        space: 1,
                        style: "single",
                      },
                    },
                    width: {
                      size: 30,
                      type: WidthType.PERCENTAGE,
                    },
                    children: [
                      new Paragraph({
                        border: {
                          borders: {
                            top: {
                              color: "0000FF",
                              space: 1,
                              style: "single",
                            },
                            left: {
                              color: "0000FF",
                              space: 1,
                              style: "single",
                            },
                            right: {
                              color: "0000FF",
                              space: 1,
                              style: "single",
                            },
                            bottom: {
                              color: "0000FF",
                              space: 1,
                              style: "single",
                            },
                          },
                        },
                        children: [
                          new ImageRun({
                            data: Buffer.from(imageBase64, "base64"),
                            transformation: {
                              width: 100,
                              height: 100,
                            },
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
            ],
          }),
          paragraph(""),
          title("Mandatory Compliance"),

          //table start
          new Table({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            rows: [
              mandatoryTableRow("Name:", `${staff.name}`, `${staff.surname}`),
              mandatoryTableRow("Position:", `${staff.staff.role}`),
              mandatoryTableRow(
                "Start Date:",
                `${moment(staff.dateAdded).format("DD/MM/YYYY")}`
              ),
              mandatoryTableRow("21+:", "yes"),
              mandatoryTableRow(
                "Children & Adult Enhanced Disclosure Number:",
                `${staff.staff.dbsnumber ? staff.staff.dbsnumber : "N/A"}

                ${
                  staff.staff.dbsissue
                    ? moment(staff.staff.dbsissue).format("DD/MM/YYYY")
                    : "N/A"
                }
                `
              ),
              mandatoryTableRow("Transferable DBS certificate Number:", "N/A"),
              mandatoryTableRow("Information to Disclose:", "none"),
              mandatoryTableRow("Disciplinary/ Investigations:", "none"),
              mandatoryTableRow(
                "Reference 1:",
                `${references.length > 0 ? "Yes" : "No"}`
              ),
              mandatoryTableRow(
                "Reference 2:",
                `${references.length > 1 ? "Yes" : "No"}`
              ),
              mandatoryTableRow(
                "Reference 3:( if the first two do not cover 5 years)",
                "N/A"
              ),
              mandatoryTableRow(
                "Work History (with any gaps explained)",
                `${cv ? "Yes" : "No"}`
              ),
              mandatoryTableRow(
                "Evidence of Right to Work in the UK and details of any visa restrictions:",
                `${righttowork ? "Yes" : "No"}`
              ),
              mandatoryTableRow("Driver yes or no?", ""),
              mandatoryTableRow(
                `Covid 19 Vaccination
              Date of Dose 1:
              Date of Dose 2:
              `,
                `Yes
              Yes
              Yes`
              ),
              mandatoryTableRow(
                "Confirmation of Negative Lateral Flow Test",
                "hello"
              ),
            ],
          }),
          paragraph(""),
          title("Experience"),
          paragraph(""),
          new Table({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            rows: [
              experinceTableRow("", "None", "Limited", "Experienced"),
              experinceTableRow("Support Worker", "", "", ""),
              experinceTableRow("Complex Care", "", "", ""),
              experinceTableRow("Child Care Worker", "", "", ""),
              experinceTableRow("Behaviours of Concern", "", "", ""),
            ],
          }),

          paragraph(""), // Empty cell
          new Table({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            rows: [
              new TableRow({
                height: {
                  value: convertInchesToTwip(1),
                  rule: HeightRule.EXACTLY,
                },
                children: [
                  new TableCell({
                    width: {
                      size: 100,
                      type: WidthType.PERCENTAGE,
                    },
                    children: [paragraph("Testimonials:")],
                  }),
                ],
              }),
            ],
          }),
          paragraph(""),
          title("Manadatory Training"),
          paragraph(""),
          new Table({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            rows: [
              trainingTableRow("", "Date Completed"),
              trainingTableRow(
                "Medication",
                `${
                  medicalManagement
                    ? moment(medicalManagement.dateTrained).format("DD/MM/YYYY")
                    : ""
                }`
              ),
              trainingTableRow(
                "Safeguarding",
                `${
                  safeguard
                    ? moment(safeguard.dateTrained).format("DD/MM/YYYY")
                    : ""
                }`
              ),
              trainingTableRow(
                "Health and Safety",
                `${
                  healthSafety
                    ? moment(healthSafety.dateTrained).format("DD/MM/YYYY")
                    : ""
                }`
              ),
              trainingTableRow(
                "Food Hygiene",
                `${
                  foodHygiene
                    ? moment(foodHygiene.dateTrained).format("DD/MM/YYYY")
                    : ""
                }`
              ),
              trainingTableRow(
                "Fire Safety",
                `${
                  safeguard
                    ? moment(safeguard.dateTrained).format("DD/MM/YYYY")
                    : ""
                }`
              ),
              trainingTableRow(
                "Manual Handling",
                `${
                  manualHandling
                    ? moment(manualHandling.dateTrained).format("DD/MM/YYYY")
                    : ""
                }`
              ),
              trainingTableRow(
                "Infection Control",
                `${
                  infectionControl
                    ? moment(infectionControl.dateTrained).format("DD/MM/YYYY")
                    : ""
                }`
              ),
              trainingTableRow(
                "First Aid",
                `${
                  firstAid
                    ? moment(firstAid.dateTrained).format("DD/MM/YYYY")
                    : ""
                }`
              ),
            ],
          }),
          paragraph(""),
          title("Specific Training (if required)"),
          paragraph(""),
          new Table({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            rows: [
              trainingTableRow("", "Date Completed"),
              trainingTableRow("Epilepsy & Buccal Midazolam", "N/A"),
              trainingTableRow("Diabetes", "N/A"),
            ],
          }),
          paragraph(""),
          new Table({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            rows: [
              singleTableRow(
                "Health & Social Care Qualification/Diploma Status"
              ),
              singleTableRow("N/A"),
            ],
          }),
          paragraph(""),
          title("Service specific requirements"),
          paragraph(""),
          new Table({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            rows: [
              new TableRow({
                height: {
                  value: convertInchesToTwip(1),
                  rule: HeightRule.EXACTLY,
                },
                children: [
                  new TableCell({
                    width: {
                      size: 100,
                      type: WidthType.PERCENTAGE,
                    },
                    children: [paragraph("")],
                  }),
                ],
              }),
            ],
          }),
        ],
      },
    ],
  });
  Packer.toBlob(doc)
    .then((blob) => {
      saveAs(blob, "Profile.docx");
    })
    .catch((error) => {
      console.log(error);
    });
};

export default generateProfile;
