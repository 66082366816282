import React from "react";

function StepTwo({ values, handleChange }) {
  let idLabel;
  let idIssueOffice;

  switch (values.proofOfId) {
    case "Passport":
      idLabel = "Passport number";
      break;
    case "ID":
      idLabel = "ID number";
      break;
    case "Drivers license":
      idLabel = "Drivers license number";
      break;

    default:
      idLabel = "Select ID type";
      break;
  }

  switch (values.proofOfId) {
    case "Passport":
      idIssueOffice = "Passport issue office";
      break;
    case "ID":
      idIssueOffice = "ID issue office";
      break;
    case "Drivers license":
      idIssueOffice = "Drivers license issue office";
      break;

    default:
      idIssueOffice = "Select ID type";
      break;
  }
  return (
    <React.Fragment>
      <div className="sm:flex sm:space-x-3 space-y-3 sm:space-y-0">
        <div className="w-full">
          <label className="font-semibold text-xl" htmlFor="gender">
            Sex
          </label>
          <br />

          <select
            name="gender"
            id="gender"
            onChange={handleChange("gender")}
            required
            className="w-full"
          >
            <option value={values.gender}>{values.gender}</option>
            <option value="female">Female</option>
            <option value="male">Male</option>
            <option value="non-binary">Non-Binary</option>
            <option value="other">Other</option>
            <option value="Prefer not to answer">Perfer not to Answer</option>
          </select>
        </div>
      </div>
      <div className="sm:flex sm:space-x-3 space-y-3 sm:space-y-0">
        <div className="w-full">
          <label className="font-semibold text-xl" htmlFor="righttowork">
            Right to work
          </label>
          <br />

          <select
            name="righttowork"
            id="righttowork"
            onChange={handleChange("righttowork")}
            required
            className="w-full"
          >
            <option value={values.righttowork}>{values.righttowork}</option>
            <option value="UK Citizen">UK Citizen</option>
            <option value="EU/EEA Citizen">EU/EEA Citizen</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <div className="w-full">
          <label className="font-semibold text-xl" htmlFor="region">
            Prefered region
          </label>
          <br />

          <select
            name="region"
            id="postcode"
            onChange={handleChange("region")}
            required
            className="w-full"
          >
            <option value={values.region}>{values.region}</option>
            <option selected>select</option>
            <option value="Eastern England">Eastern England</option>
            <option value="North East">North East</option>
            <option value="North West">North West</option>
            <option value="South East">South East</option>
            <option value="South West">South West</option>
            <option value="South Yorkshire">South Yorkshire</option>
            <option value="East Midlands">East Midlands</option>
            <option value="London">London</option>
            <option value="West Yorkshire">West Yorkshire</option>
            <option value="West Midlands">West Midlands</option>
            <option value="Wales">Wales</option>
            <option value="Wessex">Wessex</option>
          </select>
        </div>
      </div>
      {values.righttowork != "UK Citizen" && (
        <div className="sm:flex sm:space-x-3 space-y-3 sm:space-y-0">
          <div className="w-full">
            <label className="font-semibold text-xl" htmlFor="visanumber">
              Visa Number
            </label>
            <br />

            <input
              type="text"
              name="visanumber"
              id="visanumber"
              onChange={handleChange("visanumber")}
              value={values.visanumber}
              className="w-full"
            />
          </div>
          <div className="w-full">
            <label className="font-semibold text-xl" htmlFor="righttowork">
              Visa Restrictions
            </label>
            <br />

            <select
              name="visarestrictions"
              id="visarestrictions"
              onChange={handleChange("visarestrictions")}
              className="w-full"
            >
              <option value={values.visarestrictions}>
                {values.visarestrictions}
              </option>
              <option value="None">None</option>

              <option value="Other">Other</option>
            </select>
          </div>
          <div className="w-full">
            <label className="font-semibold text-xl" htmlFor="visanumber">
              Visa Expiry Date
            </label>
            <br />

            <input
              type="date"
              name="visaexpiry"
              id="visaexpiry"
              onChange={handleChange("visaexpiry")}
              value={new Date(values.visaexpiry).toISOString().split("T")[0]}
              className="w-full"
            />
          </div>
        </div>
      )}
      <div className="sm:flex sm:space-x-3 space-y-3 sm:space-y-0">
        <div className="w-full">
          <label className="font-semibold text-xl" htmlFor="housenumber">
            House Number
          </label>
          <br />

          <input
            type="text"
            name="houseaddress"
            id="houseaddress"
            onChange={handleChange("houseaddress")}
            value={values.houseaddress}
            className="w-full"
            autoComplete="off"
            required
          />
        </div>
        <div className="w-full">
          <label className="font-semibold text-xl" htmlFor="streetaddress">
            Street name
          </label>
          <br />

          <input
            type="text"
            name="streetaddress"
            id="streetaddress"
            onChange={handleChange("streetaddress")}
            value={values.streetaddress}
            className="w-full"
            autoComplete="off"
            required
          />
        </div>
      </div>
      <div className="sm:flex sm:space-x-3 space-y-3 sm:space-y-0">
        <div className="w-full">
          <label className="font-semibold text-xl" htmlFor="housenumber">
            City
          </label>
          <br />

          <input
            type="text"
            name="city"
            id="city"
            onChange={handleChange("city")}
            value={values.city}
            className="w-full"
            autoComplete="off"
            required
          />
        </div>
        <div className="w-full">
          <label className="font-semibold text-xl" htmlFor="zip">
            Postal code
          </label>
          <br />

          <input
            type="text"
            name="zip"
            id="zip"
            onChange={handleChange("zip")}
            value={values.zip}
            className="w-full"
            autoComplete="off"
            required
          />
        </div>
      </div>
      <div className=" sm:flex sm:space-x-3 space-y-3 sm:space-y-0">
        <label className="font-semibold text-xl" htmlFor="proofOfId">
          Proof of ID
        </label>
        <div className=" sm:flex sm:space-x-5 w-full">
          <div className=" flex items-center ">
            <input
              onChange={handleChange("proofOfId")}
              type="radio"
              name="proofOfId"
              id="passport"
              value="Passport"
              className=" mr-2 cursor-pointer rounded-full"
            />
            <label className="font-semibold text-xl" htmlFor="proofOfId">
              Passport
            </label>
          </div>
          <div className=" flex items-center ">
            <input
              onChange={handleChange("proofOfId")}
              type="radio"
              name="proofOfId"
              id="Driverslicense"
              value="Drivers license"
              className=" mr-2 cursor-pointer rounded-full"
            />
            <label className="font-semibold text-xl" htmlFor="proofOfId">
              Driver's license
            </label>
          </div>
          <div className=" flex items-center ">
            <input
              onChange={handleChange("proofOfId")}
              type="radio"
              name="proofOfId"
              id="ID"
              value="ID"
              className=" mr-2 cursor-pointer rounded-full"
            />
            <label className="font-semibold text-xl" htmlFor="proofOfId">
              ID
            </label>
          </div>
        </div>
      </div>
      <div className=" sm:flex sm:space-x-3 space-y-3 sm:space-y-0">
        <div className="w-full">
          <label className="font-semibold text-xl" htmlFor="passportnumber">
            {idLabel}
          </label>
          <br />

          <input
            type="text"
            name="passportnumber"
            id="passportnumber"
            onChange={handleChange("passportnumber")}
            value={values.passportnumber}
            className="w-full"
            autoComplete="off"
          />
        </div>
        <div className="w-full">
          <label className="font-semibold text-xl" htmlFor="passportissue">
            {idIssueOffice}
          </label>
          <br />

          <input
            type="text"
            name="passportissue"
            id="passportissue"
            onChange={handleChange("passportissue")}
            value={values.passportissue}
            className="w-full"
            autoComplete="off"
          />
        </div>
      </div>
      {values.proofOfId === "Passport" && (
        <div className="sm:flex sm:space-x-3 space-y-3 sm:space-y-0">
          <div className="w-full">
            <label className="font-semibold text-xl" htmlFor="passportissue">
              Passport Issue Date
            </label>
            <br />

            <input
              type="date"
              name="passportissue"
              id="passportissue"
              onChange={handleChange("passportissue")}
              className="w-full"
              autoComplete="off"
            />
          </div>
          <div className="w-full">
            <label className="font-semibold text-xl" htmlFor="passportexpiry">
              Passport expiry
            </label>
            <br />

            <input
              type="date"
              name="passportexpiry"
              id="passportexpiry"
              onChange={handleChange("passportexpiry")}
              value={
                new Date(values.passportexpiry).toISOString().split("T")[0]
              }
              className="w-full"
              autoComplete="off"
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default StepTwo;
