import React, { useEffect, useState } from "react";
import { FaHome } from "react-icons/fa";
import { BsFillPeopleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getOrganisation } from "../features/Organisation/organisationSlice";
import { logout, reset } from "../features/Auth/userSlice";
import Spinner from "../components/UI/Spinner";
import Home from "../components/Dashboard/Home";
import Staff from "../components/Dashboard/Staff";
import Groups from "../components/Dashboard/Groups";
import StaffReports from "../components/Dashboard/StaffReports";
import Admins from "../components/Dashboard/Admins";
import Archived from "../components/Dashboard/archived";

function Dashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { organisation, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.organisation
  );

  let token;
  if (user) {
    token = user.token;
  }

  const [page, setPage] = useState("home");

  useEffect(() => {
    if (!user) {
      navigate("/");
    }
    if (user) {
      // TODO LOAD ORGANISATION
      dispatch(getOrganisation(user.user.organisation));
    }
  }, []);

  if (!user) {
    return <Spinner />;
  }
  if (!organisation) {
    return <Spinner />;
  }

  // *ORGANISATION VARIABLES
  const company = organisation.organisation;

  return (
    <React.Fragment>
      <div className=" h-screen flex flex-col">
        <div className="flex flex-grow space-x-1">
          <div className=" p-2  ">
            <div className="sticky top-5 space-y-3">
              <div className="p-2 ">
                {company.image ? (
                  <img
                    crossOrigin="anonymous"
                    src={company.image.path}
                    alt=""
                    srcset=""
                    width="150px"
                    height="auto"
                  />
                ) : (
                  <p>{company.organisation}</p>
                )}
              </div>
              <div>
                <button
                  onClick={() => {
                    setPage("home");
                  }}
                  className={
                    page === "home"
                      ? " border-l-4 border-l-main2 flex space-x-2 items-center text-left w-full  text-white p-2 text-lg hover:scale-105 bg-main rounded-lg "
                      : " flex space-x-2 items-center text-left w-full  text-white p-2 text-lg hover:scale-105 bg-main rounded-lg"
                  }
                >
                  <FaHome /> <h1>Home</h1>
                </button>
              </div>
              <div>
                <button
                  onClick={() => {
                    setPage("staff");
                  }}
                  className={
                    page === "staff"
                      ? " border-l-4 border-l-main2 flex space-x-2 items-center text-left w-full  text-white p-2 text-lg hover:scale-105 bg-main rounded-lg "
                      : " flex space-x-2 items-center text-left w-full  text-white p-2 text-lg hover:scale-105 bg-main rounded-lg"
                  }
                >
                  <BsFillPeopleFill /> <h1>Staff</h1>
                </button>
              </div>
              <div>
                <button
                  onClick={() => {
                    setPage("groups");
                  }}
                  className={
                    page === "groups"
                      ? " border-l-4 border-l-main2 flex space-x-2 items-center text-left w-full  text-white p-2 text-lg hover:scale-105 bg-main rounded-lg "
                      : " flex space-x-2 items-center text-left w-full  text-white p-2 text-lg hover:scale-105 bg-main rounded-lg"
                  }
                >
                  <BsFillPeopleFill /> <h1>Groups</h1>
                </button>
              </div>
              <div>
                <button
                  onClick={() => {
                    setPage("admins");
                  }}
                  className={
                    page === "admins"
                      ? " border-l-4 border-l-main2 flex space-x-2 items-center text-left w-full  text-white p-2 text-lg hover:scale-105 bg-main rounded-lg "
                      : " flex space-x-2 items-center text-left w-full  text-white p-2 text-lg hover:scale-105 bg-main rounded-lg"
                  }
                >
                  <BsFillPeopleFill /> <h1>Admins</h1>
                </button>
              </div>
              <div>
                <button
                  onClick={() => {
                    setPage("staffreports");
                  }}
                  className={
                    page === "staffreports"
                      ? " border-l-4 border-l-main2 flex space-x-2 items-center text-left w-full  text-white p-2 text-lg hover:scale-105 bg-main rounded-lg "
                      : " flex space-x-2 items-center text-left w-full  text-white p-2 text-lg hover:scale-105 bg-main rounded-lg"
                  }
                >
                  <BsFillPeopleFill /> <h1>Reports</h1>
                </button>
              </div>
              <div>
                <button
                  onClick={() => {
                    setPage("archived");
                  }}
                  className={
                    page === "archived"
                      ? " border-l-4 border-l-main2 flex space-x-2 items-center text-left w-full  text-white p-2 text-lg hover:scale-105 bg-main rounded-lg "
                      : " flex space-x-2 items-center text-left w-full  text-white p-2 text-lg hover:scale-105 bg-main rounded-lg"
                  }
                >
                  <BsFillPeopleFill /> <h1>Archived</h1>
                </button>
              </div>

              <div>
                <button
                  onClick={() => {
                    dispatch(logout());
                    navigate("/");
                    dispatch(reset());
                  }}
                  className="font-semibold shadow  w-full   rounded-lg  bg-white border-main border-2  py-2 px-3 text-main hover:bg-main"
                >
                  Sign out
                </button>
              </div>
            </div>
          </div>
          <div className="w-full bg-[#f2f2f2] overflow-hidden">
            <div className="overflow-y-scroll">
              {page === "home" && (
                <Home user={user.user} organisation={company} />
              )}
              {page === "staff" && (
                <Staff user={user.user} organisation={company} />
              )}

              {page === "groups" && (
                <Groups user={user.user} organisation={company} token={token} />
              )}
              {page === "admins" && (
                <Admins user={user.user} organisation={company} token={token} />
              )}
              {page === "staffreports" && (
                <StaffReports user={user.user} organisation={company} />
              )}
              {page === "archived" && (
                <Archived user={user.user} organisation={company} />
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Dashboard;
