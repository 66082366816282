import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import Logo from "../../images/CA-logo-rectangle.png";

function NavBar() {
  const [open, setOpen] = useState(false);

  return (
    <nav className="sticky  shadow shadow-sm">
      <div className="bg-white w-screen text-main py-2 px-3 flex justify-between items-center">
        <div>
          <a href="/" target="_blank" rel="noopener noreferrer">
            <img src={Logo} alt="" srcset="" height="auto" width="150px" />
          </a>
        </div>
        <div className="shadow-sm p-1 md:hidden">
          <FaBars
            onClick={() => {
              setOpen(!open);
            }}
          />
        </div>
        <div className="hidden md:block text-white">
          <ul className="flex space-x-3">
            {/* <li className="p-1 rounded border-b-1">
                            <a href="#" target="_blank" rel="noopener noreferrer">
                                More
                            </a>
                        </li>
                        <li className="p-1 rounded border-b-1">
                            <a href="#" target="_blank" rel="noopener noreferrer">
                                More
                            </a>
                        </li> */}
            <li className="p-1 rounded border-b-1">
              <a href="/" rel="noopener noreferrer">
                <button className="font-semibold shadow rounded-lg  bg-main hover:bg-main2 py-1 px-3 text-[#FFF]">
                  Sign In
                </button>
              </a>
            </li>
          </ul>
        </div>
      </div>
      {open && (
        <div className="bg-white p-2 m-2 rounded shadow-sm  z-50">
          <ul>
            {/* <li className="p-1 rounded border-b-1">

              <a href="#" target="_blank" rel="noopener noreferrer">
                More
              </a>
            </li>
            <li className="p-1 rounded border-b-1">
              <a href="#" target="_blank" rel="noopener noreferrer">
                More
              </a>

            </li> */}

            <li className="p-1 rounded border-b-1">
              <a href="/" rel="noopener noreferrer">
                <button className=" w-full font-semibold shadow rounded-lg  bg-main hover:bg-main2 py-1 px-3 text-[#FFF]">
                  Sign In
                </button>
              </a>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
}

export default NavBar;
