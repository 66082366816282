import React, { useState } from "react";

function AssignedStaff({ staff }) {
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState("");

  return <>{staff.length <= 0 ? "None assigned" : <></>}</>;
}

export default AssignedStaff;
