import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import NavBar from '../NavBar/NavBar';
import Spinner from '../UI/Spinner';

function ReferenceDownload() {
    const {reference, isLoading, isError, isSuccess, message} = useSelector((state) => state.reference);

    useEffect(() => {
    }, [reference]);

    if (!reference) {
        return <Spinner/>;
    }

    return (
        <React.Fragment>
            <NavBar/>
            <section className="p-5 h-full flex items-center justify-center ">
                <div>
                    <h1 className="text-2xl text-center font-bold">Thank you for your time</h1>
                    <p className=" text-center">A copy of this reference has been sent to your email</p>
                </div>
            </section>
        </React.Fragment>
    );
}

export default ReferenceDownload;
