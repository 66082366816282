import { saveAs } from "file-saver";
import { Buffer } from "buffer";
import {
  AlignmentType,
  Document,
  Header,
  HeadingLevel,
  ImageRun,
  Packer,
  Paragraph,
  SectionType,
  ShadingType,
  Table,
  TableCell,
  TableRow,
  TextRun,
  WidthType,
} from "docx";
import imageToBase64 from "image-to-base64/browser";

const generateCygnetProfile = async (staff) => {
  const dbsExpiry = staff.staff.dbsexpiry
    ? new Date(staff.staff.dbsexpiry).toLocaleDateString("en-GB")
    : "";
  const passportStart = staff.staff.passportstart
    ? new Date(staff.staff.passportstart).toLocaleDateString("en-GB")
    : "";
  const passportExpiry = staff.staff.passportexpiry
    ? new Date(staff.staff.passportexpiry).toLocaleDateString("en-GB")
    : "";
  const nmcExpiry = staff.staff.nmcexpiry
    ? new Date(staff.staff.nmcexpiry).toLocaleDateString("en-GB")
    : "";

  const visaExpiry = staff.staff.visaexpiry
    ? staff.staff.righttowork == "UK Citizen"
      ? ""
      : new Date(staff.staff.visaexpiry).toLocaleDateString("en-GB")
    : "";
  const shading = { type: ShadingType.SOLID, color: "005994" };

  const imageBase64 = await imageToBase64(
    staff.image.path ??
      "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png"
  ) // Image URL
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("If failed");
      console.log(error); // Logs an error if there was one
    });

  //TRAININGS

  const basicLifeSupport = staff.staff.trainings.find(
    (training) => training.name == "Basic Life Support (HCW)"
  );
  const immediateLifeSupport = staff.staff.trainings.find(
    (training) => training.name == "Immediate Life support (RMN)"
  );
  const asbestos = staff.staff.trainings.find(
    (training) => training.name == "Asbestos"
  );
  const healthSafety = staff.staff.trainings.find(
    (training) => training.name == "Health and Safety"
  );
  const legionella = staff.staff.trainings.find(
    (training) => training.name == "Legionella"
  );
  const ladder = staff.staff.trainings.find(
    (training) => training.name == "(GSA) Management of violence and aggression"
  );
  const infectionControl = staff.staff.trainings.find(
    (training) => training.name == "Infection Control"
  );
  const safeguard = staff.staff.trainings.find(
    (training) => training.name == "Safeguarding (adults and children Level 3)"
  );
  const fireSafety = staff.staff.trainings.find(
    (training) => training.name == "Fire Awareness"
  );
  const mentalAct = staff.staff.trainings.find(
    (training) => training.name == "Mental Health Act"
  );
  const informationGovernance = staff.staff.trainings.find(
    (training) => training.name == "Information Governance"
  );

  const moving = staff.staff.trainings.find(
    (training) => training.name == "Moving & Handling"
  );

  const dysphagia = staff.staff.trainings.find(
    (training) => training.name == "Dysphagia"
  );

  const conflict = staff.staff.trainings.find(
    (training) => training.name == "Conflict Management and Complaints Handling"
  );
  const si = staff.staff.trainings.find(
    (training) =>
      training.name == "MAPA" ||
      training.name ==
        "SI (compliant with Mental Health Units Use of Force 2018 Act)"
  );
  const foodHygiene = staff.staff.trainings.find(
    (training) => training.name == "Food and Hand Hygiene"
  );
  const equality = staff.staff.trainings.find(
    (training) => training.name == "Equality and Diversity"
  );
  const loneworker = staff.staff.trainings.find(
    (training) => training.name == "Lone Worker"
  );
  const coshh = staff.staff.trainings.find(
    (training) => training.name == "Coshh"
  );
  const riddor = staff.staff.trainings.find(
    (training) => training.name == "Riddor"
  );
  const knife = staff.staff.trainings.find(
    (training) => training.name == "Ligature Knife Training"
  );
  const observations = staff.staff.trainings.find(
    (training) => training.name == "Observations"
  );
  const pmva = staff.staff.trainings.find(
    (training) => training.name == "PMVA"
  );
  const dols = staff.staff.trainings.find(
    (training) => training.name == "DOLS"
  );
  const medicalManagement = staff.staff.trainings.find(
    (training) => training.name == "Medication Management"
  );

  // TRAINING ROWS
  function trainingRow(name, training) {
    return new TableRow({
      children: [
        new TableCell({
          width: {
            size: 50,
            type: WidthType.PERCENTAGE,
          },
          margins: { top: 50, bottom: 50, left: 50 },
          shading,
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: name,

                  font: "Calibri",
                  size: 22,
                  color: "ffffff",
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          width: {
            size: 25,
            type: WidthType.PERCENTAGE,
          },
          margins: { top: 50, bottom: 50, left: 50 },

          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: training
                    ? new Date(training.datetrained).toLocaleDateString("en-GB")
                    : "",

                  font: "Calibri",
                  size: 22,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          width: {
            size: 60,
            type: WidthType.PERCENTAGE,
          },
          margins: { top: 50, bottom: 50, left: 50 },

          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: training
                    ? new Date(training.expirydate).toLocaleDateString("en-GB")
                    : "",

                  font: "Calibri",
                  size: 22,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          width: {
            size: 60,
            type: WidthType.PERCENTAGE,
          },
          margins: { top: 50, bottom: 50, left: 50 },

          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: training?.verified ? "Yes" : "No",
                  font: "Calibri",
                  size: 22,
                }),
              ],
            }),
          ],
        }),
      ],
    });
  }

  function createTableCell(text, size, shading) {
    return new TableCell({
      width: {
        size: size ? size : 25,
        type: WidthType.PERCENTAGE,
      },
      margins: shading ? { top: 100, bottom: 100, left: 100 } : {},
      shading: shading ? shading : {},
      children: [
        new Paragraph({
          children: [
            new TextRun({
              font: "Century Gothic",
              text: text,
              size: 22,
              color: shading ? "CEDC60" : "000000",
              bold: shading ? true : false,
            }),
          ],
          heading: HeadingLevel.TITLE,
          alignment: AlignmentType.CENTER,
          color: "000000",
        }),
      ],
    });
  }

  const profile = new Document({
    sections: [
      {
        properties: {
          type: SectionType.CONTINUOUS,
        },

        headers: {
          default: new Header({
            children: [
              new Table({
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        width: {
                          size: 3000,
                          type: WidthType.DXA,
                        },
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                font: "Century Gothic",
                                text: staff.organisationName,
                                size: 36,
                                color: "005994",
                                bold: true,
                              }),
                            ],
                            heading: HeadingLevel.TITLE,
                            alignment: AlignmentType.CENTER,
                            color: "000000",
                          }),
                        ],
                      }),
                      new TableCell({
                        width: {
                          size: 3000,
                          type: WidthType.DXA,
                        },
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                font: "Century Gothic",
                                text: "Contractor Profile",
                                size: 36,
                                color: "CEDC60",
                                bold: true,
                              }),
                            ],
                            heading: HeadingLevel.TITLE,
                            alignment: AlignmentType.CENTER,
                            color: "CEDC60",
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    font: "Century Gothic",
                    text: "",
                    size: 22,
                  }),
                ],
              }),
            ],
          }),
        },
        children: [
          new Table({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 33.3,
                      type: WidthType.PERCENTAGE,
                    },
                    margins: { top: 100, bottom: 100, left: 100 },
                    shading: { type: ShadingType.SOLID, color: "005994" },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            font: "Century Gothic",
                            text: "Title",
                            size: 22,
                            bold: true,
                            color: "CEDC60",
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: 33.3,
                      type: WidthType.PERCENTAGE,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            font: "Century Gothic",
                            text: "Mr",
                            size: 22,
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: 33.3,
                      type: WidthType.PERCENTAGE,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            font: "Century Gothic",
                            text: "Photo",
                            size: 22,
                            bold: true,
                            color: "CEDC60",
                          }),
                        ],

                        alignment: AlignmentType.CENTER,
                      }),
                    ],
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 33.3,
                      type: WidthType.PERCENTAGE,
                    },
                    margins: { top: 100, bottom: 100, left: 100 },
                    shading: { type: ShadingType.SOLID, color: "005994" },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            font: "Century Gothic",
                            text: "Forename",
                            size: 22,
                            bold: true,
                            color: "CEDC60",
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: 33.3,
                      type: WidthType.PERCENTAGE,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            font: "Century Gothic",
                            text: staff.name.split(" ")[0],
                            size: 22,
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: 33.3,
                      type: WidthType.PERCENTAGE,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new ImageRun({
                            data: Buffer.from(imageBase64, "base64"),
                            transformation: {
                              width: 200,
                              height: 200,
                            },
                          }),
                        ],
                      }),
                    ],
                    rowSpan: 9,
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 33.3,
                      type: WidthType.PERCENTAGE,
                    },
                    margins: { top: 100, bottom: 100, left: 100 },
                    shading: { type: ShadingType.SOLID, color: "005994" },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            font: "Century Gothic",
                            text: "Surname",
                            size: 22,
                            bold: true,
                            color: "CEDC60",
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: 33.3,
                      type: WidthType.PERCENTAGE,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            font: "Century Gothic",
                            text: staff.surname,
                            size: 22,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 33.3,
                      type: WidthType.PERCENTAGE,
                    },
                    margins: { top: 100, bottom: 100, left: 100 },
                    shading: { type: ShadingType.SOLID, color: "005994" },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            font: "Century Gothic",
                            text: "Middle Name",
                            size: 22,
                            bold: true,
                            color: "CEDC60",
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: 33.3,
                      type: WidthType.PERCENTAGE,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            font: "Century Gothic",
                            text: staff.name.split(" ")[1],
                            size: 22,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 33.3,
                      type: WidthType.PERCENTAGE,
                    },
                    margins: { top: 100, bottom: 100, left: 100 },
                    shading: { type: ShadingType.SOLID, color: "005994" },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            font: "Century Gothic",
                            text: "Known As",
                            size: 22,
                            bold: true,
                            color: "CEDC60",
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: 33.3,
                      type: WidthType.PERCENTAGE,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            font: "Century Gothic",
                            text: `${staff.name} ${staff.surname}`,
                            size: 22,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 33.3,
                      type: WidthType.PERCENTAGE,
                    },
                    margins: { top: 100, bottom: 100, left: 100 },
                    shading: { type: ShadingType.SOLID, color: "005994" },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            font: "Century Gothic",
                            text: "Initials",
                            size: 22,
                            bold: true,
                            color: "CEDC60",
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: 33.3,
                      type: WidthType.PERCENTAGE,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            font: "Century Gothic",
                            text: `${staff.name[0]} ${staff.surname[0]}`,
                            size: 22,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 33.3,
                      type: WidthType.PERCENTAGE,
                    },
                    margins: { top: 100, bottom: 100, left: 100 },
                    shading: { type: ShadingType.SOLID, color: "005994" },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            font: "Century Gothic",
                            text: "NI Number",
                            size: 22,
                            bold: true,
                            color: "CEDC60",
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: 33.3,
                      type: WidthType.PERCENTAGE,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            font: "Century Gothic",
                            text: staff.staff.ninumber,
                            size: 22,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 33.3,
                      type: WidthType.PERCENTAGE,
                    },
                    margins: { top: 100, bottom: 100, left: 100 },
                    shading: { type: ShadingType.SOLID, color: "005994" },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            font: "Century Gothic",
                            text: "Date of Birth",
                            size: 22,
                            bold: true,
                            color: "CEDC60",
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: 33.3,
                      type: WidthType.PERCENTAGE,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            font: "Century Gothic",
                            text: staff.staff.dateofbirth
                              ? new Date(
                                  staff.staff.dateofbirth
                                ).toLocaleDateString("en-GB")
                              : "Not available",
                            size: 22,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 33.3,
                      type: WidthType.PERCENTAGE,
                    },
                    margins: { top: 100, bottom: 100, left: 100 },
                    shading: { type: ShadingType.SOLID, color: "005994" },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            font: "Century Gothic",
                            text: "Gender",
                            size: 22,
                            bold: true,
                            color: "CEDC60",
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: 33.3,
                      type: WidthType.PERCENTAGE,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            font: "Century Gothic",
                            text: staff.staff.gender,
                            size: 22,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 33.3,
                      type: WidthType.PERCENTAGE,
                    },
                    margins: { top: 100, bottom: 100, left: 100 },
                    shading: { type: ShadingType.SOLID, color: "005994" },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            font: "Century Gothic",
                            text: "Nationality",
                            size: 22,
                            bold: true,
                            color: "CEDC60",
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: 33.3,
                      type: WidthType.PERCENTAGE,
                    },
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            font: "Century Gothic",
                            text: staff.staff.nationality,
                            size: 22,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
            ],
          }),
          // ADD Paragraph HERE
          new Paragraph({
            children: [
              new TextRun({
                font: "Century Gothic",
                text: "",
                size: 22,
              }),
            ],
          }),
          // DBS SECTION
          new Table({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            rows: [
              new TableRow({
                children: [
                  createTableCell("DBS Number", 25, shading),
                  createTableCell(staff.staff.dbsnumber),
                  createTableCell("DBS Valid From Date", 30, shading),
                  createTableCell(dbsExpiry),
                ],
              }),
              new TableRow({
                children: [
                  createTableCell("DBS Enhanced", 25, shading),
                  createTableCell(staff.staff.dbslevel),
                  createTableCell(
                    "Received/Subjected to a LADO decision?",
                    30,
                    shading
                  ),
                  createTableCell(""),
                ],
              }),
            ],
          }),
          new Paragraph({
            children: [
              new TextRun({
                font: "Century Gothic",
                text: "",
                size: 22,
              }),
            ],
          }),
          // VACCINE SECTION
          new Table({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            rows: [
              new TableRow({
                children: [
                  createTableCell("COVID Vaccination Brand Name", 25, shading),
                  createTableCell(""),
                  createTableCell("Vaccination #1 Date", 30, shading),
                  createTableCell(""),
                ],
              }),
              new TableRow({
                children: [
                  createTableCell("Vaccination #2 Date", 25, shading),
                  createTableCell(""),
                  createTableCell("Booster Vaccination Date", 30, shading),
                  createTableCell(""),
                ],
              }),
            ],
          }),
          new Paragraph({
            children: [
              new TextRun({
                font: "Century Gothic",
                text: "",
                size: 22,
              }),
            ],
          }),
          // PASSPORT AND VISA
          new Table({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            rows: [
              new TableRow({
                children: [
                  createTableCell("Passport Number", 25, shading),
                  createTableCell(staff.staff.passportnumber),
                  createTableCell("Passport Start Date", 30, shading),
                  createTableCell(passportStart),
                ],
              }),
              new TableRow({
                children: [
                  createTableCell("Passport Issue Office", 25, shading),
                  createTableCell(staff.staff.passportissue),
                  createTableCell("Passport Exp Date", 30, shading),
                  createTableCell(passportExpiry),
                ],
              }),
              new TableRow({
                children: [
                  createTableCell("Visa Number", 25, shading),
                  createTableCell(staff.staff.visanumber),
                  createTableCell("Visa Expiry Date", 30, shading),
                  createTableCell(visaExpiry),
                ],
              }),
              new TableRow({
                children: [
                  createTableCell("Visa Restrictions", 25, shading),
                  createTableCell(staff.staff.visarestrictions),
                  createTableCell("Overseas Police check", 30, shading),
                  createTableCell(""),
                ],
              }),
            ],
          }),
          new Paragraph({
            children: [
              new TextRun({
                font: "Century Gothic",
                text: "",
                size: 22,
              }),
            ],
          }),
          // ROLE
          new Table({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            rows: [
              new TableRow({
                children: [
                  createTableCell("Job Role", 30, shading),
                  createTableCell(staff.staff.role, 70),
                ],
              }),
            ],
          }),
          new Paragraph({
            children: [
              new TextRun({
                font: "Century Gothic",
                text: "",
                size: 22,
              }),
            ],
          }),
          // NMC
          new Table({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            rows: [
              new TableRow({
                children: [
                  createTableCell("NMC Pin Number", 30, shading),
                  createTableCell(staff.staff.nmcnumber, 20),
                  createTableCell("Expiry Date", 20, shading),
                  createTableCell(nmcExpiry, 30),
                ],
              }),
            ],
          }),
          new Paragraph({
            children: [
              new TextRun({
                font: "Century Gothic",
                text: "",
                size: 22,
              }),
            ],
          }),
          // REFERENCES
          new Table({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            rows: [
              new TableRow({
                children: [
                  createTableCell("Reference #1 Source", 25, shading),
                  createTableCell(
                    staff.staff.references[0]?.name ??
                      "Not Available" +
                        " " +
                        staff.staff.references[0]?.surname ??
                      "Not Available",
                    25
                  ),
                  createTableCell("Reference #2 Source", 25, shading),
                  createTableCell(
                    staff.staff.references[1]?.name ??
                      "Not Available" +
                        " " +
                        staff.staff.references[1]?.surname ??
                      "Not Available",
                    25
                  ),
                ],
              }),
              new TableRow({
                children: [
                  createTableCell("Reference #1 Date", 25, shading),
                  createTableCell(
                    staff.staff.references[0]?.datesubmitted
                      ? new Date(
                          staff.staff.references[0]?.datesubmitted
                        ).toLocaleDateString("en-GB")
                      : "",
                    25
                  ),
                  createTableCell("Reference #2 Date", 25, shading),
                  createTableCell(
                    staff.staff.references[1]?.datesubmitted
                      ? new Date(
                          staff.staff.references[1]?.datesubmitted
                        ).toLocaleDateString("en-GB")
                      : "",

                    25
                  ),
                ],
              }),
              new TableRow({
                children: [
                  createTableCell("Relationship #1", 25, shading),
                  createTableCell(staff.staff.references[0]?.capacity, 25),
                  createTableCell("Relationship #2", 25, shading),
                  createTableCell(
                    staff.staff.references[1]?.capacity,

                    25
                  ),
                ],
              }),
            ],
          }),
          new Paragraph({
            children: [
              new TextRun({
                font: "Century Gothic",
                text: "",
                size: 22,
              }),
            ],
          }),
          // Trainings HEADRER
          new Table({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            rows: [
              new TableRow({
                children: [
                  createTableCell("Current Employers & Roles", 100, shading),
                ],
              }),
              new TableRow({
                children: [createTableCell("", 100)],
              }),
              new TableRow({
                children: [
                  createTableCell("Training Certificates", 100, shading),
                ],
              }),
            ],
          }),
          new Paragraph({
            children: [
              new TextRun({
                font: "Century Gothic",
                text: "",
                size: 22,
              }),
            ],
          }),
          // HEADERS
          new Table({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 50,
                      type: WidthType.PERCENTAGE,
                    },
                    margins: { top: 50, bottom: 50, left: 50 },
                    shading: { type: ShadingType.SOLID, color: "005994" },
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: "Course",
                            bold: true,
                            font: "Calibri",
                            size: 22,
                            color: "ffffff",
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: 25,
                      type: WidthType.PERCENTAGE,
                    },
                    margins: { top: 50, bottom: 50, left: 50 },
                    shading: { type: ShadingType.SOLID, color: "005994" },
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: "Date Last Trained",
                            bold: true,
                            font: "Calibri",
                            size: 22,
                            color: "ffffff",
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: 60,
                      type: WidthType.PERCENTAGE,
                    },
                    margins: { top: 50, bottom: 50, left: 50 },
                    shading: { type: ShadingType.SOLID, color: "005994" },
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: "Review Date",
                            bold: true,
                            font: "Calibri",
                            size: 22,
                            color: "ffffff",
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: 60,
                      type: WidthType.PERCENTAGE,
                    },
                    margins: { top: 50, bottom: 50, left: 50 },
                    shading: { type: ShadingType.SOLID, color: "005994" },
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: "Certificate Attached",
                            bold: true,
                            font: "Calibri",
                            size: 22,
                            color: "ffffff",
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              // TRAININGS
              trainingRow("Equality & Diversity", equality),
              trainingRow("Health & Safety", healthSafety),
              trainingRow("Information Governance", informationGovernance),
              trainingRow("Fire Safety", fireSafety),
              trainingRow("Infection Control", infectionControl),
              trainingRow("Food Hygiene", foodHygiene),
              trainingRow("Medicine Management", medicalManagement),
              trainingRow("Basic Life Support", basicLifeSupport),
              trainingRow("Immediate Life Support", immediateLifeSupport),
              trainingRow("Dysphagia Awareness", dysphagia),
              trainingRow("Moving & Handling", moving),
              trainingRow(
                "Safeguarding Children & Adults - Intercollegiate document level",
                safeguard
              ),
              trainingRow("Complaints & Conflict Management", conflict),
              trainingRow("Lone Worker", loneworker),
              trainingRow(
                "PMVA (compliant with Mental Health units Use of Force 2018 Act)",
                pmva
              ),
              trainingRow(
                "SI (compliant with Mental Health Units Use Of Force 2018 Act",
                si
              ),
              trainingRow("Mental Health Act Awareness", mentalAct),
              trainingRow("MCA/DoLs/LPS", dols),
              trainingRow("Ladder Safety", ladder),
              trainingRow("Legionella", legionella),
              trainingRow("Asbestos", asbestos),
            ],
          }),

          new Paragraph({
            children: [
              new TextRun({
                font: "Century Gothic",
                text: "",
                size: 22,
              }),
            ],
          }),
          // Profile by
          new Table({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            rows: [
              new TableRow({
                children: [
                  createTableCell("Agency Name & Address", 25, shading),
                  createTableCell("Consultant Name", 25, shading),
                  createTableCell("Consultant Signature", 25, shading),
                  createTableCell("Date", 25, shading),
                ],
              }),
              new TableRow({
                children: [
                  createTableCell(staff.organisationName, 25),
                  createTableCell("", 25),
                  createTableCell("", 25),
                  createTableCell(new Date().toLocaleDateString("en-GB"), 25),
                ],
              }),
            ],
          }),
        ],
      },
    ],
  });

  Packer.toBlob(profile)
    .then((blob) => {
      saveAs(blob, "Cygnet Profile.docx");
    })
    .catch((error) => {
      console.log(error);
    });
};

export default generateCygnetProfile;
