import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import referenceService from './referenceService';

const initialState = {
    reference: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
};

//* GET REFERENCE
export const getReference = createAsyncThunk('reference/fetch', async (id, thunkAPI) => {
    try {
        return await referenceService.getReference(id);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const editReference = createAsyncThunk('reference/edit', async (refData, thunkAPI) => {
    try {
        return await referenceService.editReference(refData);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

// EDIT REFERENCE

export const referenceSlice = createSlice({
    name: 'reference',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = '';
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getReference.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getReference.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.reference = action.payload;
                state.message = '';
            })
            .addCase(getReference.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.user = null;
            })
            .addCase(editReference.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(editReference.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.reference = action.payload;
                state.message = '';
            })
            .addCase(editReference.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.user = null;
            });
    }
});

export const {reset} = referenceSlice.actions;
export default referenceSlice.reducer;
