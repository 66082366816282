import React, { useEffect, useState } from "react";
import {
  editReference,
  getReference,
  reset,
} from "../features/Reference/referenceSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import NavBar from "../components/NavBar/NavBar";
import Spinner from "../components/UI/Spinner";
import { toast } from "react-toastify";

function Reference() {
  const { reference, isError, isSuccess, message } = useSelector(
    (state) => state.reference
  );
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [image, setImage] = useState("");
  const [formData, setFormData] = useState({
    id,
    capacity: "",
    company: "",
    leaving: "",
    dateto: "",
    datefrom: "",
    ability: "",
    careplans: "",
    character: "",
    attitude: "",
    relationships: "",
    reliability: "",
    discipline: "",
    disciplinedetails: "",
    safeguard: "",
    safeguarddetails: "",
    crime: "",
    crimedetails: "",
    employed: "",
    employeddetails: "",
    comments: "",
    terms: "",
  });

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }

    // TODO LOAD REFERENCE
    dispatch(getReference(id));

    return () => {
      reset();
    };
  }, [isError]);

  const handleChange = (input) => (e) => {
    setFormData({ ...formData, [input]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(editReference(formData));
    if (isSuccess) {
      navigate("/reference/thank-you");
    }
  };

  if (!reference) {
    return <Spinner />;
  }
  return (
    <React.Fragment>
      <NavBar />

      <div>
        <div className="p-3">
          <h1 className="mt-3 text-center text-xl font-bold">
            Completing reference
          </h1>
          <p className="text-center">
            Thank you for taking time to complete this reference form. Please
            fill in all the details before proceeding
          </p>
        </div>
        <form onSubmit={handleSubmit} className="p-3 sm:p-5 space-y-3 ">
          <div className="sm:flex  space-y-2 sm:space-y-0 sm:space-x-3">
            <div className="w-full sm:w-1/2">
              <label className="font-semibold text-xl" htmlFor="name">
                First name
              </label>
              <br />
              <input
                disabled
                placeholder={reference.name}
                value={reference.name}
                className="w-full disabled:bg-main2 disabled:text-white"
                type="text"
                name="name"
                id="name"
                onChange={handleChange("name")}
              />
            </div>
            <div className="w-full sm:w-1/2">
              <label className="font-semibold text-xl" htmlFor="surname">
                Surname
              </label>
              <br />
              <input
                disabled
                value={reference.surname}
                className="w-full disabled:bg-main2 disabled:text-white"
                type="text"
                name="surname"
                id="surname"
                onChange={handleChange("surname")}
              />
            </div>
          </div>
          <div className="sm:flex  space-y-3 sm:space-y-0 sm:space-x-3">
            <div className="w-full sm:w-1/2">
              <label className="font-semibold text-xl" htmlFor="email">
                Email
              </label>
              <br />
              <input
                disabled
                value={reference.email}
                className="w-full disabled:bg-main2 disabled:text-white"
                type="email"
                name="email"
                id="email"
                onChange={handleChange("email")}
              />
            </div>
            <div className="w-full sm:w-1/2">
              <label className="font-semibold text-xl" htmlFor="phone">
                Contact number
              </label>
              <br />
              <input
                disabled
                value={reference.phone}
                className="w-full disabled:bg-main2 disabled:text-white"
                type="text"
                name="phone"
                id="phone"
                onChange={handleChange("phone")}
              />
            </div>
          </div>
          <div className="sm:flex  space-y-3 sm:space-y-0 sm:space-x-3">
            <div className="w-full sm:w-1/2">
              <label className="font-semibold text-xl" htmlFor="company">
                Company
              </label>
              <br />
              <input
                value={formData.company}
                className="w-full disabled:bg-main2 disabled:text-white"
                type="text"
                name="company"
                id="company"
                onChange={handleChange("company")}
              />
            </div>
            <div className="w-full sm:w-1/2">
              <label className="font-semibold text-xl" htmlFor="capacity">
                Capacity known
              </label>
              <br />
              <input
                value={formData.capacity}
                className="w-full disabled:bg-main2 disabled:text-white"
                type="text"
                name="capacity"
                id="capacity"
                onChange={handleChange("capacity")}
              />
            </div>
          </div>
          <div className="sm:flex  space-y-3 sm:space-y-0 sm:space-x-3">
            <div className="w-full sm:w-1/2">
              <label className="font-semibold text-xl" htmlFor="title">
                Title at company
              </label>
              <br />
              <input
                value={formData.title}
                className="w-full disabled:bg-main2 disabled:text-white"
                type="text"
                name="title"
                id="title"
                onChange={handleChange("title")}
              />
            </div>
            <div className="w-full sm:w-1/2">
              <label className="font-semibold text-xl" htmlFor="leaving">
                Reason for leaving
              </label>
              <br />
              <input
                value={formData.leaving}
                className="w-full disabled:bg-main2 disabled:text-white"
                type="text"
                name="leaving"
                id="leaving"
                onChange={handleChange("leaving")}
              />
            </div>
          </div>
          <div className="sm:flex  space-y-3 sm:space-y-0 sm:space-x-3">
            <div className="w-full sm:w-1/2">
              <label className="font-semibold text-xl" htmlFor="datefrom">
                Date known from
              </label>
              <br />
              <input
                className="w-full disabled:bg-main2 disabled:text-white"
                type="date"
                name="datefrom"
                id="datefrom"
                onChange={handleChange("datefrom")}
              />
            </div>
            <div className="w-full sm:w-1/2">
              <label className="font-semibold text-xl" htmlFor="dateto">
                Date known to
              </label>
              <br />
              <input
                className="w-full disabled:bg-main2 disabled:text-white"
                type="date"
                name="dateto"
                id="dateto"
                onChange={handleChange("dateto")}
              />
            </div>
          </div>
          <div>
            <h2 className="font-semibold text-center text-xl">
              Select the most appropriate choice
            </h2>
            <p className="text-center">
              How would you assess the following? Please select the relevant
              circle, With 1 being worst and 5 being best.
            </p>
          </div>
          <div className=" sm:flex sm:space-x-3 space-y-3 sm:space-y-0">
            <div className="w-full">
              <label className="font-semibold text-xl" htmlFor="careplans">
                Ability to follow careplans
              </label>
              <br />

              <select
                name="careplans"
                id="careplans"
                onChange={handleChange("careplans")}
                className="w-full "
              >
                <option value="Select">Select</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
            <div className="w-full">
              <label className="font-semibold text-xl" htmlFor="reliability">
                Reliability, time keeping and attendance?
              </label>
              <br />

              <select
                name="reliability"
                id="reliability"
                onChange={handleChange("reliability")}
                className="w-full "
              >
                <option value="Select">Select</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
          </div>
          <div className=" sm:flex sm:space-x-3 space-y-3 sm:space-y-0">
            <div className="w-full">
              <label className="font-semibold text-xl" htmlFor="character">
                Character
              </label>
              <br />

              <select
                name="character"
                id="character"
                onChange={handleChange("character")}
                className="w-full "
              >
                <option value="Select">Select</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
            <div className="w-full">
              <label className="font-semibold text-xl" htmlFor="attitude">
                Attitude
              </label>
              <br />

              <select
                name="attitude"
                id="attitude"
                onChange={handleChange("attitude")}
                className="w-full "
              >
                <option value="Select">Select</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
          </div>
          <div className=" sm:flex sm:space-x-3 space-y-3 sm:space-y-0">
            <div className="w-full">
              <label className="font-semibold text-xl" htmlFor="relationships">
                Relationship with colleagues
              </label>
              <br />

              <select
                name="relationships"
                id="relationships"
                onChange={handleChange("relationships")}
                className="w-full "
              >
                <option value="Select">Select</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
            <div className="w-full">
              <label className="font-semibold text-xl" htmlFor="ability">
                Ability to work under own initiative
              </label>
              <br />

              <select
                name="ability"
                id="ability"
                onChange={handleChange("ability")}
                className="w-full "
              >
                <option value="Select">Select</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
          </div>
          <div>
            <h2 className="font-semibold text-center text-xl">
              Answer the following to the best of your ability
            </h2>
          </div>
          <div className=" sm:flex sm:space-x-3 space-y-3 sm:space-y-0">
            <div className="w-full">
              <label className="font-semibold text-xl" htmlFor="discipline">
                Has the candidate ever been subject to a discipline hearing?
              </label>
              <br />

              <select
                name="discipline"
                id="discipline"
                onChange={handleChange("discipline")}
                className="w-full "
              >
                <option value="Select">Select</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>

              {formData.discipline === "yes" && (
                <div className="mt-3">
                  <label
                    className="font-semibold text-xl"
                    htmlFor="disciplinedetails"
                  >
                    Please provide more details
                  </label>
                  <br />

                  <textarea className="w-full" name="disciplinedetails" />
                </div>
              )}
            </div>
            <div className="w-full">
              <label className="font-semibold text-xl" htmlFor="safeguard">
                Are you aware of the candidate's involvement in any safeguarding
                investigations?
              </label>
              <br />

              <select
                name="safeguard"
                id="safeguard"
                onChange={handleChange("safeguard")}
                className="w-full "
              >
                <option value="Select">Select</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>

              {formData.safeguard === "yes" && (
                <div className="mt-3">
                  <label
                    className="font-semibold text-xl"
                    htmlFor="safeguarddetails"
                  >
                    Please provide more details
                  </label>
                  <br />

                  <textarea
                    className="w-full"
                    name="safeguarddetails"
                    onChange={handleChange("safeguarddetails")}
                  />
                </div>
              )}
            </div>
          </div>
          <div className=" sm:flex sm:space-x-3 space-y-3 sm:space-y-0">
            <div className="w-full">
              <label className="font-semibold text-xl" htmlFor="employed">
                Are you aware of any reasons the applicant should not be
                employed to work with children or vulnerable people?
              </label>
              <br />

              <select
                name="employed"
                id="employed"
                onChange={handleChange("employed")}
                className="w-full "
              >
                <option value="Select">Select</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>

              {formData.employed === "yes" && (
                <div className="mt-3">
                  <label
                    className="font-semibold text-xl"
                    htmlFor="employeddetails"
                    onChange={handleChange("employeddetails")}
                  >
                    Please provide more details
                  </label>
                  <br />

                  <textarea className="w-full" name="employeddetails" />
                </div>
              )}
            </div>
            <div className="w-full">
              <label className="font-semibold text-xl" htmlFor="crime">
                To the best of your knowledge, has the applicant been convicted
                or cautioned of a criminal offence?
              </label>
              <br />

              <select
                name="crime"
                id="crime"
                onChange={handleChange("crime")}
                className="w-full "
              >
                <option value="Select">Select</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>

              {formData.crime === "yes" && (
                <div className="mt-3">
                  <label
                    className="font-semibold text-xl"
                    htmlFor="crimedetails"
                    onChange={handleChange("crimedetails")}
                  >
                    Please provide more details
                  </label>
                  <br />

                  <textarea className="w-full" />
                </div>
              )}
            </div>
          </div>
          <div className="">
            <label for="reason" className="font-semibold text-xl">
              Additional comments
            </label>
            <textarea
              class="form-control"
              id="comments"
              name="comments"
              aria-describedby="commentsHelp"
              onChange={handleChange("comments")}
            />
          </div>
          <div className="form-check mb-3">
            <input
              className="form-check-input"
              type="checkbox"
              value="accepted"
              id="terms"
              name="terms"
              onChange={handleChange("terms")}
            />
            <label className="form-check-label" for="terms">
              I can confirm that all the details provided are accurate at the
              time that this reference was completed. I can confirm that I am
              authorised to provide a reference on behalf of my organisation. I
              understand this reference may be shown to a third party for
              auditing purposes and I can confirm that Tafara Care Services has
              this organisation’s consent and authorisation to disclose the
              contents of this reference to its end user, hirer clients. I
              understand that the applicant has the legal right to request a
              copy of their reference.
            </label>
          </div>
          <div>
            <button
              type="submit"
              className="font-semibold shadow rounded-lg  bg-main hover:bg-main2 py-3 px-3 text-[#FFF] w-full mt-3"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
}

export default Reference;
