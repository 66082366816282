import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import StepOne from "../components/RegistrationsComponents/StepOne";
import StepTwo from "../components/RegistrationsComponents/StepTwo";
import StepThree from "../components/RegistrationsComponents/StepThree";
import { register, reset } from "../../src/features/Auth/userSlice";
import { getOrganisationReg } from "../features/Organisation/organisationSlice";
import Spinner from "../components/UI/Spinner";
import { toast } from "react-toastify";
import RegProgressBar from "../components/RegistrationsComponents/stepper";

function RegistrationPage() {
  // GET ORGANISATION
  const { id } = useParams();

  const orgId = id.toUpperCase();

  const [image, setImage] = useState("");

  const [formData, setFormData] = useState({
    image: image,
    name: "",
    surname: "",
    phone: "",
    email: "",
    password: "",
    organisation: orgId,
    password2: "",
    gender: "",
    nationality: "",
    dateofbirth: new Date().toISOString().split("T")[0],
    role: "",
    userrole: "",
    ninumber: "",
    nmcnumber: "",
    nmcexpiry: new Date().toISOString().split("T")[0],
    terms: "",
    proofOfId: "",
    houseaddress: "",
    streetaddress: "",
    city: "",
    province: "",
    country: "",
    zip: "",
    region: "",
    righttowork: "",
    righttoworkproof: "",
    passportnumber: "",
    passportstart: new Date().toISOString().split("T")[0],
    passportissue: "",
    passportexpiry: new Date().toISOString().split("T")[0],
    visanumber: "",
    visaexpiry: new Date().toISOString().split("T")[0],
    visarestrictions: "",

    dbscert: "",
    dbsservice: "",
    dbsnumber: "",
    dbsissue: new Date().toISOString().split("T")[0],
    dbschecked: "",
    dbslevel: "",
  });

  const handleChange = (input) => (e) => {
    setFormData({ ...formData, [input]: e.target.value });
  };

  const onImageChange = (e) => {
    const uploadedImage = e.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(uploadedImage);
    reader.onload = function () {
      setImage(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.user
  );
  const { organisation } = useSelector((state) => state.organisation);

  const [steps, setSteps] = useState(1);

  const [passwordState, setPasswordState] = useState(true);

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
    if (isSuccess || user) {
      navigate("/");
    }

    dispatch(getOrganisationReg(id));
    dispatch(reset());
  }, [user, isLoading, isError, isSuccess, message, dispatch, navigate]);

  const HandleSubmit = (e) => {
    e.preventDefault();

    const userData = {
      image: image,
      name: formData.name.charAt(0).toUpperCase() + formData.name.slice(1),
      surname:
        formData.surname.charAt(0).toUpperCase() + formData.surname.slice(1),
      email: formData.email,
      phone: formData.phone,
      password: formData.password,
      organisation: formData.organisation,
      staff: {
        role: formData.role,
        gender: formData.gender,
        dateofbirth: formData.dateofbirth,
        nationality: formData.nationality,
        nmcnumber: formData.nmcnumber,
        nmcexpiry: formData.nmcexpiry,
        proofOfId: formData.proofOfId,
        houseaddress: formData.houseaddress,
        streetaddress: formData.streetaddress,
        city: formData.city,
        province: formData.province,
        country: formData.country,
        zip: formData.zip,
        region: formData.region,
        righttowork: formData.righttowork,
        righttoworkproof: formData.righttoworkproof,
        passportnumber: formData.passportnumber,
        passportstart: formData.passportstart,
        passportissue: formData.passportissue,
        passportexpiry: formData.passportexpiry,
        visanumber: formData.visanumber,
        visaexpiry: formData.visaexpiry,
        visarestrictions: formData.visarestrictions,
        ninumber: formData.ninumber,
        dbscert: formData.dbscert,
        dbsservice: formData.dbsservice,
        dbsnumber: formData.dbsnumber,
        dbsissue: formData.dbsissue,

        dbslevel: formData.dbslevel,
      },
    };

    dispatch(register(userData));
  };

  if (!organisation) {
    return <Spinner />;
  }

  const data = organisation.organisation;

  return (
    <React.Fragment>
      <section className="flex items-center justify-center mt-1 p-4 sm:w-full">
        <div className="space-y-3 sm:w-full">
          <div className="d-flex justify-center">
            {data.image && (
              <img
                src={data.image.path}
                alt="{data.organisation} logo"
                srcSet=""
                width="200px"
                height="auto"
                crossOrigin="anonymous"
              />
            )}
          </div>
          <h2 className="text-center text-xl font-bold">
            Sign up to start building your profile to be able book shifts with{" "}
            {data.organisation}.
          </h2>
          <p className="">Powered by Compliance Aide</p>
          <div className="flex items-center justify-center ">
            <RegProgressBar page={steps} />
          </div>
          <form className="space-y-3 sm:px-4" onSubmit={HandleSubmit}>
            {steps === 1 && (
              <StepOne
                handleChange={handleChange}
                values={formData}
                passwordState={passwordState}
                setPasswordState={setPasswordState}
                onImageChange={onImageChange}
              />
            )}

            {steps === 2 && (
              <StepTwo handleChange={handleChange} values={formData} />
            )}
            {steps === 3 && (
              <StepThree handleChange={handleChange} values={formData} />
            )}

            <div className=" flex space-x-3">
              {steps != 1 && (
                <button
                  className="w-full font-semibold text-white bg-main2  hover:bg-main px-3 py-3 rounded shadow-sm"
                  type="button"
                  onClick={() => {
                    setSteps(steps - 1);
                  }}
                >
                  Back
                </button>
              )}

              {steps !== 3 && (
                <button
                  className="w-full font-semibold text-white bg-main2  hover:bg-main px-3 py-3 rounded shadow-sm"
                  type="button"
                  onClick={() => {
                    setSteps(steps + 1);
                  }}
                  disabled={!passwordState}
                >
                  Next
                </button>
              )}
              {steps === 3 && (
                <button
                  className="w-full font-semibold text-white bg-main2  hover:bg-main px-3 py-3 rounded shadow-sm"
                  type="submit"
                >
                  Submit
                </button>
              )}
            </div>
          </form>
        </div>
      </section>
    </React.Fragment>
  );
}

export default RegistrationPage;
