import React, { useEffect } from "react";
import { getReference, reset } from "../features/Reference/referenceSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import NavBar from "../components/NavBar/NavBar";
import Spinner from "../components/UI/Spinner";
import { toast } from "react-toastify";

function FullReference({ row, show }) {
  const { reference, isError, isSuccess, message } = useSelector(
    (state) => state.reference
  );
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }

    // TODO LOAD REFERENCE
    dispatch(getReference(id));

    return () => {
      reset();
    };
  }, [isSuccess, reference]);

  if (!reference) {
    return <Spinner />;
  }

  return (
    <React.Fragment>
      <NavBar />
      <div className=" p-2 sm:w-1/2 mx-auto">
        <h1 className="container text-center mt-5 text-2xl underline decoration-main">
          Full Reference Details
        </h1>
        <div className="bg-white p-3  sm:flex justify-center space-y-3 sm:space-y-0 ">
          <div className="sm:w-full ">
            <span className="text-bold text-lg">Name:</span> {reference.name}
          </div>
          <div className="sm:w-full ">
            <span className="text-bold text-lg">Surname:</span>{" "}
            {reference.surname}
          </div>
        </div>
        <div className="bg-white p-3  sm:flex justify-center space-y-3 sm:space-y-0 ">
          <div className="sm:w-full ">
            <span className="text-bold text-lg">Title at company:</span>{" "}
            {reference.title}
          </div>
          <div className="sm:w-full ">
            <span className="text-bold text-lg">Company:</span>{" "}
            {reference.company}
          </div>
        </div>

        <div className="bg-white p-3  sm:flex justify-center space-y-3 sm:space-y-0 ">
          <div className="sm:w-full ">
            <span className="text-bold text-lg">Reason for leaving:</span>{" "}
            {reference.reason}
          </div>
          <div className="sm:w-full ">
            <span className="text-bold text-lg">Capacity known:</span>{" "}
            {reference.capacity}
          </div>
        </div>
        <div className="bg-white p-3  sm:flex justify-center space-y-3 sm:space-y-0 ">
          <div className="sm:w-full ">
            <span className="text-bold text-lg">Date known from:</span>{" "}
            {new Date(reference.datefrom).toLocaleDateString()}
          </div>
          <div className="sm:w-full ">
            <span className="text-bold text-lg">Date known to:</span>{" "}
            {new Date(reference.dateto).toLocaleDateString()}
          </div>
        </div>

        <div className="bg-white p-3  sm:flex justify-center space-y-3 sm:space-y-0 ">
          <div className="sm:w-full ">
            <span className="text-bold text-lg">
              Ability to follow careplans:
            </span>{" "}
            {reference.careplans}/5
          </div>
          <div className="sm:w-full ">
            <span className="text-bold text-lg">
              Reliability, time keeping and attendance:
            </span>{" "}
            {reference.reliability}/5
          </div>
        </div>
        <div className="bg-white p-3  sm:flex justify-center space-y-3 sm:space-y-0 ">
          <div className="sm:w-full ">
            <span className="text-bold text-lg">Character:</span>{" "}
            {reference.character}/5
          </div>
          <div className="sm:w-full ">
            <span className="text-bold text-lg">Attitude:</span>{" "}
            {reference.attitude}/5
          </div>
        </div>
        <div className="bg-white p-3  sm:flex justify-center space-y-3 sm:space-y-0 ">
          <div className="sm:w-full ">
            <span className="text-bold text-lg">
              Relationship with colleagues:
            </span>{" "}
            {reference.relationships}/5
          </div>
          <div className="sm:w-full ">
            <span className="text-bold text-lg">
              Ability to work under own initiative:
            </span>{" "}
            {reference.ability}/5
          </div>
        </div>
        <div className="bg-white p-3  sm:flex justify-center space-y-3 sm:space-y-0 ">
          <div className="sm:w-full ">
            <span className="text-bold text-lg">
              Are you aware of the candidate's involvement in any safeguarding
              investigations?
            </span>{" "}
            {reference.safeguard.toUpperCase()}
          </div>
        </div>
        {reference.safeguarding === "yes" && (
          <div className="bg-white p-3  sm:flex justify-center space-y-3 sm:space-y-0 ">
            <div className="sm:w-full ">
              <span className="text-bold text-lg">
                Safeguarding investigation details
              </span>{" "}
              {reference.safeguarddetails}
            </div>
          </div>
        )}
        <div className="bg-white p-3  sm:flex justify-center space-y-3 sm:space-y-0 ">
          <div className="sm:w-full ">
            <span className="text-bold text-lg">
              Are you aware of any reasons the applicant should not be employed
              to work with children or vulnerable people?
            </span>{" "}
            {reference.employed.toUpperCase()}
          </div>
        </div>
        {reference.employed === "yes" && (
          <div className="bg-white p-3  sm:flex justify-center space-y-3 sm:space-y-0 ">
            <div className="sm:w-full ">
              <span className="text-bold text-lg">
                Criminal offence details
              </span>{" "}
              {reference.employeddetails}
            </div>
          </div>
        )}
        <div className="bg-white p-3  sm:flex justify-center space-y-3 sm:space-y-0 ">
          <div className="sm:w-full ">
            <span className="text-bold text-lg">
              To the best of your knowledge, has the applicant been convicted or
              cautioned of a criminal offence?
            </span>{" "}
            {reference.crime.toUpperCase()}
          </div>
        </div>
        {reference.crime === "yes" && (
          <div className="bg-white p-3  sm:flex justify-center space-y-3 sm:space-y-0 ">
            <div className="sm:w-full ">
              <span className="text-bold text-lg">
                Criminal offence details
              </span>{" "}
              {reference.crimedetails}
            </div>
          </div>
        )}
        {reference.comments && (
          <div className="bg-white p-3  sm:flex justify-center space-y-3 sm:space-y-0 ">
            <div className="sm:w-full ">
              <span className="text-bold text-lg">Additional comments</span>{" "}
              {reference.comments}
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default FullReference;
