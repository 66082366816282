import React from "react";

function StepThree({ handleChange, values }) {
  return (
    <React.Fragment>
      <div className=" sm:flex sm:space-x-3 space-y-3 sm:space-y-0">
        <div className="w-full">
          <label className="font-semibold text-xl" htmlFor="dbscert">
            DBS available?*
          </label>
          <br />

          <select
            name="dbscert"
            id="dbscert"
            required
            onChange={handleChange("dbscert")}
            className="w-full"
          >
            <option selected value={values.dbscert}>
              {values.dbscert}
            </option>
            <option value="Select">Select</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </div>
        <div className="w-full">
          <label className="font-semibold text-xl" htmlFor="dbsservice">
            DBS update service?*
          </label>
          <br />

          <select
            name="dbsservice"
            id="dbsservice"
            required
            onChange={handleChange("dbsservice")}
            className="w-full"
          >
            <option value="Select">Select</option>
            <option selected value={values.dbsservice}>
              {values.dbsservice}
            </option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </div>
      </div>
      {values.dbscert === "true" && (
        <div className=" sm:flex sm:space-x-3 space-y-3 sm:space-y-0">
          <div className="w-full">
            <label className="font-semibold text-xl" htmlFor="dbsnumber">
              DBS number
            </label>
            <br />

            <input
              type="text"
              name="dbsnumber"
              id="dbsnumber"
              onChange={handleChange("dbsnumber")}
              value={values.dbsnumber}
              className="w-full"
              autoComplete="off"
            />
          </div>
          <div className="w-full">
            <label className="font-semibold text-xl" htmlFor="dbslevel">
              DBS level
            </label>
            <br />

            <select
              name="dbslevel"
              id="dbslevel"
              onChange={handleChange("dbslevel")}
              className="w-full"
            >
              <option selected value={values.dbslevel}>
                {values.dbslevel}
              </option>
              <option value="Basic">Basic</option>
              <option value="Standard">Standard</option>
              <option value="Enhanced">Enhanced</option>
            </select>
          </div>
          <div className="w-full">
            <label className="font-semibold text-xl" htmlFor="dbsissue">
              DBS valid from
            </label>
            <br />

            <input
              type="date"
              name="dbsissue"
              id="dbsissue"
              onChange={handleChange("dbsissue")}
              className="w-full"
              autoComplete="off"
              value={new Date(values.dbsissue).toISOString().split("T")[0]}
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default StepThree;
