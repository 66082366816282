import React, { useState } from 'react';
import { useEffect } from 'react';
import MaterialTable from 'material-table';
import { useNavigate } from 'react-router-dom';
import profileService from '../../features/Profile/profileService';
import Spinner from '../UI/Spinner';
import AdminProfile from './AdminProfile';
import { ThemeProvider, createTheme } from '@mui/material';

function Staff({ user, organisation }) {
	const navigate = useNavigate();
	const [isOpen, setIsOpen] = useState(false);
	const [id, setId] = useState('');
	const [users, setUsers] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const defaultMaterialTheme = createTheme();

	useEffect(() => {
		if (!user) {
			navigate('/');
		}
		//get all users
		setIsLoading(true);
		fetchAllUsers();
		setIsLoading(false);
	}, [isOpen]);

	const fetchAllUsers = async () => {
		setUsers(await profileService.getAllUsers(user.organisation));
	};

	const archiveUser = async (id) => {
		setIsLoading(true);
		await profileService.archiveStaff(id);
		fetchAllUsers();
		setIsLoading(false);
	};

	if (!users) {
		return <Spinner></Spinner>;
	}

	return (
		<React.Fragment>
			<div className="p-2 ">
				{!isOpen ? (
					<>
						<h1 className="mt-3 font-bold text-3xl text-center">All users</h1>
						<p className="text-center mb-3">list updated: {Date()}</p>

						<div style={{ maxWidth: '100%' }}>
							<link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
							<ThemeProvider theme={defaultMaterialTheme}>
								<MaterialTable
									columns={[
										{ title: 'Applied', field: 'dateAdded', type: 'date' },
										{ title: 'Name', field: 'name' },
										{ title: 'Surname', field: 'surname' },
										{ title: 'Email', field: 'email' },
										{
											title: 'Role',
											field: 'staff.role',
										},
										{
											title: 'Team',
											field: 'team',
										},
										{
											title: 'Compliant',
											field: 'staff.isCompliant',
										},
										{
											title: 'City',
											field: 'staff.city',
										},
									]}
									data={users.filter((user) => !user.archived)}
									isLoading={isLoading}
									options={{
										actionsColumnIndex: 1,
										exportButton: true,
										exportFileName: 'tcs-staff-export',
										actionsColumnIndex: -1,
										rowStyle: {
											padding: '0 0rem',
											textAlign: 'center',
											fontSize: '0.9rem',
										},
										headerStyle: {
											padding: '0 0.5rem',
											fontWeight: 'bold',
											fontSize: '1.3rem',
										},
										pageSizeOptions: [20, 50, 100, 500],
										pageSize: 20,
									}}
									actions={[
										(rowData) => ({
											icon: 'chevron_right',
											tooltip: 'View profile',
											onClick: (event, rowData) => {
												//window.open(`/profile/${rowData._id}`, "_blank");
												setId(rowData._id);
												setIsOpen(true);
											},
										}),
										(rowData) => ({
											icon: 'clear',
											tooltip: 'Archive staff',
											onClick: (event, rowData) => {
												archiveUser(rowData._id);
											},
										}),
									]}
									title="All Staff"
								/>
							</ThemeProvider>
						</div>
					</>
				) : (
					<AdminProfile id={id} setIsOpen={setIsOpen} />
				)}
			</div>
		</React.Fragment>
	);
}

export default Staff;
