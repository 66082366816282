import React, { Fragment, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { ThemeProvider, createTheme } from "@mui/material";
import MaterialTable from "material-table";
import adminService from "../../features/Admin/adminService";
import Spinner from "../UI/Spinner";
import { useNavigate } from "react-router-dom";
import Modal from "../UI/Modal";
import organisationService from "../../features/Organisation/organisationService";
import { toast } from "react-toastify";

function Organisations() {
  const [isOpen, setIsOpen] = useState(false);

  const [image, setImage] = useState("");
  const [formData, setFormData] = useState({
    image,
    name: "",
    reference: "",
    contactName: "",
    contactSurname: "",
    contactPhone: "",
    contactEmail: "",
  });
  const { mutate, isLoading: loading } = useMutation(
    organisationService.createOrganisation,
    {
      onSuccess: (data) => {
        toast.success(data + " Successfully Added.");
      },
      onError: (err) => {
        toast.error("Failed to add organisation.");
      },
    }
  );
  const defaultMaterialTheme = createTheme();
  const navigate = useNavigate();
  const fetchAllOrgs = async () => {
    return await adminService.getAllOrgs();
  };

  const { isLoading, isError, data, error } = useQuery(
    "all-orgs",
    fetchAllOrgs
  );

  const onImageChange = (e) => {
    const uploadedImage = e.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(uploadedImage);
    reader.onload = function () {
      setImage(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const organisation = {
      image: image,
      organisation: formData.reference,
      organisationName: formData.name,
      contactname: formData.contactName,
      contactsurname: formData.contactSurname,
      contactemail: formData.contactEmail,
      contactnumber: formData.contactPhone,
    };

    mutate(organisation);
    setIsOpen(false);
  };

  const handleChange = (input) => (e) => {
    setFormData({ ...formData, [input]: e.target.value });
  };

  const body = () => {
    return (
      <div className="space-y-4 ">
        <div className="flex space-x-4">
          <div className="w-1/2">
            <label for="datetrainined" class="form-label">
              Organisation Name
            </label>
            <br />
            <input
              className="w-full"
              type="text"
              name="name"
              id="name"
              onChange={handleChange("name")}
              required
            />
          </div>
          <div className="w-1/2">
            <label for="expirydate" class="form-label">
              Reference (Must not have spaces i.e TCS)
            </label>
            <br />
            <input
              className="w-full"
              type="text"
              name="reference"
              id="reference"
              onChange={handleChange("reference")}
              required
            />
          </div>
        </div>
        <div className="flex space-x-4">
          <div className="w-1/2">
            <label for="contactName" class="form-label">
              Contact Name
            </label>
            <br />
            <input
              className="w-full"
              type="text"
              name="contactName"
              id="contactName"
              onChange={handleChange("contactName")}
              required
            />
          </div>
          <div className="w-1/2">
            <label for="contactSurname" class="form-label">
              Contact Surname
            </label>
            <br />
            <input
              className="w-full"
              type="text"
              name="contactSurname"
              id="contactSurname"
              onChange={handleChange("contactSurname")}
              required
            />
          </div>
        </div>
        <div className="flex space-x-4">
          <div className="w-1/2">
            <label for="contactEmail" class="form-label">
              Contact Email
            </label>
            <br />
            <input
              className="w-full"
              type="email"
              name="contactEmail"
              id="contactEmail"
              onChange={handleChange("contactEmail")}
              required
            />
          </div>
          <div className="w-1/2">
            <label for="contactPhone" class="form-label">
              Contact Phone
            </label>
            <br />
            <input
              className="w-full"
              type="text"
              name="contactPhone"
              id="contactPhone"
              onChange={handleChange("contactPhone")}
              required
            />
          </div>
        </div>
        <div className="w-full">
          <label for="image" class="form-label">
            Logo
          </label>
          <br />
          <input
            values={image}
            type="file"
            name="image"
            id="image"
            onChange={onImageChange}
          />
        </div>
      </div>
    );
  };

  if (isLoading) {
    return <Spinner></Spinner>;
  }

  return (
    <Fragment>
      <button
        onClick={() => setIsOpen(true)}
        className="font-semibold shadow   rounded-lg  bg-main hover:bg-main2 py-2 px-5 text-white mb-3"
      >
        Add New Organisation
      </button>
      <div style={{ maxWidth: "100%" }}>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
        <ThemeProvider theme={defaultMaterialTheme}>
          <MaterialTable
            columns={[
              { title: "Joined", field: "date", type: "date" },
              { title: "Name", field: "organisationName" },
              { title: "Reference", field: "organisation" },
            ]}
            data={data.data}
            isLoading={isLoading}
            options={{
              actionsColumnIndex: -1,
              rowStyle: {
                padding: "0 0rem",
                textAlign: "center",
                fontSize: "0.9rem",
              },
              headerStyle: {
                padding: "0 0.5rem",
                fontWeight: "bold",
                fontSize: "1.3rem",
              },
              pageSizeOptions: [20, 50, 100, 500],
              pageSize: 20,
            }}
            actions={[
              (rowData) => ({
                icon: "chevron_right",
                tooltip: "View",
                onClick: (event, rowData) => {
                  navigate("/admin/organisation/" + rowData._id);
                },
              }),
            ]}
            title="Organisations"
          />
        </ThemeProvider>
      </div>

      <div className="mt-5">
        <Modal
          open={isOpen}
          closeModal={() => {
            setIsOpen(false);
          }}
          title={"Adding New Organisation"}
          body={body()}
          submitForm={handleSubmit.bind(this)}
        />
      </div>
    </Fragment>
  );
}

export default Organisations;
