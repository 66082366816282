import React, { useEffect, useState } from "react";
import { Bar, Pie } from "react-chartjs-2";
import moment from "moment";
import { startOfMonth } from "date-fns";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  Tooltip,
} from "chart.js";
import Spinner from "../UI/Spinner";
import metricService from "../../features/Metrics/metricService";

ChartJS.register(ArcElement, Tooltip, CategoryScale, LinearScale, BarElement);

function Home({ user, organisation }) {
  const yearStart = moment().startOf("year");
  const [userData, setUserData] = useState({
    total: 0,
    HCA: 0,
    RMN: 0,
    RGN: 0,
    SOCIALWORKER: 0,
  });
  const [criticalDocs, setCriticalDocs] = useState({
    PMVA: {
      valid: 0,
      expired: 0,
      expiring: 0,
      expiring30: 0,
      missing: 0,
    },
    MAPA: {
      valid: 0,
      expired: 0,
      expiring: 0,
      expiring30: 0,
      missing: 0,
    },
    BRP: {
      valid: 0,
      expired: 0,
      expiring: 0,
      expiring30: 0,
      missing: 0,
    },
    DBS: {
      valid: 0,
      expired: 0,
      expiring: 0,
      expiring30: 0,
      missing: 0,
    },
  });

  const [loading, setLoading] = useState(true);
  const [loadingCritical, setLoadingCritical] = useState(true);

  useEffect(() => {
    getUsersInit();
    getCriticalDocsStats();
  }, []);

  if (!organisation) {
    return <Spinner></Spinner>;
  }

  const newApplicants = organisation.users.filter(
    (user) => new Date(user.dateAdded) > startOfMonth(Date.now())
  );
  const compliantStaff = organisation.users.filter(
    (user) => user.staff.isCompliant
  );

  // REGION DATA
  let regions = organisation.users.map((user) => user.staff.region);

  let regionData = regions.reduce(function (allRegions, region) {
    if (region in allRegions) {
      allRegions[region]++;
    } else {
      allRegions[region] = 1;
    }
    return allRegions;
  }, {});

  const data = Object.keys(regionData).map((item) => {
    return { name: item, count: regionData[item] };
  });

  const getUsers = async (event) => {
    setLoading(true);
    const days = event.target.value ?? 7;
    const data = await metricService.getNewUsers(days);
    setUserData(data);
    setLoading(false);
  };

  const getUsersInit = async () => {
    setLoading(true);
    const data = await metricService.getNewUsers(7);
    setUserData(data);
    setLoading(false);
  };

  const getCriticalDocsStats = async () => {
    setLoadingCritical(true);
    const data = await metricService.getCriticalDocs();
    setCriticalDocs(data);
    setLoadingCritical(false);
  };

  // COMPLIANT BY MONTH DATA

  let monthsForCompliant = compliantStaff.map((user) => {
    if (user.staff.compliantBy) {
      if (moment(user.staff.compliantBy.date) > yearStart) {
        return moment(user.staff.compliantBy.date).format("MMMM");
      }
    } else {
      return null;
    }
  });

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  monthsForCompliant = monthsForCompliant.filter(Boolean).sort((a, b) => {
    return months.indexOf(a) - months.indexOf(b);
  });

  let monthsCount = monthsForCompliant.reduce(function (allMonths, month) {
    if (month in allMonths) {
      allMonths[month]++;
    } else {
      allMonths[month] = 1;
    }
    return allMonths;
  }, {});

  const monthData = Object.keys(monthsCount).map((item) => {
    return { name: item, count: monthsCount[item] };
  });

  // GRAPH DATA AND OPTIONS
  const options = {
    responsive: true,
    maintainAspectRatio: false,
  };

  // PIE CHART DATA
  const pieData = {
    labels: data.map((x) => x.name),
    datasets: [
      {
        label: "Regions by popularity",
        data: data.map((x) => x.count),
        backgroundColor: [
          "rgba(255, 99, 132, 0.8)",
          "rgba(54, 162, 235, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(75, 192, 192, 0.8)",
          "rgba(153, 102, 255, 0.8)",
          "rgba(255, 159, 64, 0.8)",
        ],
      },
    ],
  };

  // BarGRAPH DATA
  const complianceData = {
    labels: monthData.map((x) => x.name),
    datasets: [
      {
        label: "Compliance by month",
        data: monthData.map((x) => x.count),
        backgroundColor: [
          "rgba(255, 99, 132, 0.8)",
          "rgba(54, 162, 235, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(75, 192, 192, 0.8)",
          "rgba(153, 102, 255, 0.8)",
          "rgba(255, 159, 64, 0.8)",
        ],
      },
    ],
  };

  return (
    <React.Fragment>
      <div className="p-2 space-y-3">
        <div className=" p-2">
          <h2 className="font-semibold text-lg">
            Hello, {user.name} {user.surname}
          </h2>
          <p className="opacity-75">welcome to your dashboard</p>
        </div>

        <div className="flex space-x-3">
          <div className="space-y-1 bg-white  p-2 shadow-sm w-1/4">
            <h2>New applicants</h2>
            <div className="border-l-4 border-l-main px-1">
              <h1 className="text-2xl">{newApplicants.length}</h1>
            </div>
          </div>
          <div className="space-y-1 bg-white  p-2 shadow-sm w-1/4">
            <h2>Total staff</h2>
            <div className="border-l-4 border-l-main px-1">
              <h1 className="text-2xl">{organisation.users.length}</h1>
            </div>
          </div>
          <div className="space-y-1 bg-white  p-2 shadow-sm w-1/4">
            <h2>Compliant staff</h2>
            <div className="border-l-4 border-l-main px-1">
              <h1 className="text-2xl">{compliantStaff.length}</h1>
            </div>
          </div>
          <div className="space-y-1 bg-white  p-2 shadow-sm w-1/4">
            <h2>Admins</h2>
            <div className="border-l-4 border-l-main px-1">
              <h1 className="text-2xl">{organisation.admins.length}</h1>
            </div>
          </div>
        </div>
        <div className="flex space-x-3   ">
          <div className="w-1/4 bg-white p-2 space-y-3  ">
            <h2 className="underline text-center front-semibold text-lg">
              Preferred regions
            </h2>
            <div className="relative h-52 ">
              <Pie className="h-42" data={pieData} options={options} />
            </div>
          </div>
          <div className="w-3/4 bg-white p-2 ">
            <h2 className="underline text-center front-semibold text-lg">
              Compliant staff by month
            </h2>
            <div className="relative h-52 ">
              <Bar
                width="50%"
                height="10%"
                className="h-52"
                data={complianceData}
                options={options}
              />
            </div>
          </div>
        </div>
        <div className="flex space-x-3   ">
          <div className="w-1/4 bg-white p-2 space-y-3  ">
            <div className="flex justify-between">
              <h2 className="font-semibold text-lg">New Staff </h2>
              <div>
                <select name="days" id="days" onChange={getUsers}>
                  <option value="7">Last 7 Days</option>
                  <option value="30">Last 30 Days</option>
                </select>
              </div>
            </div>

            {!loading ? (
              <div className="relative py-2">
                <table className="table-fixed w-full text-lg  border">
                  <thead>
                    <tr className="w-full">
                      <th className="w-1/2 border p-2">Role</th>
                      <th className="w-1/2 border p-2">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className=" border p-2">HCA</td>
                      <td className=" border p-2">{userData.HCA}</td>
                    </tr>
                    <tr>
                      <td className=" border p-2">RGN</td>
                      <td className=" border p-2">{userData.RGN}</td>
                    </tr>
                    <tr>
                      <td className=" border p-2">RMN</td>
                      <td className=" border p-2">{userData.RMN}</td>
                    </tr>
                    <tr>
                      <td className=" border p-2">Social Worker</td>
                      <td className=" border p-2">{userData.SOCIALWORKER}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="text-center my-5">Loading...</div>
            )}
          </div>
          <div className="w-3/4 bg-white p-2 ">
            <h2 className="font-semibold text-lg mb-3">
              Critical Document Status:
            </h2>
            {!loadingCritical ? (
              <table className="table-fixed w-full text-lg  border">
                <thead>
                  <tr className="w-full">
                    <th className="w-1/2 border p-2">Name</th>
                    <th className="w-1/2 border p-2">Total Valid</th>
                    <th className="w-1/2 border p-2">
                      Expiring in less than 30 days
                    </th>
                    <th className="w-1/2 border p-2">
                      Expiring in less than 6 months
                    </th>
                    <th className="w-1/2 border p-2">Expired</th>
                    <th className="w-1/2 border p-2">Missing</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className=" border p-2">PMVA</td>
                    <td className=" border p-2">{criticalDocs.PMVA.valid}</td>
                    <td className=" border p-2">
                      {criticalDocs.PMVA.expiring30}
                    </td>
                    <td className=" border p-2">
                      {criticalDocs.PMVA.expiring}
                    </td>
                    <td className=" border p-2">{criticalDocs.PMVA.expired}</td>
                    <td className=" border p-2">{criticalDocs.PMVA.missing}</td>
                  </tr>
                  <tr>
                    <td className=" border p-2">MAPA / SI</td>
                    <td className=" border p-2">{criticalDocs.MAPA.valid}</td>
                    <td className=" border p-2">
                      {criticalDocs.MAPA.expiring30}
                    </td>
                    <td className=" border p-2">
                      {criticalDocs.MAPA.expiring}
                    </td>
                    <td className=" border p-2">{criticalDocs.MAPA.expired}</td>
                    <td className=" border p-2">{criticalDocs.MAPA.missing}</td>
                  </tr>
                  <tr>
                    <td className=" border p-2">DBS</td>
                    <td className=" border p-2">{criticalDocs.DBS.valid}</td>
                    <td className=" border p-2">
                      {criticalDocs.DBS.expiring30}
                    </td>
                    <td className=" border p-2">{criticalDocs.DBS.expiring}</td>
                    <td className=" border p-2">{criticalDocs.DBS.expired}</td>
                    <td className=" border p-2">{criticalDocs.DBS.missing}</td>
                  </tr>
                  <tr>
                    <td className=" border p-2">BRP</td>
                    <td className=" border p-2">{criticalDocs.BRP.valid}</td>
                    <td className=" border p-2">
                      {criticalDocs.BRP.expiring30}
                    </td>
                    <td className=" border p-2">{criticalDocs.BRP.expiring}</td>
                    <td className=" border p-2">{criticalDocs.BRP.expired}</td>
                    <td className=" border p-2">{criticalDocs.BRP.missing}</td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <div className="text-center mt-5">Loading...</div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Home;
