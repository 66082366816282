import React, { useState } from "react";
import NavBar from "../components/NavBar/NavBar";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Spinner from "../components/UI/Spinner";
import { toast } from "react-toastify";

function SetNewPassoword() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });

  const handleChange = (input) => (e) => {
    setFormData({ ...formData, [input]: e.target.value });
  };

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.password === formData.confirmPassword) {
      try {
        setLoading(true);
        const response = await axios.post(
          `/api/authenticate/reset/${id}`,
          formData
        );
        setLoading(false);
        if (
          response.status === 200 ||
          response.data.message.includes("Successfully")
        ) {
          // * NAVIGATE TO LOG IN PAGE
          navigate("/");
        }
      } catch (error) {
        setLoading(false);
        toast.error(
          "Failed to change password. Please go back and try again.",
          {
            position: "top-center",
            autoClose: 5000,
          }
        );
      }
    } else {
      toast.error("Passwords do not match", {
        position: "top-center",
        autoClose: 5000,
      });
    }
  };

  if (loading) {
    return <Spinner />;
  }
  return (
    <React.Fragment>
      <div className="h-screen flex flex-col">
        <NavBar />

        <form
          onSubmit={handleSubmit}
          className=" w-screen flex flex-col items-center justify-center flex-grow-1 space-y-5 p-5"
        >
          <div className=" sm:w-1/2 space-y-3">
            <h1 className="font-bold text-2xl ">Enter a new password</h1>
            <p>
              Almost there. This is the last step in reseting your password.
            </p>
          </div>
          <div className="w-full sm:w-1/2 flex flex-col ">
            <label
              className="font-semibold text-xl text-center"
              htmlFor="password"
            >
              Password
            </label>

            <input
              value={formData.password}
              className=" "
              type="password"
              name="password"
              id="password"
              onChange={handleChange("password")}
            />
          </div>
          {formData.password != formData.confirmPassword && (
            <p className="text-red font-bold font-xl text-center">
              Passwords do not match!
            </p>
          )}
          <div className="w-full sm:w-1/2 flex flex-col ">
            <label
              className="font-semibold text-xl text-center"
              htmlFor="confirmPassword"
            >
              Confirm Password
            </label>

            <input
              value={formData.confirmPassword}
              className=" "
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              onChange={handleChange("confirmPassword")}
            />
          </div>
          <div className="w-full sm:w-1/2 flex flex-col justify-center">
            <button
              disabled={
                formData.password != formData.confirmPassword ? true : false
              }
              type="submit"
              className="font-semibold text-white bg-main disabled:bg-black hover:scale-105 hover:bg-main2 px-3 py-2 rounded shadow-sm"
            >
              Submit
            </button>
          </div>
          <p
            className="hover:cursor-pointer hover:scale-105"
            onClick={() => {
              navigate("/");
            }}
          >
            Cancel password reset
          </p>
        </form>
      </div>
    </React.Fragment>
  );
}

export default SetNewPassoword;
