import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import OtpInput from "react18-input-otp";
import { toast } from "react-toastify";
import userService from "../features/Auth/userService";
import NavBar from "../components/NavBar/NavBar";

export default function Verify2FACode() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [secret, setSecret] = useState("");
  const [remember, setRemember] = useState(false);
  const [sucess, setSucess] = useState(false);

  const handleInput = (input) => (event) => {
    if (input === "secret") {
      setSecret(event.target.value);
    } else {
      setRemember(event.target.checked);
    }
  };

  const handleOTP = (otp) => {
    setSecret(otp);
  };

  useEffect(() => {
    if (!user) {
      navigate(`/`);
    }

    if (user) {
      if (user.message) {
        navigate(`/`);
      }
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { token: secret, remember };

    if (data.token === "") {
      toast.error("Please enter a valid code.");
      return;
    }

    try {
      const response = await userService.verifyToken(id, data);
      if (response.data.verified) {
        if (user.user.role === "staff") {
          navigate(`/profile/${user.user._id}`);
        }
        if (user.user.role !== "staff") {
          navigate(`/dashboard`);
        }
      }
    } catch (error) {
      toast.error("Code is invalid, please try again.", {
        position: "top-center",
        autoClose: 5000,
      });
    }
  };

  const reset = async () => {
    await localStorage.clear();
    navigate("/");
    window.location.reload(false);
  };

  return (
    <React.Fragment>
      <NavBar></NavBar>
      <div className="h-screen d-flex align-center p-4">
        <div className="border border-main rounded-lg md:w-1/2 w-full m-auto shadow shadow-sm">
          <h3 className="text-center text-2xl fw-bold p-4 text-main font-bold">
            Two-Factor Authentication (2FA)
          </h3>
          <form onSubmit={handleSubmit}>
            <div className="m-3">
              <div className="w-full">
                <label className="mb-3" htmlFor="name">
                  Please enter the code from your authenticator app.
                </label>
                <br />
                <OtpInput
                  value={secret}
                  onChange={handleOTP}
                  numInputs={6}
                  separator={<span>-</span>}
                  isInputNum="true"
                  containerStyle="justify-center"
                  inputStyle="h-[3rem] !w-[3rem] justify-center"
                />
              </div>
              <div className="my-3 ">
                <div className="w-full d-flex items-center gap-2">
                  <input
                    className=""
                    type="checkbox"
                    name="remember"
                    id="remember"
                    onChange={handleInput("remeber")}
                  />
                  <label className="m-0" htmlFor="remember">
                    Don't ask me again
                  </label>
                </div>
              </div>
            </div>
            <div className="m-3 d-flex justify-center gap-3">
              <button
                type="submit"
                className="w-full font-semibold shadow rounded-lg  bg-main hover:bg-main2 py-2 px-3 text-[#FFF] disabled:bg-gray-300"
                disabled={secret.length < 6}
              >
                verify
              </button>
            </div>
          </form>
          <div className="m-3 d-flex justify-center gap-3">
            <button
              type="button"
              onClick={reset}
              className="w-full font-semibold shadow rounded-lg  bg-white hover:bg-main2 py-2 px-3 text-main border border-main"
            >
              cancel
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
