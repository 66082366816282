import React from 'react';

function LogsPage() {
    return (
        <div className=" h-full flex justify-center items-center text-4xl font-black">
            Feature currently under maintenance...
        </div>
    );
}

export default LogsPage;
