import React from "react";

function Audit({ profile }) {
  console.log(profile.profile.rightToWork);
  return (
    <React.Fragment>
      <div className="space-y-4 overflow-hidden">
        <div className="flex-col ">
          <div className=" border  rounded ">
            <h1 className="bg-main2 text-white p-3 text-lg">
              IDENTITY VALIDATION
            </h1>
          </div>
          <div className="flex border">
            <p className="p-3 w-4/5 border-r">
              Enhanced DBS check / DBS Update Service Check (where applicable){" "}
            </p>
            {/**  SHOULD BE CHECKING DOCUMENTS FOR DBS OR API **/}
            <div className=" w-1/5 text-center">
              {profile.profile.dbs ? (
                <p className="bg-[#00FF00] p-3">Valid</p>
              ) : (
                <p className="bg-[#FF0000] p-3">Invalid</p>
              )}
            </div>
          </div>
          <div className="flex border">
            <p className="p-3 w-4/5 border-r">
              Proof of identity verified (formal records of interview, 2 forms
              of photographic identification)
            </p>
            {/**  CHECK FOR INTERVIEWS AND TWO IDs **/}
            <div className=" w-1/5 text-center">
              {!profile.profile.identity ? (
                <p className="bg-[#FF0000] p-3">Invalid</p>
              ) : (
                <p className="bg-[#00FF00] p-3">Valid</p>
              )}
            </div>
          </div>
          {profile.role !== "HCA" && (
            <div className="flex items-center border">
              <p className="p-3 w-4/5 border-r">
                Registration with Professional and Regulatory Bodies (Original
                certificate or statement of entry verified & regular ongoing NMC
                web pin checks evidenced prior to start date and on-going status
                checks) – If unable to provide then evidence a selfdeclaration
                along with validation of their registration.
              </p>
              {/**  CHECK FOR INTERVIEWS AND TWO IDs **/}
              <div className=" w-1/5 text-center ">
                {!profile.profile.professionalReg ? (
                  <p className="bg-[#FF0000] p-3 ">Invalid</p>
                ) : (
                  <p className="bg-[#00FF00] p-3">Valid</p>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="flex-col ">
          <div className=" border  rounded">
            <h1 className="bg-main2 text-white p-3 text-lg uppercase">
              Agency Worker Regulatory Compliance
            </h1>
          </div>

          <div className="flex items-center border">
            <p className="p-3 w-4/5 border-r">
              Proof of eligibility verified (Right to Work) – passport front
              covers not required as per Home Office Guidelines
            </p>
            {/**  CHECK FOR INTERVIEWS AND TWO IDs **/}
            <div className=" w-1/5 text-center ">
              {!profile.profile.rightToWork ? (
                <p className="bg-[#FF0000] p-3 ">Invalid</p>
              ) : (
                <p className="bg-[#00FF00] p-3">Valid</p>
              )}
            </div>
          </div>

          <div className="flex items-center border">
            <p className="p-3 w-4/5 border-r">
              WTR verification of Opt in or out of 48 hr. declaration.
            </p>

            <div className=" w-1/5 text-center ">
              {!profile.profile.optinorout ? (
                <p className="bg-[#FF0000] p-3 ">Invalid</p>
              ) : (
                <p className="bg-[#00FF00] p-3">Valid</p>
              )}
            </div>
          </div>
          <div className="flex items-center border">
            <p className="p-3 w-4/5 border-r">
              AWR entitlement (parity pay, benefits and day one rights) detailed
              in contracts or handbook for Temporary worker
            </p>

            <div className=" w-1/5 text-center ">
              {profile.profile.employeeHandbook ||
              profile.profile.employeeContract ? (
                <p className="bg-[#00FF00] p-3">Valid</p>
              ) : (
                <p className="bg-[#FF0000] p-3 ">Invalid</p>
              )}
            </div>
          </div>
          <div className="flex items-center border">
            <p className="p-3 w-4/5 border-r">
              AWR Process in place with clock pause and STOP
            </p>

            <div className=" w-1/5 text-center ">
              {!profile.profile.clockStopForm ? (
                <p className="bg-[#FF0000] p-3 ">Invalid</p>
              ) : (
                <p className="bg-[#00FF00] p-3">Valid</p>
              )}
            </div>
          </div>
          <div className="flex items-center border">
            <p className="p-3 w-4/5 border-r">
              Temporary contractor contract for services verified.
            </p>

            <div className=" w-1/5 text-center ">
              {!profile.profile.employeeContract ? (
                <p className="bg-[#FF0000] p-3 ">Invalid</p>
              ) : (
                <p className="bg-[#00FF00] p-3">Valid</p>
              )}
            </div>
          </div>
        </div>
        <div className="flex-col ">
          <div className=" border  rounded">
            <h1 className="bg-main2 text-white p-3 text-lg uppercase">
              Occupational Health
            </h1>
          </div>
          <div className="flex items-center border">
            <p className="p-3 w-4/5 border-r">
              Fitness to Work Certificate restrictions & follow up
              recommendations to customers
            </p>

            <div className=" w-1/5 text-center ">
              {!profile.profile.fitnessToWork ? (
                <p className="bg-[#FF0000] p-3 ">Invalid</p>
              ) : (
                <p className="bg-[#00FF00] p-3">Valid</p>
              )}
            </div>
          </div>
          <div className="flex items-center border">
            <p className="p-3 w-4/5 border-r">
              Evidence of assigned copy of Infection Prevention Control has been
              completed and signed by contractor
            </p>

            <div className=" w-1/5 text-center ">
              {!profile.profile.infectionPreventionControl ? (
                <p className="bg-[#FF0000] p-3 ">Invalid</p>
              ) : (
                <p className="bg-[#00FF00] p-3">Valid</p>
              )}
            </div>
          </div>
        </div>
        <div className="flex-col ">
          <div className=" border  rounded">
            <h1 className="bg-main2 text-white p-3 text-lg uppercase">
              Professional Qualifications
            </h1>
          </div>

          <div className="flex items-center border">
            <p className="p-3 w-4/5 border-r">
              Details evidenced of previous and upcoming appraisals (including
              annual appraisal) or Revalidation
            </p>

            <div className=" w-1/5 text-center ">
              {!profile.profile.appraisalForm ? (
                <p className="bg-[#FF0000] p-3 ">Invalid</p>
              ) : (
                <p className="bg-[#00FF00] p-3">Valid</p>
              )}
            </div>
          </div>
          <div className="flex items-center border">
            <p className="p-3 w-4/5 border-r">
              Completed application form / registration evidenced
            </p>

            <div className=" w-1/5 text-center ">
              <p className="bg-[#00FF00] p-3">Valid</p>
            </div>
          </div>
          <div className="flex items-center border">
            <p className="p-3 w-4/5 border-r">
              Face to face interview verified (Video Conferencing acceptable but
              need to evidence that original documents have been seen and
              verified) - If agency can maintain copies of the recording a
              screenshot to be shown on day of audit
            </p>

            <div className=" w-1/5 text-center ">
              {!profile.profile.proofOfInterview ? (
                <p className="bg-[#FF0000] p-3 ">Invalid</p>
              ) : (
                <p className="bg-[#00FF00] p-3">Valid</p>
              )}
            </div>
          </div>
          <div className="flex items-center border">
            <p className="p-3 w-4/5 border-r">
              Skills assessment verified as appropriate to the Job profile.
            </p>

            <div className=" w-1/5 text-center ">
              {!profile.profile.skillAssessment ? (
                <p className="bg-[#FF0000] p-3 ">Invalid</p>
              ) : (
                <p className="bg-[#00FF00] p-3">Valid</p>
              )}
            </div>
          </div>
          <div className="flex items-center border">
            <p className="p-3 w-4/5 border-r">
              ID Badge Verified – Recommendation best practice renew each year –
              if older than 1 year pass – I.D. badge must match professional
              registration if not fail.
            </p>

            <div className=" w-1/5 text-center ">
              {!profile.profile.id ? (
                <p className="bg-[#FF0000] p-3 ">Invalid</p>
              ) : (
                <p className="bg-[#00FF00] p-3">Valid</p>
              )}
            </div>
          </div>
          <div className="flex items-center border">
            <p className="p-3 w-4/5 border-r">
              Evidence of contractors ten year work history (where applicable)
              on a prospective CV verifying any gaps in employment over three
              months.
            </p>

            <div className=" w-1/5 text-center ">
              {!profile.profile.cv ? (
                <p className="bg-[#FF0000] p-3 ">Invalid</p>
              ) : (
                <p className="bg-[#00FF00] p-3">Valid</p>
              )}
            </div>
          </div>
          <div className="flex items-center border">
            <p className="p-3 w-4/5 border-r">
              References verified (electronic accepted from the referees’
              official email address with the relevant employer), a minimum of 2
              required unless the temporary worker has been with the same
              employer for the full 3 year period - 3 years required to validate
              employment history - if the agency worker has been with the agency
              for the previous 12 months feedback forms can be used to confirm
              the employment over this period.
            </p>

            <div className=" w-1/5 text-center ">
              {profile.profile.references ? (
                <p className="bg-[#00FF00] p-3">Valid</p>
              ) : (
                <p className="bg-[#FF0000] p-3 ">Invalid</p>
              )}
            </div>
          </div>
        </div>
        <div className="flex-col ">
          <div className=" border  rounded">
            <h1 className="bg-main2 text-white p-3 text-lg uppercase">
              Evidence of Training Records
            </h1>
          </div>

          <div className="flex items-center border">
            <p className="p-3 w-4/5 border-r">
              Basic and/or Immediate Life support (adult or paediatric, as
              appropriate) in accordance with the relevant Job profile.profile
              and which is compliant always with Resuscitation Council UK and
              the Cygnet's guidelines, as appropriate, and has been delivered by
              means of a practical course.
            </p>

            <div className=" w-1/5 text-center ">
              {!profile.profile.immediateLifeSupport ? (
                <p className="bg-[#FF0000] p-3 ">Invalid</p>
              ) : (
                <p className="bg-[#00FF00] p-3">Valid</p>
              )}
            </div>
          </div>
          <div className="flex items-center border">
            <p className="p-3 w-4/5 border-r">Lone Worker Training</p>

            <div className=" w-1/5 text-center ">
              {!profile.profile.loneworker ? (
                <p className="bg-[#FF0000] p-3 ">Invalid</p>
              ) : (
                <p className="bg-[#00FF00] p-3">Valid</p>
              )}
            </div>
          </div>
          <div className="flex items-center border">
            <p className="p-3 w-4/5 border-r">
              Food hygiene & hygiene awareness as appropriate to the Job
              profile.profiler
            </p>

            <div className=" w-1/5 text-center ">
              {!profile.profile.food ? (
                <p className="bg-[#FF0000] p-3 ">Invalid</p>
              ) : (
                <p className="bg-[#00FF00] p-3">Valid</p>
              )}
            </div>
          </div>
          <div className="flex items-center border">
            <p className="p-3 w-4/5 border-r">
              Mental Health Act & Mental Capacity Act as appropriate to the Job
              profile.profile
            </p>

            <div className=" w-1/5 text-center ">
              {!profile.profile.mental ? (
                <p className="bg-[#FF0000] p-3 ">Invalid</p>
              ) : (
                <p className="bg-[#00FF00] p-3">Valid</p>
              )}
            </div>
          </div>
          {/* MAPA OR PMVA */}
          <div className="flex items-center border">
            <p className="p-3 w-4/5 border-r">
              Positive & Safe Care skills & techniques, including personal
              safety, control & restraint as appropriate to the Job
              profile.profile (Annual) - for PMVA "West London NHS Standard for
              breakaway and restraint" / MAPA "Crisis Prevention Institute
              Standard with Blue Card"
            </p>

            <div className=" w-1/5 text-center ">
              {!profile.profile.MAPA ? (
                <p className="bg-[#FF0000] p-3 ">Invalid</p>
              ) : (
                <p className="bg-[#00FF00] p-3">Valid</p>
              )}
            </div>
          </div>
          <div className="flex items-center border">
            <p className="p-3 w-4/5 border-r">
              Health, Safety and Welfare (Annual)
            </p>

            <div className=" w-1/5 text-center ">
              {!profile.profile.healthSafety ? (
                <p className="bg-[#FF0000] p-3 ">Invalid</p>
              ) : (
                <p className="bg-[#00FF00] p-3">Valid</p>
              )}
            </div>
          </div>
          <div className="flex items-center border">
            <p className="p-3 w-4/5 border-r">
              Infection Prevention (Included MRSA & Clostridium Difficile) Level
              1 (every 3 years) or 2 (Annual)
            </p>

            <div className=" w-1/5 text-center ">
              {!profile.profile.infectionPreventionControl ? (
                <p className="bg-[#FF0000] p-3 ">Invalid</p>
              ) : (
                <p className="bg-[#00FF00] p-3">Valid</p>
              )}
            </div>
          </div>
          <div className="flex items-center border">
            <p className="p-3 w-4/5 border-r">Complaints Handling</p>

            <div className=" w-1/5 text-center ">
              {!profile.profile.conflict ? (
                <p className="bg-[#FF0000] p-3 ">Invalid</p>
              ) : (
                <p className="bg-[#00FF00] p-3">Valid</p>
              )}
            </div>
          </div>
          <div className="flex items-center border">
            <p className="p-3 w-4/5 border-r">
              Fire Safety – Some practical (induction on job training) and
              online
            </p>

            <div className=" w-1/5 text-center ">
              {!profile.profile.fire ? (
                <p className="bg-[#FF0000] p-3 ">Invalid</p>
              ) : (
                <p className="bg-[#00FF00] p-3">Valid</p>
              )}
            </div>
          </div>
          <div className="flex items-center border">
            <p className="p-3 w-4/5 border-r">
              Safeguarding Adults level 1 or 2 (Annual)
            </p>

            <div className=" w-1/5 text-center ">
              {!profile.profile.safeguarding ? (
                <p className="bg-[#FF0000] p-3 ">Invalid</p>
              ) : (
                <p className="bg-[#00FF00] p-3">Valid</p>
              )}
            </div>
          </div>
          <div className="flex items-center border">
            <p className="p-3 w-4/5 border-r">
              Safeguarding Children level 1, 2 & 3 (Annual) - only applicable if
              supply CAMHS Services
            </p>

            <div className=" w-1/5 text-center ">
              {!profile.profile.safeguarding ? (
                <p className="bg-[#FF0000] p-3 ">Invalid</p>
              ) : (
                <p className="bg-[#00FF00] p-3">Valid</p>
              )}
            </div>
          </div>
          {/* @todo : INDUCTION AND ORIENTATION TRAINING */}
          <div className="flex items-center border">
            <p className="p-3 w-4/5 border-r">
              Moving and Handling (loads and people, Level 1 or 2) - Online
              Assessment accepted
            </p>

            <div className=" w-1/5 text-center ">
              {!profile.profile.moving ? (
                <p className="bg-[#FF0000] p-3 ">Invalid</p>
              ) : (
                <p className="bg-[#00FF00] p-3">Valid</p>
              )}
            </div>
          </div>
          <div className="flex items-center border">
            <p className="p-3 w-4/5 border-r">
              Evidence of numeracy and literacy test pass by the contractors (
              IELTS Level 7 or above Occupational English Test certificate for
              overseas candidates: grade B or A, GCSC English Pass for UK
              candidates )
            </p>

            <div className=" w-1/5 text-center ">
              {!profile.profile.literacyTest ? (
                <p className="bg-[#FF0000] p-3 ">Invalid</p>
              ) : (
                <p className="bg-[#00FF00] p-3">Valid</p>
              )}
            </div>
          </div>
          <div className="flex items-center border">
            <p className="p-3 w-4/5 border-r">
              Confidentiality, security checks and Disclaimer to include
              agreement to accept third party audit evidenced
            </p>

            <div className=" w-1/5 text-center ">
              {!profile.profile.confidentiality ? (
                <p className="bg-[#FF0000] p-3 ">Invalid</p>
              ) : (
                <p className="bg-[#00FF00] p-3">Valid</p>
              )}
            </div>
          </div>
          <div className="flex items-center border">
            <p className="p-3 w-4/5 border-r">
              Equality, Diversity & Human Rights
            </p>

            <div className=" w-1/5 text-center ">
              {!profile.profile.equality ? (
                <p className="bg-[#FF0000] p-3 ">Invalid</p>
              ) : (
                <p className="bg-[#00FF00] p-3">Valid</p>
              )}
            </div>
          </div>
          <div className="flex items-center border">
            <p className="p-3 w-4/5 border-r">
              The Caldicott Principles / Information Governance
            </p>

            <div className=" w-1/5 text-center ">
              {!profile.profile.info ? (
                <p className="bg-[#FF0000] p-3 ">Invalid</p>
              ) : (
                <p className="bg-[#00FF00] p-3">Valid</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Audit;
