import { useEffect, useState } from "react";
import Spinner from "../UI/Spinner";
import { CSVLink } from "react-csv";
import metricService from "../../features/Metrics/metricService";
import profileService from "../../features/Profile/profileService";

function StaffReports({ organisation }) {
  const [data, setData] = useState();

  useEffect(() => {
    async function fetchData() {
      setData(await metricService.getUserReport(organisation.organisation));
    }

    fetchData();
  }, []);

  if (!data) {
    return <Spinner></Spinner>;
  }

  return (
    <div className="p-3">
      <h1 className="text-2xl font-bold">Reports</h1>
      <hr />
      <CSVLink data={data} filename={"my-file.csv"} className="">
        Export CSV
      </CSVLink>
    </div>
  );
}

export default StaffReports;
