import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {editAdmin} from "../../features/Profile/profileSlice";

function Settings({admin}) {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        name: admin.name,
        surname: admin.surname,
        phone: admin.phone,
        email: admin.email,
        expiringDocs: admin.expiringDocs,
        newApplicants: admin.newApplicants,
        manager: admin.manager,
        booking: admin.booking,
        compliance: admin.compliance,
    });

    const handleChange = (e) => {
        const {name, value} = e.target;

        setFormData({...formData, [name]: value});
    };

    const handleCheckBox = (e) => {
        const {name, checked} = e.target;

        setFormData({...formData, [name]: checked});
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const adminData = {id: admin._id, ...formData};

        dispatch(editAdmin(adminData));
    };

    return (
        <form className="space-y-3 w-100" onSubmit={handleSubmit}>
            <h4 className="font-bold text-xl text-center">Edit Details</h4>
            <div className="flex space-x-5 w-100 ">
                <div className="w-50">
                    <label htmlFor="name">Name</label>
                    <br/>
                    <input
                        className="w-100"
                        type="text"
                        name="name"
                        onChange={handleChange}
                        value={formData.name}
                    />
                </div>
                <div className="w-50">
                    <label htmlFor="surname">Surname</label>
                    <br/>
                    <input
                        className="w-100"
                        type="text"
                        name="surname"
                        onChange={handleChange}
                        value={formData.surname}
                    />
                </div>
            </div>
            <div className="flex space-x-5 ">
                <div className="w-50">
                    <label htmlFor="phone">Phone</label>
                    <br/>
                    <input
                        className="w-100"
                        type="text"
                        name="phone"
                        onChange={handleChange}
                        value={formData.phone}
                    />
                </div>
                <div className="w-50">
                    <label htmlFor="email">Email</label>
                    <br/>
                    <input
                        disabled
                        type="email"
                        onChange={handleChange}
                        name="email"
                        className="w-100 bg-gray-600"
                        value={formData.email}
                    />
                </div>
            </div>

            <h4 className="font-bold text-xl">Group:</h4>
            <div className="flex space-x-5">
                <div className="space-x-2 ">
                    <input
                        type="checkbox"
                        name="compliance"
                        id="compliance"
                        onChange={handleCheckBox}
                        checked={formData.compliance}
                    />
                    <label htmlFor="">Compliance</label>
                </div>
                <div className="space-x-2 ">
                    <input
                        type="checkbox"
                        name="booking"
                        id="booking"
                        onChange={handleCheckBox}
                        checked={formData.booking}
                    />
                    <label htmlFor="">Bookings</label>
                </div>
                <div className="space-x-2 ">
                    <input
                        onChange={handleCheckBox}
                        type="checkbox"
                        name="manager"
                        id="manager"
                        checked={formData.manager}
                    />
                    <label htmlFor="">Manager</label>
                </div>
            </div>
            <h4 className="font-bold text-xl">Notifications:</h4>
            <div className="flex space-x-5">
                <div className="space-x-2 ">
                    <input
                        onChange={handleCheckBox}
                        type="checkbox"
                        name="newApplicants"
                        id="newapplicants"
                        checked={formData.newApplicants}
                    />
                    <label htmlFor="">New Applicants</label>
                </div>
                <div className="space-x-2 ">
                    <input
                        onChange={handleCheckBox}
                        type="checkbox"
                        name="expiringDocs"
                        id="expiringDocs"
                        checked={formData.expiringDocs}
                    />
                    <label htmlFor="">Expiring Documents</label>
                </div>
            </div>
            <button
                type="submit"
                className="font-semibold shadow   rounded-lg  bg-main2 hover:bg-main py-2 px-2 text-white flex justify-center w-full"
            >
                Save Changes
            </button>
        </form>
    );
}

export default Settings;
