import React from "react";
import { CgUserList } from "react-icons/cg";
import { BiIdCard } from "react-icons/bi";
import { FaMapMarkedAlt, FaPassport } from "react-icons/fa";

function BasicInfo({ staff }) {
  return (
    <React.Fragment>
      <div className="space-y-2">
        <div className="flex items-center text-2xl space-x-3 font-bold ">
          <CgUserList /> <span>ABOUT</span>
        </div>
        <div className="flex space-x-4">
          <div className="w-1/2">
            <h1 className=" w-full text-xl font-bold">Name</h1>
            <h1>{staff.name}</h1>
          </div>
          <div>
            <h1 className="text-xl font-bold">Surname</h1>
            <h1>{staff.surname}</h1>
          </div>
        </div>
        <div className="flex space-x-4">
          <div className="w-1/2">
            <h1 className="text-xl font-bold">Phone</h1>
            <h1>{staff.phone}</h1>
          </div>
          <div>
            <h1 className="text-xl font-bold">Email</h1>
            <h1>{staff.email}</h1>
          </div>
        </div>
        <div className="flex space-x-4">
          <div className="w-1/2">
            <h1 className="text-xl font-bold">Nationality</h1>
            <h1>
              {staff.staff.nationality
                ? staff.staff.nationality
                : "Not Available"}
            </h1>
          </div>
          <div>
            <h1 className="text-xl font-bold">Date of Birth</h1>
            <h1>
              {staff.staff.dateofbirth
                ? new Date(staff.staff.dateofbirth).toLocaleDateString("en-GB")
                : "Not Available"}
            </h1>
          </div>
        </div>
        <hr />
        <div className="flex items-center text-2xl space-x-3 font-bold ">
          <BiIdCard /> <span>PROOF OF ID</span>
        </div>
        <div className="flex space-x-4">
          <div className="w-1/2">
            <h1 className="text-xl font-bold">
              {staff.staff.proofOfId} Number
            </h1>
            <h1>{staff.staff.passportnumber}</h1>
          </div>
          <div>
            <h1 className="text-xl font-bold">Issue Office</h1>
            <h1>{staff.staff.passportissue}</h1>
          </div>
        </div>
        {staff.staff.proofOfId === "Passport" && (
          <div className="flex space-x-4">
            <div className="w-1/2">
              <h1 className="text-xl font-bold">Passport Start</h1>
              <h1>
                {new Date(staff.staff.passportstart).toDateString("en-GB")}
              </h1>
            </div>
            <div>
              <h1 className="text-xl font-bold">Passport Expiry</h1>
              <h1>
                {new Date(staff.staff.passportexpiry).toDateString("en-GB")}
              </h1>
            </div>
          </div>
        )}
        <hr />
        <div className="flex items-center text-2xl space-x-3 font-bold ">
          <FaPassport /> <span>RIGHT TO WORK</span>
        </div>
        <div className="flex space-x-4">
          <div className="w-1/2">
            <h1 className="text-xl font-bold">DBS Number</h1>
            <h1>
              {staff.staff.dbscert ? staff.staff.dbsnumber : "Not available"}
            </h1>
          </div>
          <div>
            <h1 className="text-xl font-bold">DBS valid from</h1>
            <h1>
              {staff.staff.dbscert
                ? new Date(staff.staff.dbsissue).toDateString("en-GB")
                : "Not available"}
            </h1>
          </div>
        </div>
        <div className="flex space-x-4">
          <div className="w-1/2">
            <h1 className="text-xl font-bold">DBS Level</h1>
            <h1>
              {staff.staff.dbscert ? staff.staff.dbslevel : "Not available"}
            </h1>
          </div>
        </div>

        {staff.staff.righttowork != "UK Citizen" && (
          <div>
            <hr />
            <div>
              <div className="flex items-center text-2xl space-x-3 font-bold ">
                <FaPassport /> <span>VISA DETAILS</span>
              </div>
              <div className="flex space-x-4">
                <div className="w-1/2">
                  <h1 className="text-xl font-bold">Visa Number</h1>
                  <h1>{staff.staff.visanumber}</h1>
                </div>
                <div>
                  <h1 className="text-xl font-bold">Visa Expiry</h1>
                  <h1>
                    {new Date(staff.staff.visaexpiry).toDateString("en-GB")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        )}
        <div>
          <hr />
          <div>
            <div className="flex items-center text-2xl space-x-3 font-bold ">
              <FaMapMarkedAlt /> <span>ADDRESS</span>
            </div>
            <div className="flex ">
              <div className="w-1/3">
                <h1 className="text-xl font-bold">House</h1>
                <h1>{staff.staff.houseaddress}</h1>
              </div>
              <div className="w-1/3">
                <h1 className="text-xl font-bold">Street</h1>
                <h1>{staff.staff.streetaddress}</h1>
              </div>
              <div className="w-1/3">
                <h1 className="text-xl font-bold">City</h1>
                <h1>{staff.staff.city}</h1>
              </div>
            </div>
            <div className="flex">
              <div className="w-1/3">
                <h1 className="text-xl font-bold">County</h1>
                <h1>{staff.staff.province}</h1>
              </div>
              <div className="w-1/3">
                <h1 className="text-xl font-bold">Postal Code</h1>
                <h1>{staff.staff.zip}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default BasicInfo;
