import instance from "../../components/AxiosInterceptor";

const API_URL = "/api/group/";
// Get GroupS
const getGroups = async (id) => {
  const response = await instance.get(API_URL);

  return response.data;
};

// Get GroupS
const deleteGroup = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await instance.delete(API_URL + `${id}`, config);
  localStorage.setItem("groups", JSON.stringify(response.data));

  return response.data;
};

// CREATE GROUP
const createGroup = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await instance.post(API_URL, data, config);

  localStorage.setItem("groups", JSON.stringify(response.data));

  return response.data;
};

// GET Group
const getGroup = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await instance.get(API_URL + `${id}`, config);
  localStorage.setItem("group", JSON.stringify(response.data));

  return response.data;
};

const removeFromGroup = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const members = data.members;

  const response = await instance.post(
    API_URL + `${data.groupId}/remove_members`,
    members,
    config
  );
  localStorage.setItem("group", JSON.stringify(response.data));

  return response.data;
};

//ADD MEMBERS
const editGroup = async (token, id, members) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await instance.post(
    API_URL + `${id}/add_members`,
    members,
    config
  );
  localStorage.setItem("group", JSON.stringify(response.data));

  return response.data;
};

const groupService = {
  getGroups,
  editGroup,
  getGroup,
  removeFromGroup,
  createGroup,
  deleteGroup,
};

export default groupService;
