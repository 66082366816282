import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  createGroup,
  deleteGroup,
  reset,
} from "../../features/Groups/groupsSlice";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Spinner from "../UI/Spinner";
import SingleGroup from "../Groups/SingleGroup";
import { ThemeProvider, createTheme } from "@mui/material";
import MaterialTable from "material-table";

function Groups({ token, organisation }) {
  const defaultMaterialTheme = createTheme();
  const dispatch = useDispatch();
  const { isSuccess, isLoading, isError, message } = useSelector(
    (state) => state.group
  );
  const [table, setTable] = useState(true);
  const [id, setId] = useState("");
  const [formData, setFormData] = useState({
    name: "",
  });
  const [groups, setGroups] = useState(
    JSON.parse(localStorage.getItem("groups"))
  );

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  // TODO GET FROM REDUX
  useEffect(() => {
    if (isError) {
      console.log(message);
    }

    axios.get(`/api/group`, config).then(
      (result) => {
        localStorage.setItem("groups", JSON.stringify(result.data));
        setGroups(result.data);
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      reset();
    };
  }, [isSuccess, isLoading]);

  if (!groups) {
    return <Spinner />;
  }

  const handleChange = (input) => (e) => {
    setFormData({ ...formData, [input]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      name: formData.name,
      organisation: organisation.organisation,
    };
    dispatch(createGroup(data));

    toast.success(`${formData.name} created successfully.`);
  };

  return (
    <React.Fragment>
      {table ? (
        <div>
          <div className="p-2 space-y-4">
            <div>
              <h1 className="font-bold text-3xl underline">My groups</h1>
            </div>
            <form onSubmit={handleSubmit} className="">
              <div className="w-full space-y-4">
                <div className="space-y-4">
                  <div className="space-y-4  ">
                    <p className="text-center font-semibold">
                      Enter a name below to create a new group.
                    </p>

                    <label for="name" className="form-label">
                      Group name
                    </label>
                    <br />
                    <input
                      className="w-full"
                      type="text"
                      id="name"
                      name="name"
                      onChange={handleChange("name")}
                    />
                  </div>
                </div>
                <button className="font-semibold shadow  w-full   rounded-lg  bg-main hover:bg-main py-2 px-3 text-[#FFF]">
                  Create new group
                </button>
              </div>
            </form>
            <div>
              <div style={{ maxWidth: "100%" }}>
                <link
                  rel="stylesheet"
                  href="https://fonts.googleapis.com/icon?family=Material+Icons"
                />
                <ThemeProvider theme={defaultMaterialTheme}>
                  <MaterialTable
                    columns={[
                      { title: "Name", field: "name" },
                      { title: "Created By", field: "createdBy" },
                    ]}
                    data={groups}
                    isLoading={isLoading}
                    options={{
                      actionsColumnIndex: -1,
                      rowStyle: {
                        padding: "0 1rem",
                        textAlign: "center",
                      },
                      headerStyle: {
                        padding: "0 1rem",
                        fontWeight: "bold",
                        fontSize: "1.3rem",
                      },
                      pageSizeOptions: [10, 20, 50],
                      pageSize: 10,
                    }}
                    actions={[
                      {
                        icon: "chevron_right",
                        tooltip: "View profile",
                        onClick: (event, rowData) => {
                          setTable(false);
                          setId(rowData._id);
                        },
                      },
                      {
                        icon: "delete",
                        tooltip: "Delete group",
                        onClick: (event, rowData) => {
                          dispatch(deleteGroup(rowData._id));
                        },
                      },
                    ]}
                    title="All Groups"
                  />
                </ThemeProvider>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="p-4 text-3xl font-black flex items-center  ">
            <span
              className="cursor-pointer hover:scale-105"
              onClick={() => {
                setTable(true);
              }}
            >
              Back to groups
            </span>
          </div>
          <div>
            {!id ? (
              "No ID"
            ) : (
              <SingleGroup
                id={id}
                selectedGroup={groups.filter((group) => group._id === id)}
                staff={organisation.users}
              />
            )}{" "}
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default Groups;
