import React from "react";
import { useQuery } from "react-query";
import adminService from "../../features/Admin/adminService";
import { format } from "date-fns";

function Home() {
  const fetchOrganisations = async () => {
    return await adminService.getOrganisations();
  };

  const fetchNewUsers = async () => {
    return await adminService.getCountUsers();
  };

  const {
    isLoading: isLoadingOrganisations,
    isError,
    data,
    error,
  } = useQuery("organisations", fetchOrganisations);

  const {
    isLoading: isLoadingNewUser,
    isError: isUserError,
    data: newUserData,
    error: newUserError,
  } = useQuery("new-users", fetchNewUsers);

  const newestOrganisations = data?.data
    .filter((org) => {
      {
        if (org.name === "DEMO ACCOUNT" || org.name === "Complaint Aid") {
          return false;
        }
        return true;
      }
    })
    .sort((a, b) => new Date(b.date) - new Date(a.date))
    .slice(0, 3);

  const largestOrganisations = data?.data
    .filter((org) => {
      if (org.name === "DEMO ACCOUNT" || org.name === "Complaint Aid") {
        return false;
      }
      return true;
    })
    .sort((a, b) => b.totalStaff - a.totalStaff)
    .slice(0, 3);

  const date = new Date();
  const month = format(date, "LLLL");

  return (
    <div className="flex w-100">
      <div className="flex-1 mx-3">
        <h1 className="my-3 text-xl font-bold text-center">
          New Organisations
        </h1>
        <table className=" table-auto w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th className="px-4 py-2">Organisation</th>
              <th className="px-4 py-2">Total Staff</th>
            </tr>
          </thead>
          <tbody>
            {data ? (
              newestOrganisations.map((organisation, i) => {
                return (
                  <tr
                    key={i}
                    className="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                  >
                    <td className="px-4 py-2">{organisation.name}</td>

                    <td className="px-4 py-2">{organisation.totalStaff}</td>
                  </tr>
                );
              })
            ) : (
              <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                <td className="px-4 py-2">Nothing to display</td>

                <td className="px-4 py-2">000</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="flex-1">
        <div className="flex-1 mx-3">
          <h1 className="my-3 text-xl font-bold text-center">
            Largest Organisations
          </h1>
          <table className=" table-auto w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th className="px-4 py-2">Organisation</th>
                <th className="px-4 py-2">Total Staff</th>
              </tr>
            </thead>
            <tbody>
              {data ? (
                largestOrganisations.map((organisation, i) => {
                  return (
                    <tr
                      key={i}
                      className="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                    >
                      <td className="px-4 py-2">{organisation.name}</td>

                      <td className="px-4 py-2">{organisation.totalStaff}</td>
                    </tr>
                  );
                })
              ) : (
                <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                  <td className="px-4 py-2">Nothing to display</td>

                  <td className="px-4 py-2">000</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex-1 mx-3 text-center">
        <h1 className="my-3 text-xl font-bold">New this {month}</h1>
        <svg viewBox="0 0 56 18">
          <text x="15" y="15 ">
            {isLoadingNewUser ? "loading..." : newUserData.data}
          </text>
        </svg>
      </div>
    </div>
  );
}

export default Home;
