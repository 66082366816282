import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import profileService from "./profileService";

// Get user from localStorage
const profile = JSON.parse(localStorage.getItem("profile"));

try {
    if (profile.message?.length) {
        localStorage.clear()
    }
} catch (error) {

}



const initialState = {
    profile: profile ? profile : null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
};

// FETCH USER
export const getProfile = createAsyncThunk(
    "profile/getOne",
    async (id, thunkAPI) => {
        try {
            const token = thunkAPI.getState().user.user.token;

            return await profileService.getProfile(token, id);
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);


//ADD TRAINING
export const addTraining = createAsyncThunk(
    "training/add",
    async (toAdd, thunkAPI) => {
        try {
            const token = thunkAPI.getState().user.user.token;

            const id = toAdd.id;

            const formData = {
                name: toAdd.name,
                datetrained: toAdd.datetrained,
                expirydate: toAdd.expirydate,
                image: toAdd.image,
            };

            return await profileService.addTraining(token, id, formData);
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

//Verify TRAINING
export const verifyTraining = createAsyncThunk(
    "training/verify",
    async (id, thunkAPI) => {
        try {
            const token = thunkAPI.getState().user.user.token;

            return await profileService.verifyTraining(token, id);
        } catch (error) {
            console.log(error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

//revoke TRAINING
export const revokeTraining = createAsyncThunk(
    "training/revoke",
    async (id, thunkAPI) => {
        try {
            const token = thunkAPI.getState().user.user.token;

            return await profileService.revokeTraining(token, id);
        } catch (error) {
            console.log(error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

// DELETE TRAINING
export const deleteTraining = createAsyncThunk(
    "training/delete",
    async (userData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().user.user.token;

            return await profileService.deleteTraining(token, userData);
        } catch (error) {
            console.log(error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

//ADD DOCUMENT
export const addDocument = createAsyncThunk(
    "document/add",
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().user.user.token;

            const id = data.id;

            const formData = {
                doctype: data.doctype,
                image: data.image,
            };

            return await profileService.addDocument(token, id, formData);
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

//ADD DOCUMENT
export const changePic = createAsyncThunk(
    "profile/change",
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().user.user.token;

            const id = data.id;

            const formData = {
                image: data.image,
            };

            return await profileService.changeProfilePic(token, id, formData);
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

//DELETE DOCUMENT
export const deleteDocument = createAsyncThunk(
    "document/delete",
    async (data, thunkAPI) => {
        try {
            const token = thunkAPI.getState().user.user.token;

            const id = data.id;

            const docId = {
                documentId: data.docId,
            };

            return await profileService.deleteDocument(token, id, docId);
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

//EDIT STAFF
export const editStaff = createAsyncThunk(
    "staff/update",
    async (staffData, thunkAPI) => {
        try {
            const token =
                thunkAPI.getState().user.user.token ||
                thunkAPI.getState().user.admin.token;
            const { id } = staffData;
            return await profileService.editStaff(staffData, token, id);
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

//EDIT ADMIN
export const editAdmin = createAsyncThunk(
    "admin/update",
    async (adminData, thunkAPI) => {
        try {
            const token =
                thunkAPI.getState().user.user.token ||
                thunkAPI.getState().user.admin.token;
            const { id } = adminData;
            return await profileService.editAdmin(adminData, token, id);
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

//ADD REFERENCE
export const addReference = createAsyncThunk(
    "reference/add",
    async (postData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().user.user.token;

            const { id } = postData;

            return await profileService.addReference(token, id, postData);
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

//DELETE REFERENCE
export const deleteReference = createAsyncThunk(
    "reference/delete",
    async (postData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().user.user.token;

            return await profileService.deleteReference(token, postData);
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = "";
        },
    },
    extraReducers: (builder) => {
        builder
            //GET A profile
            .addCase(getProfile.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getProfile.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.profile = action.payload;
            })
            .addCase(getProfile.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            // ADD TRAINING
            .addCase(addTraining.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addTraining.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;

                state.profile.user.staff.trainings.push(action.payload);
            })
            .addCase(addTraining.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            // VERIFY TRAINING
            .addCase(verifyTraining.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(verifyTraining.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.profile.user.staff.trainings =
                    state.profile.user.staff.trainings.filter(
                        (training) => training._id !== action.payload.training._id
                    );
                state.profile.user.staff.trainings.push(action.payload.training);
            })
            .addCase(verifyTraining.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            //REVOKE TRAINING
            .addCase(revokeTraining.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(revokeTraining.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.profile.user.staff.trainings =
                    state.profile.user.staff.trainings.filter(
                        (training) => training._id !== action.payload.training._id
                    );
                state.profile.user.staff.trainings.push(action.payload.training);
            })
            .addCase(revokeTraining.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            // DELETE TRAINING
            .addCase(deleteTraining.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteTraining.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;

                state.profile.user.staff.trainings =
                    state.profile.user.staff.trainings.filter(
                        (training) => training._id !== action.payload.id
                    );
            })
            .addCase(deleteTraining.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            }) //ADD DOCUMENT
            .addCase(addDocument.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addDocument.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.profile.user.staff.documents = action.payload;
            })
            .addCase(addDocument.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            }) //DELETE DOCUMENT
            .addCase(deleteDocument.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteDocument.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;

                state.profile.user.staff.documents =
                    action.payload.user.staff.documents;
            })
            .addCase(deleteDocument.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            //edit STAFF
            .addCase(editStaff.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(editStaff.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.profile = action.payload;
            })
            .addCase(editStaff.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            //edit Admin
            .addCase(editAdmin.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(editAdmin.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.profile = action.payload;
            })
            .addCase(editAdmin.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            //ADD REFERENCE
            .addCase(addReference.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addReference.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.profile.user.staff.references.push(action.payload);
            })
            .addCase(addReference.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            //REMOVE REFERENCE
            .addCase(deleteReference.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteReference.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.profile.user.staff.references =
                    state.profile.user.staff.references.filter(
                        (reference) => reference._id !== action.payload.id
                    );
            })
            .addCase(deleteReference.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            }).addCase(changePic.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(changePic.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.profile.image = action.payload;
            })
            .addCase(changePic.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            });
    },
});

export const { reset } = profileSlice.actions;
export default profileSlice.reducer;
