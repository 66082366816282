import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Spinner from "../UI/Spinner";
import { toast } from "react-toastify";
import { getProfile, reset } from "../../features/Profile/profileSlice";
import BasicInfo from "../profile/BasicInfo";
import Audit from "../profile/Audit";
import Trainings from "../profile/Trainings";
import Documents from "../profile/Documents";
import Reference from "../profile/Reference";
import LogsPage from "../profile/LogsPage";
import EditProfile from "../profile/EditProfile";
import generateCygnetProfile from "../../generate-profiles/cygnetProfile";
import generateElysiumProfile from "../../generate-profiles/elysiumProfile";
import { changePic } from "../../features/Profile/profileSlice";
import Modal from "../UI/Modal";
import complianceService from "../../features/Compliance/complianceService";
import generateProfile from "../../generate-profiles/new-profile";

function AdminProfile({ id, setIsOpen }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { profile, isError, isSuccess, message } = useSelector(
    (state) => state.profile
  );

  const { organisation } = useSelector((state) => state.organisation);

  // const logo = organisation ? organisation.organisation.image.path : null;
  const logo =
    "https://res.cloudinary.com/dhefsiizy/image/upload/v1681196713/logos/elysium-logo_ackedz.png";

  const { user } = useSelector((state) => state.user);

  const [open, setOpen] = useState(false);
  const [image, setImage] = useState("");

  // PAGE NAVIGATION
  const [page, setPage] = useState("basic");
  const [compliance, setCompliance] = useState(undefined);

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }

    if (!user) {
      navigate("/");
    }

    dispatch(getProfile(id));

    async function updateComplianceStatus() {
      const response = await complianceService.checkComplianceStatus(id);
      setCompliance(response.data);
    }

    updateComplianceStatus();

    return () => {
      reset();
    };
  }, [isSuccess, open]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { id, image };
    dispatch(changePic(data));
    toast.success(
      `Picture successfully changed. Please close modal or refresh page.`
    );
  };

  const assignToTeam = async (e) => {
    //get value from select
    const teamId = e.target.value;

    const team = await complianceService.assignUserToGroup(id, teamId);
  };

  const onImageChange = (e) => {
    const uploadedImage = e.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(uploadedImage);
    reader.onload = function () {
      setImage(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const body = () => {
    return (
      <div className="space-y-4 ">
        <div className="w-full">
          <label for="image" class="form-label">
            New Image
          </label>
          <br />
          <input
            values={image}
            type="file"
            name="image"
            id="image"
            onChange={onImageChange}
          />
        </div>
      </div>
    );
  };

  const goToBasic = () => {
    setPage("basic");
  };

  const goToAudit = () => {
    setPage("audit");
  };

  const goToTrainings = () => {
    setPage("training");
  };
  const goToReferences = () => {
    setPage("reference");
  };

  if (!profile || !compliance) {
    return <Spinner />;
  }

  const staff = profile.user;

  if (!user) {
    return <div>Successfully logged out</div>;
  }
  return (
    <React.Fragment>
      <div className="h-screen flex flex-col">
        <div className="  sm:flex space-y-3 sm:space-y-0 sm:space-x-3 p-4 bg-[#D3D3D3] flex-grow">
          <div className=" drop-shadow-md bg-white rounded p-3 sm:w-1/5   sm:sticky top-18 space-y-2  mb-5 ">
            <div>
              <button
                onClick={() => {
                  setIsOpen(false);
                }}
                className=" space-y-3 flex items-center text-xl py-2 px-4 w-full font-medium text-white bg-main rounded-md border border-main hover:bg-main2  focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
              >
                <span className="text-4xl">&#10094;</span> Back to staff
              </button>
            </div>
            <div className="flex-col justify-center items-center">
              <button
                className="py-2 px-4 text-sm font-medium text-white bg-main border w-full rounded shadow-sm hover:bg-main2  focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 "
                onClick={() => {
                  setOpen(true);
                }}
              >
                Edit Photo
              </button>

              {staff.image && (
                <img
                  crossOrigin="anonymous"
                  src={staff.image.path}
                  alt=""
                  srcSet=""
                />
              )}
            </div>

            <h1 className="text-2xl">
              {staff.name} {staff.surname}, {staff.staff.role}
            </h1>
            <h1 className="text-2xl">
              {compliance.compliant ? (
                <span className="text-green">Compliant</span>
              ) : (
                <span className="text-red">
                  {compliance.percentage}% compliant
                </span>
              )}
            </h1>
            <h1 className="text-l">Right to work: {staff.staff.righttowork}</h1>
            <h1 className="text-l">Sex: {staff.staff.gender}</h1>
            <h1 className="text-l">NI #: {staff.staff.ninumber}</h1>
            {staff.staff.role != "HCA" && (
              <h1 className="text-l">
                NMC #:{staff.staff.nmcnumber},{" "}
                {new Date(staff.staff.nmcexpiry).toLocaleDateString()}
              </h1>
            )}
            <button
              onClick={() => {
                window.open(
                  "https://www.cognitoforms.com/ComplianceAide1/MandatoryETrainingBookingForm",
                  "_blank"
                );
              }}
              className="py-2 px-4 text-sm font-medium text-white bg-main border w-full rounded shadow-sm hover:bg-main2  focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 "
            >
              Book Manadatory Training
            </button>
            <button
              onClick={() => {
                setPage("edit");
              }}
              className="py-2 px-4 text-sm font-medium text-white bg-main border w-full rounded shadow-sm hover:bg-main2  focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 "
            >
              Edit Profile
            </button>
            <button
              onClick={() => {
                generateCygnetProfile(staff);
              }}
              className="py-2 px-4 text-sm font-medium text-white bg-main border w-full rounded shadow-sm hover:bg-main2  focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 "
            >
              Cygnet Profile
            </button>
            <button
              onClick={() => {
                generateElysiumProfile(staff, logo);
              }}
              className="py-2 px-4 text-sm font-medium text-white bg-main border w-full rounded shadow-sm hover:bg-main2  focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 "
            >
              Elysium Profile
            </button>
            <button
              onClick={() => {
                generateProfile(staff, logo);
              }}
              className="py-2 px-4 text-sm font-medium text-white bg-main border w-full rounded shadow-sm hover:bg-main2  focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 "
            >
              Autism Initiatives
            </button>
            <div>
              <p className="mb-1">Currently in team : {staff.team}</p>
              <select
                id="team"
                class="form-select"
                name="team"
                onChange={assignToTeam}
                className="w-full"
                required
              >
                <option value="">Select a team</option>
                <option value="A">Team A</option>
                <option value="B">Team B</option>
                <option value="C">Team C</option>
              </select>
            </div>
          </div>
          <div className=" drop-shadow-md bg-white rounded p-3 sm:w-4/5 space-y-2 top-18 sticky overflow-auto mb-5">
            <div className="flex flex-wrap rounded-md  " role="group">
              <button
                onClick={goToBasic}
                type="button"
                className="py-2 px-4 text-sm font-medium text-gray-900 bg-white rounded border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 "
              >
                Profile
              </button>
              {user.user.role != "staff" && (
                <button
                  onClick={goToAudit}
                  type="button"
                  className="py-2 px-4 text-sm font-medium text-gray-900 bg-white border rounded border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 "
                >
                  Audit
                </button>
              )}
              <button
                onClick={goToTrainings}
                type="button"
                className="py-2 px-4 text-sm font-medium text-gray-900 bg-white border rounded border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 "
              >
                Trainings
              </button>
              <button
                onClick={goToReferences}
                type="button"
                className="py-2 px-4 text-sm font-medium text-gray-900 bg-white border rounded border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 "
              >
                References
              </button>
              <button
                onClick={() => {
                  setPage("documents");
                }}
                type="button"
                className={
                  user.user.role === "staff"
                    ? "py-2 px-4 text-sm font-medium text-gray-900 bg-white border rounded border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 "
                    : "py-2 px-4 text-sm font-medium text-gray-900 bg-white rounded-r-md border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 "
                }
              >
                Documents
              </button>
              {user.user.role !== "staff" && (
                <button
                  onClick={() => {
                    setPage("logs");
                  }}
                  type="button"
                  className="py-2 px-4 text-sm font-medium text-gray-900 bg-white rounded-r-md border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 "
                >
                  Logs
                </button>
              )}
            </div>
            <div className="p-3 drop-shadow-md rounded   ">
              <Modal
                open={open}
                closeModal={() => {
                  setOpen(false);
                }}
                title={"Adding New Training"}
                body={body()}
                submitForm={handleSubmit}
              />
              {page === "basic" && <BasicInfo staff={staff} />}
              {page === "audit" && <Audit profile={compliance} />}
              {page === "training" && <Trainings />}
              {page === "documents" && <Documents />}
              {page === "reference" && <Reference staff={staff} />}
              {page === "logs" && <LogsPage staff={staff} />}
              {page === "edit" && (
                <EditProfile
                  close={() => {
                    setPage("basic");
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AdminProfile;
