import instance from "../../components/AxiosInterceptor";

const API_URL = "/api/reference/";

// Get Reference
const getReference = async (id) => {
  const response = await instance.get(API_URL + `${id}`);

  return response.data;
};

// UPDATE REFERENCE
//updatestaff
const editReference = async (refData) => {
  const response = await instance.put(API_URL + `${refData.id}`, refData);

  return response.data;
};

const referenceService = {
  getReference,
  editReference,
};

export default referenceService;
