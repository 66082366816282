import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import groupService from './groupsService';

const group = JSON.parse(localStorage.getItem('group'));

const initialState = {
    group: group ? group : null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
};

//* CREATE GROUP
export const createGroup = createAsyncThunk('group/create', async (data, thunkAPI) => {
    try {
        const token = thunkAPI.getState().user.user.token;
        return await groupService.createGroup(token, data);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

//* GET group
export const getGroup = createAsyncThunk('group/fetch', async (id, thunkAPI) => {
    try {
        const token = thunkAPI.getState().user.user.token;
        return await groupService.getGroup(token, id);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

//* DELETE GROUP
export const deleteGroup = createAsyncThunk('group/delete', async (id, thunkAPI) => {
    try {
        const token = thunkAPI.getState().user.user.token;
        return await groupService.deleteGroup(token, id);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const addToGroup = createAsyncThunk('group/add', async (data, thunkAPI) => {
    try {
        const token = thunkAPI.getState().user.user.token;
        const id = data.id;
        const members = data.formData;

        return await groupService.editGroup(token, id, members);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

//* REMOVE USER FROM GROUP
export const removeFromGroup = createAsyncThunk('group/removeUser', async (data, thunkAPI) => {
    try {
        const token = thunkAPI.getState().user.user.token;

        return await groupService.removeFromGroup(token, data);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

// EDIT group

export const groupSlice = createSlice({
    name: 'group',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = '';
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getGroup.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getGroup.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.group = action.payload;
                state.message = '';
            })
            .addCase(getGroup.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.group = null;
            })
            // ADD USERS TO GROUP
            .addCase(addToGroup.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addToGroup.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.group = action.payload;
                state.message = '';
            })
            .addCase(addToGroup.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.group = null;
            })
            // DELETE USER FROM GROUP
            .addCase(removeFromGroup.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(removeFromGroup.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.group = action.payload;
                state.message = '';
            })
            .addCase(removeFromGroup.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.group = null;
            })
            // Create group
            .addCase(createGroup.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createGroup.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.group = action.payload;
                state.message = '';
            })
            .addCase(createGroup.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.group = null;
            })
            // Delete group
            .addCase(deleteGroup.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteGroup.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.group = action.payload;
                state.message = '';
            })
            .addCase(deleteGroup.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.group = null;
            });
    }
});

export const {reset} = groupSlice.actions;
export default groupSlice.reducer;
