import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import userService from './userService';

// Get user from localStorage
let user;
const stored = JSON.parse(localStorage.getItem('user'));

if (stored?.message){
  user = null
}else{
    user = stored
}

const initialState = {
    user: user ? user : null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
};

//LOG IN USER
//Login USER
export const login = createAsyncThunk('user/login', async (user, thunkAPI) => {
    try {
        return await userService.login(user);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

//Log out user
export const logout = createAsyncThunk('user/logout', async () => {
    await userService.logout();
});

//REGISTER USER
export const register = createAsyncThunk('user/register', async (user, thunkAPI) => {
    try {
        return await userService.register(user);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = '';
        }
    },
    extraReducers: (builder) => {
        builder
            // LOG IN USER
            .addCase(login.pending, (state) => {
                state.isLoading = true;
                state.isSuccess = false;
                state.isError = false;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.user = action.payload;
                state.message = '';
            })
            .addCase(login.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.user = null;
            })
            //LOG OUT USER
            .addCase(logout.fulfilled, (state) => {
                state.user = null;
            })
            // REGISTER USER
            .addCase(register.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(register.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.user = action.payload;
            })
            .addCase(register.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.user = null;
            });
    }
});

export const {reset} = userSlice.actions;
export default userSlice.reducer;
