import instance from "../../components/AxiosInterceptor";

const API_URL = "/api/";

//get a profile
const getProfile = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await instance.get(API_URL + `user/${id}`, config);

  if (response.data) {
    localStorage.setItem("profile", JSON.stringify(response.data));
  }

  return response.data;
};

const getAllUsers = async (id) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await instance.get(API_URL + `user/${id}/allusers`, config);

  return response.data.users;
};

const getAdmins = async (id) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await instance.get(API_URL + `user/${id}/admins`, config);

  return response.data.admins;
};

// GET ADD TRAINING
const addTraining = async (token, id, formData) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await instance.post(
    API_URL + `training/${id}`,
    formData,
    config
  );

  return response.data;
};

//verify training
const verifyTraining = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await instance.put(
    API_URL + `training/${id}/verify`,
    config
  );

  return response.data;
};

//verify training
const revokeTraining = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await instance.put(
    API_URL + `training/${id}/revoke`,
    config
  );

  return response.data;
};

//delete training
const deleteTraining = async (token, userData) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await instance.delete(
    API_URL + `training/${userData.id}/${userData.trainingId}`,
    config
  );

  return response.data;
};

//add document
const addDocument = async (token, id, formData) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await instance.post(
    API_URL + `document/${id}`,
    formData,
    config
  );

  return response.data;
};

//add document
const changeProfilePic = async (token, id, formData) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await instance.post(
    API_URL + `document/${id}/edit-pic`,
    formData,
    config
  );

  return response.data;
};

//delete document
const deleteDocument = async (token, id, docId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await instance.post(
    API_URL + `document/${id}/delete`,
    docId,
    config
  );

  return response.data;
};

//updatestaff
const editStaff = async (staffData, token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await instance.put(
    API_URL + `user/${id}`,
    staffData,
    config
  );

  return response.data;
};

//updateadmin
const editAdmin = async (staffData, token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await instance.put(
    API_URL + `user/${id}/admin`,
    staffData,
    config
  );

  return response.data;
};

// DELETE REFERENCE

const deleteReference = async (token, postData) => {
  const response = await instance.delete(API_URL + `reference/${postData.id}`, {
    data: { refId: postData.refId },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  console.log(response.data);
  return response.data;
};

//add reference
const addReference = async (token, id, formData) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await instance.post(
    API_URL + `reference/${id}`,
    formData,
    config
  );

  return response.data;
};

//add reference
const archiveStaff = async (id) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await instance.put(
    API_URL + `user/archive/${id}`,
    {},
    config
  );

  return response.data;
};

const profileService = {
  getProfile,
  getAllUsers,
  addTraining,
  verifyTraining,
  revokeTraining,
  deleteTraining,
  addDocument,
  deleteDocument,
  editStaff,
  addReference,
  deleteReference,
  editAdmin,
  changeProfilePic,
  getAdmins,
  archiveStaff,
};

export default profileService;
