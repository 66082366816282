import React, { useState, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material';
import MaterialTable from 'material-table';
import SingleAdmin from '../Admins/SingleAdmin';
import profileService from '../../features/Profile/profileService';
import Spinner from '../UI/Spinner';

function Admins({ user, token }) {
	const [table, setTable] = useState(true);
	const [id, setId] = useState('');
	const [admins, setAdmins] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const defaultMaterialTheme = createTheme();

	useEffect(() => {
		setIsLoading(true);
		fetchAdmins();
		setIsLoading(false);
	}, []);

	const fetchAdmins = async () => {
		setAdmins(await profileService.getAdmins(user.organisation));
	};

	if (!admins) {
		return <Spinner></Spinner>;
	}

	return (
		<>
			{' '}
			{table ? (
				<div>
					<h1 className="mt-3 text-3xl font-bold text-center">All admins</h1>
					<p className="text-center">list updated: {Date()}</p>
					<div style={{ maxWidth: '100%' }}>
						<link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
						<ThemeProvider theme={defaultMaterialTheme}>
							<MaterialTable
								columns={[
									{ title: 'Name', field: 'name' },
									{ title: 'Surname', field: 'surname' },
									{ title: 'Email', field: 'email' },
								]}
								data={admins}
								isLoading={isLoading}
								options={{
									actionsColumnIndex: -1,
									rowStyle: {
										padding: '0 1rem',
										textAlign: 'center',
									},
									headerStyle: {
										padding: '0 1rem',
										fontWeight: 'bold',
										fontSize: '1.3rem',
									},
									pageSizeOptions: [10, 20, 50],
									pageSize: 10,
								}}
								actions={[
									(rowData) => ({
										icon: 'chevron_right',
										tooltip: 'View profile',
										onClick: (event, rowData) => {
											setTable(false);
											setId(rowData._id);
										},
									}),
								]}
								title="All Admins"
							/>
						</ThemeProvider>
					</div>
				</div>
			) : (
				<>
					<div className="p-4 text-3xl font-black flex items-center  ">
						<span
							className="cursor-pointer hover:scale-105"
							onClick={() => {
								setTable(true);
							}}>
							Back to admins
						</span>
					</div>
					<div>
						<SingleAdmin id={id} />
					</div>
				</>
			)}
		</>
	);
}

export default Admins;
