import instance from "../../components/AxiosInterceptor";

const API_URL = "/api/";

// login user
const login = async (Data) => {
  const response = await instance.post(API_URL + "authenticate/login", Data);

  if (response.data) {
    localStorage.setItem("user", JSON.stringify(response.data));
  }

  return response.data;
};

//Logout user
const logout = () => {
  localStorage.clear();
};

// Register user
const register = async (userData) => {
  const response = await instance.post(API_URL + `user/${userData.organisation}`, userData);

  if (response.data && response.data.token) {
    localStorage.setItem("user", JSON.stringify(response.data));
  }

  return response.data;
};

const generateToken = async (id) => {
  const response = await instance.get(API_URL + `authenticate/generate-token/${id}`);

  return response.data;
};

const verifyToken = async (id, data) => {
  const response = await instance.post(API_URL + `authenticate/verify-token/${id}`, data);

  return response.data;
};

const userService = {
  logout,
  login,
  register,
  generateToken,
  verifyToken,
};

export default userService;
