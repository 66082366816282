import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import OtpInput from "react18-input-otp";
import userService from "../features/Auth/userService";
import QRCode from "qrcode";
import copy from "../images/copy.png";
import { toast } from "react-toastify";
import Spinner from "../components/UI/Spinner";
import NavBar from "../components/NavBar/NavBar";

export default function Generate2FA() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [otpauth_url, setOtpauth_url] = useState("");
  const [qrcodeUrl, setqrCodeUrl] = useState("");
  const [base32, setBase32] = useState("");
  const [secret, setSecret] = useState("");

  useEffect(() => {
    if (!user) {
      navigate(`/`);
    }

    if (user) {
      if (user.message) {
        navigate(`/`);
      }
    }
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const data = await userService.generateToken(id);
      if (data.message === "Token generated") {
        setBase32(data.data.base32);
        setOtpauth_url(data.data.otpauth_url);
      } else if (data.message === "User already has a token") {
        navigate(`/verify2fa/${id}`);
      }
    } catch (error) {}
  };

  if (!otpauth_url) {
    return <Spinner></Spinner>;
  }

  QRCode.toDataURL(otpauth_url).then(setqrCodeUrl);

  const handleOTP = (otp) => {
    setSecret(otp);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { token: secret, remember: false };
    try {
      const response = await userService.verifyToken(id, data);

      if (response.data.verified) {
        if (user.user.role === "staff") {
          navigate(`/profile/${user.user._id}`);
        }
        if (user.user.role !== "staff") {
          navigate(`/dashboard`);
        }
      }
    } catch (error) {
      toast.error("Code is invalid, please try again.", {
        position: "top-center",
        autoClose: 5000,
      });
    }
  };

  const reset = async (e) => {
    await localStorage.clear();
    navigate("/");
    window.location.reload(false);
  };

  return (
    <React.Fragment>
      <NavBar></NavBar>
      <div className="h-screen d-flex align-center p-4">
        <div className="border border-main rounded-lg md:w-1/2 w-full m-auto shadow shadow-sm">
          <h3 className="text-center text-2xl fw-bold p-4 text-main font-bold">
            Two-Factor Authentication (2FA)
          </h3>
          <div>
            <h4 className="text-main text-xl font-bold mb-3 px-3">
              Configuring Google Authenticator or Authy
            </h4>
            <ol className="px-5 list-decimal">
              <li>
                Install Google Authenticator (IOS - Android) or Authy (IOS -
                Android).
              </li>
              <li>In the authenticator app, select "+" icon.</li>
              <li>
                Select "Scan a barcode (or QR code)" and use the phone's camera
                to scan this barcode.
              </li>
            </ol>
          </div>
          <div className="m-3">
            <h4 className="text-main text-xl font-bold mb-3">Scan QR code:</h4>
            <img
              className="w-64 h-64 object-contain mx-auto"
              src={qrcodeUrl}
              alt="qrcode url"
            />
          </div>
          <div className="mx-3">
            <h4 className="text-main text-xl font-bold mb-3">
              Or Enter Code into Your App
            </h4>
            <p className="text-sm">
              If you are on mobile, copy the highlighted code directly into your
              Google Authenticator (IOS - Android) or Authy (IOS - Android) app.
            </p>
            <p className="text-sm mt-3 d-flex gap-3">
              Code: <span className="text-main font-semibold">{base32}</span>
              <span
                className="hover:cursor-pointer"
                onClick={() => {
                  navigator.clipboard.writeText(base32);
                  toast.success("copied code");
                }}
              >
                <img srcSet={copy} alt="Copy" width="20px" height="auto" />
              </span>
            </p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="m-3">
              <h4 className="text-main text-xl font-bold mb-3">Verify Pin</h4>
              <div className="w-full">
                <p className="text-sm mb-3">
                  Enther the 6 digit verification pin from your Google
                  Authenticator (IOS - Android) or Authy (IOS - Android) app
                  below.
                </p>
                <OtpInput
                  value={secret}
                  onChange={handleOTP}
                  numInputs={6}
                  separator={<span>-</span>}
                  isInputNum="true"
                  containerStyle="justify-center"
                  inputStyle="h-[3rem] !w-[3rem] justify-center"
                />
              </div>
            </div>
            <div className="m-3 d-flex justify-center">
              <button
                type="submit"
                className="w-full font-semibold shadow rounded-lg  bg-main hover:bg-main2 py-2 px-3 text-[#FFF] disabled:bg-gray-300"
                disabled={secret.length < 6}
              >
                Verify
              </button>
            </div>
          </form>
          <div className="m-3 d-flex justify-center gap-3">
            <button
              type="button"
              onClick={reset}
              className="w-full font-semibold shadow rounded-lg  bg-white hover:bg-main2 py-2 px-3 text-main border border-main"
            >
              cancel
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
