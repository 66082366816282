import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Logout() {
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.clear();

    navigate("/");

    return () => {};
  }, []);

  return (
    <div className="h-screen w-screen flex items-center justify-center">
      Login you out
    </div>
  );
}

export default Logout;
