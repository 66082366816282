import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import MaterialTable from "material-table";

function AdminStaff({ users }) {
  const navigate = useNavigate();
  const defaultMaterialTheme = createTheme();
  return (
    <Fragment>
      <div style={{ maxWidth: "100%" }}>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
        <ThemeProvider theme={defaultMaterialTheme}>
          <MaterialTable
            columns={[
              { title: "Applied", field: "dateAdded", type: "date" },
              { title: "Name", field: "name" },
              { title: "Surname", field: "surname" },
              { title: "Email", field: "email" },
              {
                title: "Role",
                field: "staff.role",
              },
              {
                title: "Team",
                field: "team",
              },
              {
                title: "Compliant",
                field: "staff.isCompliant",
              },
              {
                title: "City",
                field: "staff.city",
              },
            ]}
            data={users.filter((user) => !user.archived)}
            options={{
              actionsColumnIndex: 1,
              exportButton: true,
              exportFileName: "staff-export",
              actionsColumnIndex: -1,
              rowStyle: {
                padding: "0 0rem",
                textAlign: "center",
                fontSize: "0.9rem",
              },
              headerStyle: {
                padding: "0 0.5rem",
                fontWeight: "bold",
                fontSize: "1.3rem",
              },
              pageSizeOptions: [20, 50, 100, 500],
              pageSize: 20,
            }}
            actions={[
              (rowData) => ({
                icon: "chevron_right",
                tooltip: "View profile",
                onClick: (event, rowData) => {},
              }),
              (rowData) => ({
                icon: "clear",
                tooltip: "Archive staff",
                onClick: (event, rowData) => {},
              }),
            ]}
          />
        </ThemeProvider>
      </div>
    </Fragment>
  );
}

export default AdminStaff;
