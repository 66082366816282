import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout, reset } from "../../features/Auth/userSlice";

import Logo from "../../images/CA-logo-rectangle.png";

function AppNav() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className=" bg-white w-full p-2 text-white sticky top-0 px-4 z-40 shadow shadow-sm">
      <div className="flex justify-between items-center ">
        <div>
          <img
            src={Logo}
            alt=""
            srcSet=""
            width="200px"
            crossOrigin="anonymous"
          />
        </div>
        <div>
          <button
            className="font-semibold shadow rounded-lg  bg-main hover:bg-main2 py-2 px-3 text-[#FFF]"
            onClick={() => {
              dispatch(logout());
              navigate("/");
              dispatch(reset());
            }}
          >
            Sign Out
          </button>
        </div>
      </div>
    </div>
  );
}

export default AppNav;
