import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../UI/Modal";
import {
  addDocument,
  deleteDocument,
} from "../../features/Profile/profileSlice";
import { FaPen, FaTrashAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import { compareAsc, sub } from "date-fns";
import { ThemeProvider, createTheme } from "@mui/material";
import MaterialTable from "material-table";

function Documents() {
  const defaultMaterialTheme = createTheme();
  const dispatch = useDispatch();

  // MODAL DATA
  const [isOpen, setIsOpen] = useState(false);

  const [formData, setFormData] = useState({
    file: "",
    doctype: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const documentData = {
      image: formData.file,
      doctype: formData.doctype,
      id: profile.user._id,
    };
    dispatch(addDocument(documentData));
    toast.success(`${documentData.doctype} successfully added`);
  };
  const handleChange = (input) => (e) => {
    setFormData({ ...formData, [input]: e.target.value });
  };
  const onDocChange = (e) => {
    const uploadedDoc = e.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(uploadedDoc);
    reader.onload = function () {
      setFormData({ ...formData, file: reader.result });
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const body = () => {
    return (
      <div className="space-y-4">
        <div className="w-full">
          <label for="doctype" class="form-label">
            Document Name
          </label>
          <br />
          <select
            class="form-select"
            name="doctype"
            onChange={handleChange("doctype")}
            className="w-full"
          >
            <option selected>Choose document name</option>
            <option value="48 hour OPT OUT">48 hour OPT OUT</option>
            <option value="Annual Appraisal">Annual Appraisal</option>
            <option value="Annual Health Assessment">
              Annual Health Assessment
            </option>
            <option value="Application Form">Application Form</option>
            <option value="AWR Process with Clock Pause and Stop">
              AWR Process with Clock Pause and Stop
            </option>
            <option value="BRP Front">BRP Front</option>
            <option value="BRP Back">BRP Back</option>
            <option value="Confidentiality Agreement">
              Confidentiality Agreement
            </option>
            <option value="Contract">Contract</option>
            <option value="Company ID">Company ID</option>
            <option value="CV">CV</option>
            <option value="Employee Handbook Acknowledgement">
              Employee Handbook Acknowledgement
            </option>
            <option value="Employee Manual Agreement">
              Employee Manual Agreement
            </option>
            <option value="Fitness to Work">Fitness to Work</option>
            <option value="IPC Booklet">IPC Booklet</option>
            <option value="Interview form">Interview form</option>
            <option value="KID">Key Info Doc (K.I.D)</option>
            <option value="Mental Health Act & Mental Capacity Act ">
              Mental Health Act & Mental Capacity Act{" "}
            </option>
            <option value="Numeracy and Literacy Quiz">
              Numeracy and Literacy Quiz
            </option>
            <option value="Old Interview Form">Old Interview Form</option>
            <option value="Proof of Address">Proof of Address</option>
            <option value="Proof of DBS">Proof of DBS</option>
            <option value="Proof of ID">Proof of ID</option>
            <option value="Proof of Immediate Life Support">
              Proof of Immediate Life Support
            </option>
            <option value="Proof of NI">Proof of NI</option>
            <option value="Proof of NMC">Proof of NMC</option>
            <option value="Proof of MAPA">Proof of MAPA</option>
            <option value="Proof of SI (compliant with Mental Health Units Use of Force 2018 Act)">
              Proof of SI (compliant with Mental Health Units Use of Force 2018
              Act)
            </option>
            <option value="Proof of PMVA">Proof of PMVA</option>
            <option value="Proof of training">Proof of Training</option>
            <option value="Proof of Time Sheet">Proof of Time Sheet</option>
            <option value="Proof of visa">Proof of Visa</option>
            <option value="R.O.C">R.O.C</option>
            <option value="Right to Work">Right to Work</option>
            <option value="Support Worker Contract">
              Support Worker Contract
            </option>
            <option value="Temporary Worker Contract">
              Temporary Worker Contract
            </option>
            <option value="Vaccination Checks">Vaccination Checks</option>
          </select>
        </div>
        <div className="w-full">
          <div className="">
            <input
              className="w-full"
              type="file"
              name="doc"
              id="doc"
              onChange={onDocChange}
            />
          </div>
        </div>
      </div>
    );
  };

  const { profile, isSuccess, isLoading } = useSelector(
    (state) => state.profile
  );

  const filteredDocs = profile.user.staff.documents.filter((document) =>
    compareAsc(new Date(document.uploaded), sub(new Date(), { years: 1 }))
  );

  const data = filteredDocs.map((item) => ({ ...item }));

  return (
    <React.Fragment>
      <div className="space-y-4">
        {" "}
        <div className="space-y-4">
          <button
            onClick={() => setIsOpen(true)}
            className="font-semibold shadow   rounded-lg  bg-main hover:bg-main2 py-2 px-5 text-white"
          >
            Add new document
          </button>
        </div>
        <div style={{ maxWidth: "100%" }}>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
          />
          <ThemeProvider theme={defaultMaterialTheme}>
            <MaterialTable
              columns={[
                { title: "Name", field: "doctype" },
                { title: "Date Added", field: "uploaded", type: "date" },
              ]}
              data={data}
              options={{
                actionsColumnIndex: -1,
                rowStyle: {
                  padding: "0 1rem",
                  textAlign: "center",
                },
                headerStyle: {
                  padding: "0 1rem",
                  fontWeight: "bold",
                  fontSize: "1.3rem",
                },
                pageSizeOptions: [10, 20, 50],
                pageSize: 10,
              }}
              actions={[
                (rowData) => ({
                  icon: "save_alt",
                  tooltip: "View",
                  onClick: (event, rowData) => {
                    window.open(rowData.path);
                  },
                }),
                (rowData) => ({
                  icon: "delete",
                  tooltip: "Delete",
                  onClick: (event, rowData) => {
                    const data = {
                      docId: rowData._id,
                      id: profile.user._id,
                    };
                    dispatch(deleteDocument(data));
                    toast.success("Document successfully deleted");
                  },
                }),
              ]}
              title="All Documents"
            />
          </ThemeProvider>
        </div>
        <Modal
          open={isOpen}
          closeModal={() => {
            setIsOpen(false);
          }}
          title={"Adding New Document"}
          body={body()}
          submitForm={handleSubmit}
        />
      </div>
    </React.Fragment>
  );
}

export default Documents;
