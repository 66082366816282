import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AppNav from "../components/AppNav/AppNav";
import Audit from "../components/profile/Audit";
import BasicInfo from "../components/profile/BasicInfo";
import Documents from "../components/profile/Documents";
import EditProfile from "../components/profile/EditProfile";
import LogsPage from "../components/profile/LogsPage";
import Reference from "../components/profile/Reference";
import Trainings from "../components/profile/Trainings";
import Spinner from "../components/UI/Spinner";
import { getProfile, reset } from "../features/Profile/profileSlice";
import complianceService from "../features/Compliance/complianceService";

function Profile() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // lOAD USER FROM

  const { profile, isError, isSuccess, isLoading } = useSelector(
    (state) => state.profile
  );
  const { user } = useSelector((state) => state.user);

  const [page, setPage] = useState("basic");
  const [compliance, setCompliance] = useState();
  const [percentage, setPercentage] = useState();

  useEffect(() => {
    if (!user) {
      navigate("/");
    }

    dispatch(getProfile(id));

    async function updateComplianceStatus() {
      const response = await complianceService.checkComplianceStatus(id);
      setCompliance(response.data);
    }

    updateComplianceStatus();

    return () => {
      reset();
    };
  }, [isError, isSuccess, user, dispatch, navigate]);

  // PAGE NAVIGATION

  const goToBasic = () => {
    setPage("basic");
  };

  const goToAudit = () => {
    setPage("audit");
  };

  const goToTrainings = () => {
    setPage("training");
  };
  const goToReferences = () => {
    setPage("reference");
  };

  if (!profile || !compliance) {
    return <Spinner />;
  }

  const staff = profile.user;

  if (!user) {
    return <div>Successfully logged out</div>;
  }

  return (
    <React.Fragment>
      <div className="h-screen flex flex-col">
        <AppNav />
        <div className="  sm:flex space-y-3 sm:space-y-0 sm:space-x-3 p-4 bg-[#D3D3D3] flex-grow">
          <div className=" drop-shadow-md bg-white rounded p-3 sm:w-1/5   sm:sticky top-18 space-y-2  mb-5 h-fit">
            <div className="flex-col justify-center items-center">
              <button className="py-2 px-4 text-sm font-medium text-white bg-main border w-full rounded shadow-sm hover:bg-main2  focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 ">
                Change
              </button>
              {staff.image && (
                <img
                  crossOrigin="anonymous"
                  src={staff.image.path}
                  alt="Profile image"
                />
              )}
            </div>

            <h1 className="text-2xl">
              {staff.name} {staff.surname}, {staff.staff.role}
            </h1>
            <h1 className="text-2xl">
              {compliance.compliant ? (
                <span className="text-green">Compliant</span>
              ) : (
                <span className="text-red">
                  {compliance.percentage}% compliant
                </span>
              )}
            </h1>
            <h1 className="text-l">Right to work: {staff.staff.righttowork}</h1>
            <h1 className="text-l">Sex: {staff.staff.gender}</h1>
            <h1 className="text-l">Region: {staff.staff.region}</h1>
            <h1 className="text-l">NI #: {staff.staff.ninumber}</h1>
            {staff.staff.role != "HCA" && (
              <h1 className="text-l">
                NMC #:{staff.staff.nmcnumber},{" "}
                {new Date(staff.staff.nmcexpiry).toLocaleDateString()}
              </h1>
            )}
            <button
              onClick={() => {
                window.open(
                  "https://www.cognitoforms.com/ComplianceAide1/MandatoryETrainingBookingForm",
                  "_blank"
                );
              }}
              className="py-2 px-4 text-sm font-medium text-white bg-main border w-full rounded shadow-sm hover:bg-main2  focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 "
            >
              Book Manadatory Training
            </button>
            <button
              onClick={() => {
                setPage("edit");
              }}
              className="py-2 px-4 text-sm font-medium text-white bg-main border w-full rounded shadow-sm hover:bg-main2  focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 "
            >
              Edit Profile
            </button>
            {user.user.role != "staff" && (
              <button
                onClick={() => {
                  window.open("/cygnet-profile", "_blank");
                }}
                className="py-2 px-4 text-sm font-medium text-white bg-main border w-full rounded shadow-sm hover:bg-main2  focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 "
              >
                Cygnet Profile
              </button>
            )}
          </div>
          <div className=" drop-shadow-md bg-white rounded p-3 sm:w-4/5 space-y-2 top-18 sticky overflow-auto mb-5">
            <div className="flex flex-wrap rounded-md  " role="group">
              <button
                onClick={goToBasic}
                type="button"
                className="py-2 px-4 text-sm font-medium text-gray-900 bg-white rounded border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 "
              >
                Profile
              </button>
              {user.user.role != "staff" && (
                <button
                  onClick={goToAudit}
                  type="button"
                  className="py-2 px-4 text-sm font-medium text-gray-900 bg-white border rounded border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 "
                >
                  Audit
                </button>
              )}
              <button
                onClick={goToTrainings}
                type="button"
                className="py-2 px-4 text-sm font-medium text-gray-900 bg-white border rounded border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 "
              >
                Trainings
              </button>
              <button
                onClick={goToReferences}
                type="button"
                className="py-2 px-4 text-sm font-medium text-gray-900 bg-white border rounded border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 "
              >
                References
              </button>
              <button
                onClick={() => {
                  setPage("documents");
                }}
                type="button"
                className={
                  user.user.role === "staff"
                    ? "py-2 px-4 text-sm font-medium text-gray-900 bg-white border rounded border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 "
                    : "py-2 px-4 text-sm font-medium text-gray-900 bg-white rounded-r-md border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 "
                }
              >
                Documents
              </button>
              {user.user.role != "staff" && (
                <button
                  onClick={() => {
                    setPage("logs");
                  }}
                  type="button"
                  className="py-2 px-4 text-sm font-medium text-gray-900 bg-white rounded-r-md border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 "
                >
                  Logs
                </button>
              )}
            </div>
            <div className="p-3 drop-shadow-md rounded   ">
              {page === "basic" && <BasicInfo staff={staff} />}
              {page === "audit" && <Audit profile={compliance} />}
              {page === "training" && <Trainings />}
              {page === "documents" && <Documents />}
              {page === "reference" && <Reference staff={staff} />}
              {page === "logs" && <LogsPage staff={staff} />}
              {page === "edit" && (
                <EditProfile
                  close={() => {
                    setPage("basic");
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Profile;
