import {configureStore} from '@reduxjs/toolkit';
import userReducer from '../features/Auth/userSlice';
import profileReducer from '../features/Profile/profileSlice';
import referenceReducer from '../features/Reference/referenceSlice';
import organisationReducer from '../features/Organisation/organisationSlice';
import groupReducer from '../features/Groups/groupsSlice';

export const store = configureStore({
    reducer: {
        user: userReducer,
        profile: profileReducer,
        reference: referenceReducer,
        organisation: organisationReducer,
        group: groupReducer
    }
});
