import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import organisationService from './organisationService';
import localforage from 'localforage';

let organisation;

async function getOrganisation2() {
    organisation = JSON.parse(localStorage.getItem('organisation'));
}

getOrganisation2();

const initialState = {
    organisation: organisation ? organisation : null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
};

//LOG IN organisation
//Login organisation
export const getOrganisation = createAsyncThunk('organisation/fetch', async (organisation, thunkAPI) => {
    try {
        const token = thunkAPI.getState().user.user.token;

        return await organisationService.getOrganisation(token, organisation);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

//REgistration org details
export const getOrganisationReg = createAsyncThunk('organisation_reg/fetch', async (organisation, thunkAPI) => {
    try {
        return await organisationService.getOrganisationReg(organisation);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const organisationSlice = createSlice({
    name: 'organisation',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = '';
        }
    },
    extraReducers: (builder) => {
        builder
            // LOG IN organisation
            .addCase(getOrganisation.pending, (state) => {
                state.isLoading = true;
                state.isSuccess = false;
                state.isError = false;
            })
            .addCase(getOrganisation.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.organisation = action.payload;
                state.message = '';
            })
            .addCase(getOrganisation.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.organisation = null;
            })
            // Registration Organisation
            .addCase(getOrganisationReg.pending, (state) => {
                state.isLoading = true;
                state.isSuccess = false;
                state.isError = false;
            })
            .addCase(getOrganisationReg.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.organisation = action.payload;
                state.message = '';
            })
            .addCase(getOrganisationReg.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.organisation = null;
            });
    }
});

export const { reset } = organisationSlice.actions;
export default organisationSlice.reducer;
