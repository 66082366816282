import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addToGroup,
  getGroup,
  removeFromGroup,
  reset,
} from "../../features/Groups/groupsSlice";
import Spinner from "../UI/Spinner";
import { FaTimes } from "react-icons/fa";
import AdminProfile from "../Dashboard/AdminProfile";
import { ThemeProvider, createTheme } from "@mui/material";
import MaterialTable from "material-table";

function SingleGroup({ id, staff }) {
  const defaultMaterialTheme = createTheme();
  const { group, isSuccess, isLoading, isError, message } = useSelector(
    (state) => state.group
  );

  const dispatch = useDispatch();
  const [formData, setFormData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [users, setUsers] = useState(staff);

  const [isOpen, setIsOpen] = useState(false);
  const [uid, setUid] = useState("");

  useEffect(() => {
    if (isError) {
      console.log(message);
    }

    dispatch(getGroup(id));

    return () => {
      dispatch(reset);
    };
  }, [isSuccess]);

  const handleChange = (e) => {
    let updatedList = [...formData];
    if (!updatedList.includes(e.target.value)) {
      updatedList = [...formData, e.target.value];
    } else {
      updatedList.splice(formData.indexOf(e.target.value), 1);
    }
    setFormData(updatedList);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { formData, id };
    dispatch(addToGroup(data));
  };

  const handleSubmitDelete = (e) => {
    e.preventDefault();

    const data = { members: formData, groupId: group._id };
    dispatch(removeFromGroup(data));
  };

  const handleSearch = (e) => {
    const term = e.target.value;

    setUsers(
      staff.filter((user) => {
        return user.name.toLowerCase().indexOf(term.toLowerCase()) !== -1;
      })
    );
  };

  if (!group) {
    return <Spinner />;
  }

  const data = group.members.map((item) => ({ ...item }));

  return (
    <React.Fragment>
      {!isOpen ? (
        <div className="pb-4 px-4 space-y-3">
          <div className="flex space-x-4">
            <button
              onClick={() => {
                setOpen(true);
              }}
              className=" w-full font-semibold shadow   rounded-lg  bg-main hover:bg-main2 py-2 px-2 text-white flex justify-center"
            >
              Add Users
            </button>
            <button
              onClick={() => {
                setOpenDelete(true);
              }}
              className=" w-full font-semibold shadow   rounded-lg  bg-red hover:bg-main2 py-2 px-2 text-white flex justify-center"
            >
              Remove Users
            </button>
          </div>

          <div style={{ maxWidth: "100%" }}>
            <link
              rel="stylesheet"
              href="https://fonts.googleapis.com/icon?family=Material+Icons"
            />
            <ThemeProvider theme={defaultMaterialTheme}>
              <MaterialTable
                columns={[
                  { title: "Name", field: "name" },
                  { title: "Surname", field: "surname" },
                  { title: "Email", field: "email" },
                ]}
                data={data}
                options={{
                  actionsColumnIndex: -1,
                  rowStyle: {
                    padding: "0 1rem",
                    textAlign: "center",
                  },
                  headerStyle: {
                    padding: "0 1rem",
                    fontWeight: "bold",
                    fontSize: "1.3rem",
                  },
                  pageSizeOptions: [10, 20, 50],
                  pageSize: 10,
                }}
                actions={[
                  {
                    icon: "chevron_right",
                    tooltip: "View profile",
                    onClick: (event, rowData) => {
                      setUid(rowData._id);
                      setIsOpen(true);
                    },
                  },
                ]}
                title="Group Members"
              />
            </ThemeProvider>
          </div>

          {open && (
            <div className="fixed top-0 right-0 bottom-0  bg-white p-4 space-y-3 shadow-sm">
              <div className=" font-black text-3xl flex items-center justify-end ">
                <h1
                  className="cursor-pointer hover:scale-105 p-2 bg-main text-white rounded  "
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <FaTimes />
                </h1>
              </div>
              <h1 className="font-semibold text-3xl">{group.name} group</h1>
              <h2 className="font-semibold underline">
                Select candidates to add to group
              </h2>
              <input
                type="text"
                placeholder="Search first name..."
                onChange={handleSearch}
              />
              <form onSubmit={handleSubmit}>
                {/* TODO: MAP ALL USERS SORTED */}

                {users.map((user, i) => {
                  return (
                    <div className="space-x-3">
                      <input
                        key={i}
                        className=""
                        type="checkbox"
                        id={`name${user.name}`}
                        name={`name`}
                        onChange={handleChange}
                        value={user._id}
                      />
                      <label htmlFor="name" className="form-label">
                        {user.name} {user.surname}
                      </label>
                    </div>
                  );
                })}
                <button
                  type="submit"
                  className=" w-full font-semibold shadow   rounded-lg  bg-main hover:bg-main2 py-2 px-2 text-white flex justify-center"
                >
                  Add members
                </button>
              </form>
            </div>
          )}
          {openDelete && (
            <div className="fixed top-0 right-0 bottom-0  bg-white p-4 space-y-3 shadow-sm">
              <div className=" font-black text-3xl flex items-center justify-end ">
                <h1
                  className="cursor-pointer hover:scale-105 p-2 bg-main text-white rounded  "
                  onClick={() => {
                    setOpenDelete(false);
                  }}
                >
                  <FaTimes />
                </h1>
              </div>
              <h1 className="font-semibold text-3xl">{group.name} group</h1>
              <h2 className="font-semibold underline">
                Select candidates to remove
              </h2>
              <input
                type="text"
                placeholder="Search first name..."
                onChange={handleSearch}
              />
              <form onSubmit={handleSubmitDelete}>
                {/* TODO: MAP ALL USERS SORTED */}

                {group.members.map((user, i) => {
                  return (
                    <div className="space-x-3">
                      <input
                        key={i}
                        className=""
                        type="checkbox"
                        id={`name${user.name}`}
                        name={`name`}
                        onChange={handleChange}
                        value={user._id}
                      />
                      <label htmlFor="name" className="form-label">
                        {user.name} {user.surname}
                      </label>
                    </div>
                  );
                })}
                <button
                  type="submit"
                  className=" w-full font-semibold shadow   rounded-lg  bg-main hover:bg-main2 py-2 px-2 text-white flex justify-center"
                >
                  Remove
                </button>
              </form>
            </div>
          )}
        </div>
      ) : (
        <AdminProfile id={uid} setIsOpen={setIsOpen} />
      )}
    </React.Fragment>
  );
}

export default SingleGroup;
