import React, { useState } from "react";
import AdminProfile from "../Dashboard/AdminProfile";

function StaffMadeCompliant({ staff }) {
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState("");

  return (
    <>
      {!isOpen ? (
        <div>{staff.length <= 0 ? "None available" : <></>}</div>
      ) : (
        <AdminProfile id={id} setIsOpen={setIsOpen} />
      )}
    </>
  );
}

export default StaffMadeCompliant;
