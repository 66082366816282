import React, {useState} from "react";
import NavBar from "../components/NavBar/NavBar";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import Spinner from "../components/UI/Spinner";

function ResetPassword() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: "",
    });

    const handleChange = (input) => (e) => {
        setFormData({...formData, [input]: e.target.value});
    };

    const [accepted, setAccepted] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            setLoading(true);
            const response = await axios.post(
                "/api/authenticate/reset",
                formData
            );
            setLoading(false);
            if (response.status === 200 && response.data.message.includes("Further")) {
                setAccepted(true);
            }

            //
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    if (loading) {
        return <Spinner/>;
    }

    return (
        <React.Fragment>
            <div className="h-screen flex flex-col">
                <NavBar/>
                {accepted ? (
                    <p className="text-center p-5 flex items-center flex-grow">
            <span>
              Further Steps have been sent to the email address provided. Please also check your junk/spam mail for the password reset email
            </span>
                    </p>
                ) : (
                    <form
                        onSubmit={handleSubmit}
                        className=" w-screen flex flex-col items-center justify-center flex-grow-1 space-y-5 p-5"
                    >
                        <div className=" sm:w-1/2 space-y-3">
                            <h1 className="font-bold text-2xl ">Reset Your Password</h1>
                            <p>
                                Fear not. we will email instructions to you on how to reset your
                                password. If you do not have access to your email anymore please
                                contact your compliance manager
                            </p>
                        </div>
                        <div className="w-full sm:w-1/2 flex flex-col ">
                            <label
                                className="font-semibold text-xl text-center"
                                htmlFor="email"
                            >
                                Account email address
                            </label>

                            <input
                                value={formData.email}
                                className=" "
                                type="email"
                                name="email"
                                id="email"
                                onChange={handleChange("email")}
                            />
                        </div>
                        <div className="w-full sm:w-1/2 flex flex-col justify-center">
                            <button
                                type="submit"
                                className="font-semibold text-white bg-main hover:scale-105 hover:bg-main2 px-3 py-2 rounded shadow-sm"
                            >
                                Submit
                            </button>
                        </div>
                        <p
                            className="hover:cursor-pointer hover:scale-105"
                            onClick={() => {
                                navigate("/");
                            }}
                        >
                            Cancel password reset
                        </p>
                    </form>
                )}
            </div>
        </React.Fragment>
    );
}

export default ResetPassword;
