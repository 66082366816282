import React, { Fragment, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import adminService from "../../features/Admin/adminService";
import Spinner from "../UI/Spinner";
import AdminStaff from "./AdminStaff";

function OrganisationProfile() {
  let { id } = useParams();
  const [page, setPage] = useState("HOME");

  const fetchSingleOrg = async () => {
    return await adminService.getSingleOrganisation(id);
  };

  const { isLoading, isError, data, error } = useQuery(
    "single-organisation",
    fetchSingleOrg
  );

  if (isLoading) {
    return <Spinner></Spinner>;
  }

  const renderComponent = () => {
    switch (page) {
      case "HOME":
        return <AdminStaff users={organisation.users}></AdminStaff>;
      case "ADMINS":
        return <AdminStaff users={organisation.admins}></AdminStaff>;
      case "PAYMENTS":
        return <div>Nothing to display yet.</div>;
      default:
        break;
    }
  };

  const organisation = data.organisation;

  return (
    <Fragment>
      <div className="p-3 shadow-sm rounded-sm flex items-center mb-3">
        <div className="h-auto w-[200px] mr-5">
          <img src={organisation?.image?.path} alt="" />
        </div>
        <div>
          <h1 className="text-3xl font-bold">
            Name: {organisation.organisationName}
          </h1>
          <h2 className="font-semibold">Ref: {organisation.organisation}</h2>
          <h2 className="font-semibold">
            Date Joined:{" "}
            {new Date(organisation.date).toLocaleDateString("en-GB")}
          </h2>

          <h2>
            Contact Name: {organisation.contactname}{" "}
            {organisation.contactsurname}
          </h2>
          <h2>Contact Email: {organisation.contactemail}</h2>
          <h2>Contact Phone: {organisation.contactnumber}</h2>
        </div>
      </div>
      <div className="flex">
        <button
          onClick={() => setPage("HOME")}
          className={`flex-1 mx-1 border rounded p-3 hover:bg-main2 ${
            page === "HOME" ? "bg-main" : "bg-black"
          }  text-white`}
        >
          Users{" "}
          <span className="font-semibold">({organisation.users.length})</span>
        </button>
        <button
          onClick={() => setPage("ADMINS")}
          className={`flex-1 mx-1 border rounded p-3 hover:bg-main2 ${
            page === "ADMINS" ? "bg-main" : "bg-black"
          }  text-white`}
        >
          Admins{" "}
          <span className="font-semibold">({organisation.admins.length})</span>
        </button>
        <button
          onClick={() => setPage("PAYMENTS")}
          className={`flex-1 mx-1 border rounded p-3 hover:bg-main2 ${
            page === "PAYMENTS" ? "bg-main" : "bg-black"
          }  text-white`}
        >
          Users
        </button>
      </div>
      <div className="mt-3">{renderComponent()}</div>
    </Fragment>
  );
}

export default OrganisationProfile;
