import instance from "../../components/AxiosInterceptor";

const API_URL = "/api/organisation/";
// getOrganisation user
const getOrganisation = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await instance.get(API_URL + `${data}`, config);

    if (response.data) {
      localStorage.setItem("organisation", JSON.stringify(response.data));
    }

    return response.data;
  } catch (error) {
    localStorage.clear();
    window.open("/");
  }
};

// getOrganisation user
const createOrganisation = async (data) => {
  const token = JSON.parse(localStorage.getItem("user")).token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await instance.post(API_URL, data, config);
  return response.data;
};

// getOrganisation user
const getOrganisationReg = async (data) => {
  const response = await instance.get(API_URL + `one/${data}`);

  if (response.data) {
    // localStorage.setItem('organisation', JSON.stringify(response.data));
  }

  return response.data;
};

const organisationService = {
  getOrganisation,
  getOrganisationReg,
  createOrganisation,
};

export default organisationService;
