import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./pages/Dashboard";
import LogInPage from "./pages/LogInPage";
import Profile from "./pages/Profile";
import RegistrationPage from "./pages/RegistrationPage";
import Reference from "./pages/Reference";
import ReferenceDownload from "./components/References/ReferenceDownload";
import ResetPassword from "./pages/ResetPassword";
import SetNewPassoword from "./pages/SetNewPassoword";
import FullReference from "./pages/FullReference";
import NotFound from "./pages/NotFound";
import CygnetProfile from "./pages/CygnetProfile";
import Generate2FA from "./pages/Generate2FA";
import Verify2FACode from "./pages/Verify2FACode";
import RequireAuth from "./components/RequireAuth";
import axios from "axios";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import useAuth from "./hooks/useAuth";
import instance, { AxiosInterceptor } from "./components/AxiosInterceptor";
import Logout from "./pages/Logout";
import AdminDashboard from "./Layouts/admin-dasboard";
import Home from "./components/CAAdmin/Home";
import Organisations from "./components/CAAdmin/Organisations";
import Payments from "./components/CAAdmin/Payments";
import OrganisationProfile from "./components/CAAdmin/OrganisationProfile";
import ShortRegistrationPage from "./pages/ShortRegistrationPage";

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();

  return (
    <React.Fragment>
      <Routes>
        <Route path="/" element={<LogInPage />} />
        <Route path="/generate2fa/:id" element={<Generate2FA />} />
        <Route path="/verify2fa/:id" element={<Verify2FACode />} />

        <Route element={<RequireAuth allowedRoles={["staff", "admin", "orgAdmin", "orgOwner"]} />}>
          <Route path="/profile/:id" element={<Profile />} />
          <Route path="/dashboard" element={<Dashboard />} />
        </Route>

        <Route path="admin" element={<AdminDashboard />}>
          <Route element={<RequireAuth allowedRoles={["admin"]} />}>
            <Route index element={<Home />} />
            <Route path="organisations" element={<Organisations />} />
            <Route path="organisation/:id" element={<OrganisationProfile />} />
            <Route path="payments" element={<Payments />} />
          </Route>
        </Route>

        <Route path="/registration/:id" element={<RegistrationPage />} />
        <Route path="/short-registration/:id" element={<ShortRegistrationPage />} />
        <Route exact path="/reference/:id" element={<Reference />} />
        <Route path="/reference/view/:id" element={<FullReference />} />
        <Route path="/reference/thank-you" element={<ReferenceDownload />} />
        <Route path="/reset_password" element={<ResetPassword />} />
        <Route path="/reset_password/:id" element={<SetNewPassoword />} />
        <Route path="/cygnet-profile" element={<CygnetProfile />} />
        <Route path="/log-out" element={<Logout />} />
        <Route path="*" element={<NotFound />} />
      </Routes>

      <ToastContainer />
    </React.Fragment>
  );
}

export default App;
