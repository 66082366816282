import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login, reset } from "../features/Auth/userSlice";
import { toast } from "react-toastify";
import Logo from "../images/CA-logo-square.png";
import useAuth from "../hooks/useAuth";

function LogInPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { auth, setAuth } = useAuth();

  const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.user);

  useEffect(() => {
    if (isError) {
      toast.error(message, {
        position: "top-center",
        autoClose: 5000,
      });
      if (message.includes("version 1")) {
      }
    }

    if (user) {
      console.log(user);
      setAuth({ ...auth, isAuthenticated: true, user });
      if (user.message) {
        navigate(`/`);
      }

      // if (!user.user.otp) {
      //   localStorage.clear();
      //   navigate("/");
      //   window.location.reload(false);
      // }

      // if (user.user.otp.otpEnabled && !user.user.otp.otpVerified) {
      //   navigate(`/generate2fa/${user.user._id}`);
      //   return;
      // } else if (
      //   user.user.otp.otpEnabled &&
      //   user.user.otp.otpVerified &&
      //   !user.user.otp.remember
      // ) {
      //   navigate(`/verify2fa/${user.user._id}`);
      //   return;
      // }

      // if (user.user.role === "staff") {
      //   navigate(`/profile/${user.user._id}`);
      // }
      // if (user.user.role !== "admin") {
      //   navigate(`/dashboard`);
      // } else {
      //   navigate(`/admin`);
      // }

      switch (user.user.role) {
        case "staff":
          navigate(`/profile/${user.user._id}`);
          break;
        case "admin":
          navigate(`/admin`);
          break;
        default:
          navigate(`/dashboard`);
          break;
      }
    }

    reset();
  }, [isError, isSuccess, navigate, user]);

  // FORM DATA
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  // SUBMIT FORM
  const handleSubmit = (e) => {
    e.preventDefault();

    // TODO VALIDATE FORM INPUT

    dispatch(login(formData));
  };
  // HANDLE FORM INPUT
  const handleChange = (input) => (e) => {
    setFormData({ ...formData, [input]: e.target.value });
  };

  return (
    <React.Fragment>
      {/* log in form */}
      <div className="h-screen  flex flex-col justify-center items-center space-y-6">
        <form onSubmit={handleSubmit} className="space-y-3 md:w-1/2 w-full">
          <div className="">
            <img srcSet={Logo} alt="" className=" mx-auto" width="250px" height="auto" crossOrigin="anonymous" />
          </div>
          <div className="w-full flex flex-col justify-center items-center ">
            <label htmlFor="email">Email </label>

            <input
              type="email"
              className="w-3/4 sm:w-1/2"
              id="email"
              name="email"
              placeholder="Email"
              onChange={handleChange("email")}
            />
          </div>
          <div className="w-full flex flex-col justify-center items-center">
            <label htmlFor="email">Password</label>

            <input
              type="password"
              className="w-3/4 sm:w-1/2"
              id="password"
              name="password"
              placeholder="Password"
              onChange={handleChange("password")}
            />
          </div>
          <div className=" w-full flex flex-col justify-center items-center">
            <button
              className="w-3/4 mt-3 sm:w-1/2 font-semibold text-white bg-main hover:bg-main2 px-3 py-2 rounded shadow-sm"
              type="submit"
            >
              Sign In
            </button>
          </div>
        </form>
        <p
          className="mt-3 hover:cursor-pointer hover:scale-105"
          onClick={() => {
            navigate("/reset_password");
          }}
        >
          Forgot password
        </p>
      </div>
    </React.Fragment>
  );
}

export default LogInPage;
