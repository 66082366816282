import axios from "../../components/AxiosInterceptor";
const API_URL = "/api/metrics/";

// login user
const getNewUsers = async (days = 7) => {
  const token = JSON.parse(localStorage.getItem("user")).token;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL + `user-count/${days}`, config);

  return response.data;
};

const getUserReport = async (id) => {
  const token = JSON.parse(localStorage.getItem("user")).token;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL + `user-report/${id}`, config);

  return response.data;
};

const getCriticalDocs = async () => {
  const token = JSON.parse(localStorage.getItem("user")).token;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL + `critical-report`, config);

  return response.data;
};

const metricService = {
  getNewUsers,
  getUserReport,
  getCriticalDocs,
};

export default metricService;
