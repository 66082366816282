import React, { useEffect, useState } from "react";
import Modal from "../UI/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  addReference,
  deleteReference,
  reset,
} from "../../features/Profile/profileSlice";
import { FaTrashAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import { ThemeProvider, createTheme } from "@mui/material";
import MaterialTable from "material-table";

function Reference({ staff }) {
  const defaultMaterialTheme = createTheme();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isFullRefOpen, setIsFullRefOpen] = useState(false);

  const { profile, isSuccess, isError, message } = useSelector(
    (state) => state.profile
  );
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }

    return () => {
      reset();
    };
  }, [profile, isSuccess, isError]);

  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    email: "",
    phone: "",
  });

  const [fullReference, setFullReference] = useState({
    datesubmitted: "",
    name: "",
    surname: "",
    email: "",
    phone: "",
    capacity: "",
    company: "",
    leaving: "",
    dateto: "",
    datefrom: "",
    ability: "",
    careplans: "",
    character: "",
    attitude: "",
    relationships: "",
    reliability: "",
    discipline: "",
    disciplinedetails: "",
    safeguard: "",
    safeguarddetails: "",
    crime: "",
    crimedetails: "",
    employed: "",
    employeddetails: "",
    comments: "",
    terms: "",
  });

  const handleChange = (input) => (e) => {
    setFormData({ ...formData, [input]: e.target.value });
  };
  const handleRefChange = (input) => (e) => {
    setFullReference({ ...fullReference, [input]: e.target.value });
  };

  const handleSubmit = (e) => {
    try {
      e.preventDefault();
      const postData = { ...formData, id: staff._id };
      dispatch(addReference(postData));
      toast.success(`${formData.name} added as a reference.`);
      setIsOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleRefSubmit = (e) => {
    try {
      e.preventDefault();
      const postData = { ...fullReference, id: staff._id };
      dispatch(addReference(postData));
      toast.success(`${fullReference.name} added as a reference.`);
      setIsFullRefOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  const references = profile.user.staff.references.map((reference) => ({
    ...reference,
  }));

  const userCopy = { ...user.user };

  const hideButton = (data) => {
    return userCopy.role === "staff" || !data.terms;
  };

  const body = () => {
    return (
      <div className="mb-4">
        <p>Please note all fields with a * are required.</p>
        <div className="sm:flex sm:space-x-4 space-y-3 sm:space-y-0">
          <div className="sm:w-1/2">
            <label for="name" className="form-label">
              First name*
            </label>
            <br />
            <input
              className="w-full"
              type="text"
              name="name"
              id="name"
              onChange={handleChange("name")}
              required
            />
          </div>
          <div className="sm:w-1/2">
            <label for="surname" className="form-label">
              Surname*
            </label>
            <br />
            <input
              className="sm:w-full"
              type="text"
              name="surname"
              id="surname"
              onChange={handleChange("surname")}
              required
            />
          </div>
        </div>
        <div className="sm:flex sm:space-x-4 space-y-3 sm:space-y-0">
          <div className="sm:w-1/2">
            <label for="email" className="form-label">
              Email Address*
            </label>
            <br />
            <input
              className="w-full"
              type="email"
              name="email"
              id="email"
              onChange={handleChange("email")}
              required
            />
          </div>
          <div className="sm:w-1/2">
            <label for="phone" className="form-label">
              Phone number*
            </label>
            <br />
            <input
              className="w-full"
              type="text"
              name="phone"
              id="phone"
              onChange={handleChange("phone")}
              required
            />
          </div>
        </div>
      </div>
    );
  };

  const bodyRef = () => {
    return (
      <div className="mb-4">
        <p>Please note all fields with a * are required.</p>
        <div className="sm:flex  space-y-3 sm:space-y-0 sm:space-x-3">
          <div className="w-full ">
            <label className="font-semibold text-xl" htmlFor="datefrom">
              Date known from
            </label>
            <br />
            <input
              className="w-full disabled:bg-main2 disabled:text-white"
              type="date"
              name="datesubmitted"
              id="datesubmitted"
              onChange={handleRefChange("datesubmitted")}
            />
          </div>
        </div>
        <div className="sm:flex  space-y-2 sm:space-y-0 sm:space-x-3">
          <div className="w-full sm:w-1/2">
            <label className="font-semibold text-xl" htmlFor="name">
              First name
            </label>
            <br />
            <input
              className="w-full disabled:bg-main2 disabled:text-white"
              type="text"
              name="name"
              id="name"
              onChange={handleRefChange("name")}
            />
          </div>
          <div className="w-full sm:w-1/2">
            <label className="font-semibold text-xl" htmlFor="surname">
              Surname
            </label>
            <br />
            <input
              className="w-full disabled:bg-main2 disabled:text-white"
              type="text"
              name="surname"
              id="surname"
              onChange={handleRefChange("surname")}
            />
          </div>
        </div>
        <div className="sm:flex  space-y-3 sm:space-y-0 sm:space-x-3">
          <div className="w-full sm:w-1/2">
            <label className="font-semibold text-xl" htmlFor="email">
              Email
            </label>
            <br />
            <input
              className="w-full disabled:bg-main2 disabled:text-white"
              type="email"
              name="email"
              id="email"
              onChange={handleRefChange("email")}
            />
          </div>
          <div className="w-full sm:w-1/2">
            <label className="font-semibold text-xl" htmlFor="phone">
              Contact number
            </label>
            <br />
            <input
              className="w-full disabled:bg-main2 disabled:text-white"
              type="text"
              name="phone"
              id="phone"
              onChange={handleRefChange("phone")}
            />
          </div>
        </div>
        <div className="sm:flex  space-y-3 sm:space-y-0 sm:space-x-3">
          <div className="w-full sm:w-1/2">
            <label className="font-semibold text-xl" htmlFor="company">
              Company
            </label>
            <br />
            <input
              className="w-full disabled:bg-main2 disabled:text-white"
              type="text"
              name="company"
              id="company"
              onChange={handleRefChange("company")}
            />
          </div>
          <div className="w-full sm:w-1/2">
            <label className="font-semibold text-xl" htmlFor="capacity">
              Capacity known
            </label>
            <br />
            <input
              className="w-full disabled:bg-main2 disabled:text-white"
              type="text"
              name="capacity"
              id="capacity"
              onChange={handleRefChange("capacity")}
            />
          </div>
        </div>
        <div className="sm:flex  space-y-3 sm:space-y-0 sm:space-x-3">
          <div className="w-full sm:w-1/2">
            <label className="font-semibold text-xl" htmlFor="title">
              Title at company
            </label>
            <br />
            <input
              className="w-full disabled:bg-main2 disabled:text-white"
              type="text"
              name="title"
              id="title"
              onChange={handleRefChange("title")}
            />
          </div>
          <div className="w-full sm:w-1/2">
            <label className="font-semibold text-xl" htmlFor="leaving">
              Reason for leaving
            </label>
            <br />
            <input
              className="w-full disabled:bg-main2 disabled:text-white"
              type="text"
              name="leaving"
              id="leaving"
              onChange={handleRefChange("leaving")}
            />
          </div>
        </div>
        <div className="sm:flex  space-y-3 sm:space-y-0 sm:space-x-3">
          <div className="w-full sm:w-1/2">
            <label className="font-semibold text-xl" htmlFor="datefrom">
              Date known from
            </label>
            <br />
            <input
              className="w-full disabled:bg-main2 disabled:text-white"
              type="date"
              name="datefrom"
              id="datefrom"
              onChange={handleRefChange("datefrom")}
            />
          </div>
          <div className="w-full sm:w-1/2">
            <label className="font-semibold text-xl" htmlFor="dateto">
              Date known to
            </label>
            <br />
            <input
              className="w-full disabled:bg-main2 disabled:text-white"
              type="date"
              name="dateto"
              id="dateto"
              onChange={handleRefChange("dateto")}
            />
          </div>
        </div>
        <div>
          <h2 className="font-semibold text-center text-xl my-3">
            Select the most appropriate choice
          </h2>
          <p className="text-center">
            How would you assess the following? Please select the relevant
            circle, With 1 being worst and 5 being best.
          </p>
        </div>
        <div className=" space-y-3 sm:space-y-0">
          <div className="w-full">
            <label className="font-semibold text-xl" htmlFor="careplans">
              Ability to follow careplans
            </label>
            <br />

            <select
              name="careplans"
              id="careplans"
              onChange={handleRefChange("careplans")}
              className="w-full "
            >
              <option value="Select">Select</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </div>
          <div className="w-full">
            <label className="font-semibold text-xl" htmlFor="reliability">
              Reliability, time keeping and attendance?
            </label>
            <br />

            <select
              name="reliability"
              id="reliability"
              onChange={handleRefChange("reliability")}
              className="w-full "
            >
              <option value="Select">Select</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </div>
        </div>
        <div className=" sm:flex sm:space-x-3 space-y-3 sm:space-y-0">
          <div className="w-full">
            <label className="font-semibold text-xl" htmlFor="character">
              Character
            </label>
            <br />

            <select
              name="character"
              id="character"
              onChange={handleRefChange("character")}
              className="w-full "
            >
              <option value="Select">Select</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </div>
          <div className="w-full">
            <label className="font-semibold text-xl" htmlFor="attitude">
              Attitude
            </label>
            <br />

            <select
              name="attitude"
              id="attitude"
              onChange={handleRefChange("attitude")}
              className="w-full "
            >
              <option value="Select">Select</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </div>
        </div>
        <div className=" space-y-3 sm:space-y-0">
          <div className="w-full">
            <label className="font-semibold text-xl" htmlFor="relationships">
              Relationship with colleagues
            </label>
            <br />

            <select
              name="relationships"
              id="relationships"
              onChange={handleRefChange("relationships")}
              className="w-full "
            >
              <option value="Select">Select</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </div>
          <div className="w-full">
            <label className="font-semibold text-xl" htmlFor="ability">
              Ability to work under own initiative
            </label>
            <br />

            <select
              name="ability"
              id="ability"
              onChange={handleRefChange("ability")}
              className="w-full "
            >
              <option value="Select">Select</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </div>
        </div>
        <div>
          <h2 className="font-semibold text-center text-xl my-3">
            Answer the following to the best of your ability
          </h2>
        </div>
        <div className=" space-y-3 sm:space-y-0">
          <div className="w-full">
            <label className="font-semibold text-xl" htmlFor="discipline">
              Has the candidate ever been subject to a discipline hearing?
            </label>
            <br />

            <select
              name="discipline"
              id="discipline"
              onChange={handleRefChange("discipline")}
              className="w-full "
            >
              <option value="Select">Select</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>

            {formData.discipline === "yes" && (
              <div className="mt-3">
                <label
                  className="font-semibold text-xl"
                  htmlFor="disciplinedetails"
                >
                  Please provide more details
                </label>
                <br />

                <textarea className="w-full" name="disciplinedetails" />
              </div>
            )}
          </div>
          <div className="w-full">
            <label className="font-semibold text-xl" htmlFor="safeguard">
              Are you aware of the candidate's involvement in any safeguarding
              investigations?
            </label>
            <br />

            <select
              name="safeguard"
              id="safeguard"
              onChange={handleRefChange("safeguard")}
              className="w-full "
            >
              <option value="Select">Select</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>

            {formData.safeguard === "yes" && (
              <div className="mt-3">
                <label
                  className="font-semibold text-xl"
                  htmlFor="safeguarddetails"
                >
                  Please provide more details
                </label>
                <br />

                <textarea
                  className="w-full"
                  name="safeguarddetails"
                  onChange={handleRefChange("safeguarddetails")}
                />
              </div>
            )}
          </div>
        </div>
        <div className=" space-y-3 sm:space-y-0">
          <div className="w-full">
            <label className="font-semibold text-xl" htmlFor="employed">
              Are you aware of any reasons the applicant should not be employed
              to work with children or vulnerable people?
            </label>
            <br />

            <select
              name="employed"
              id="employed"
              onChange={handleRefChange("employed")}
              className="w-full "
            >
              <option value="Select">Select</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>

            {formData.employed === "yes" && (
              <div className="mt-3">
                <label
                  className="font-semibold text-xl"
                  htmlFor="employeddetails"
                  onChange={handleRefChange("employeddetails")}
                >
                  Please provide more details
                </label>
                <br />

                <textarea className="w-full" name="employeddetails" />
              </div>
            )}
          </div>
          <div className="w-full">
            <label className="font-semibold text-xl" htmlFor="crime">
              To the best of your knowledge, has the applicant been convicted or
              cautioned of a criminal offence?
            </label>
            <br />

            <select
              name="crime"
              id="crime"
              onChange={handleRefChange("crime")}
              className="w-full "
            >
              <option value="Select">Select</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>

            {formData.crime === "yes" && (
              <div className="mt-3">
                <label
                  className="font-semibold text-xl"
                  htmlFor="crimedetails"
                  onChange={handleRefChange("crimedetails")}
                >
                  Please provide more details
                </label>
                <br />

                <textarea className="w-full" />
              </div>
            )}
          </div>
        </div>
        <div className="mb-3">
          <label for="reason" className="font-semibold text-xl">
            Additional comments
          </label>
          <textarea
            className="form-control"
            id="comments"
            name="comments"
            aria-describedby="commentsHelp"
            onChange={handleRefChange("comments")}
          />
        </div>
        <div className="form-check mb-3">
          <input
            className="form-check-input"
            type="checkbox"
            value="accepted"
            id="terms"
            name="terms"
            onChange={handleRefChange("terms")}
          />
          <label className="form-check-label" for="terms">
            I can confirm that all the details provided are accurate at the time
            that this reference was completed. I can confirm that I am
            authorised to provide a reference on behalf of my organisation. I
            understand this reference may be shown to a third party for auditing
            purposes and I can confirm that Tafara Care Services has this
            organisation’s consent and authorisation to disclose the contents of
            this reference to its end user, hirer clients. I understand that the
            applicant has the legal right to request a copy of their reference.
          </label>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Modal
        open={isOpen}
        closeModal={() => {
          setIsOpen(false);
        }}
        title={"Adding New Reference"}
        body={body()}
        submitForm={handleSubmit}
      />
      <Modal
        open={isFullRefOpen}
        closeModal={() => {
          setIsFullRefOpen(false);
        }}
        title={"Adding Existing Reference"}
        body={bodyRef()}
        submitForm={handleRefSubmit}
      />
      <div className="space-y-3 mb-3">
        <div>
          <button
            className="font-semibold shadow   rounded-lg  bg-main hover:bg-main2 py-2 px-5 text-white mr-3"
            onClick={() => {
              setIsOpen(true);
            }}
          >
            Add Reference
          </button>
          <button
            className="font-semibold shadow   rounded-lg  bg-main2 hover:bg-main py-2 px-5 text-white"
            onClick={() => {
              setIsFullRefOpen(true);
            }}
          >
            Add Existing Reference
          </button>
        </div>
        <div style={{ maxWidth: "100%" }}>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
          />
          <ThemeProvider theme={defaultMaterialTheme}>
            <MaterialTable
              columns={[
                { title: "Name", field: "name" },
                { title: "Surname", field: "surname" },
                { title: "Date Added", field: "datesubmitted", type: "date" },
                { title: "Phone", field: "phone" },
                { title: "email", field: "email" },
              ]}
              data={references}
              options={{
                actionsColumnIndex: -1,
                rowStyle: {
                  padding: "0 1rem",
                  textAlign: "center",
                },
                headerStyle: {
                  padding: "0 1rem",
                  fontWeight: "bold",
                  fontSize: "1.3rem",
                },
                pageSizeOptions: [5, 10, 20],
                pageSize: 5,
              }}
              actions={[
                (rowData) => ({
                  icon: "chevron_right",
                  tooltip: "View",
                  hidden: hideButton(rowData),
                  onClick: (event, rowData) => {
                    window.open("/reference/view/" + rowData._id);
                  },
                }),
                () => ({
                  icon: "delete",
                  tooltip: "Delete",
                  onClick: (event, rowData) => {
                    dispatch(
                      deleteReference({ id: userCopy._id, refId: rowData._id })
                    );
                    toast.success("Reference successfully deleted");
                  },
                }),
              ]}
              title="All Documents"
            />
          </ThemeProvider>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Reference;
