import React from 'react';
import NavBar from '../components/NavBar/NavBar';

function NotFound() {
    return (
        <React.Fragment>
            <div className="h-screen w-screen flex flex-col items-center ">
                <NavBar/>
                <div className="flex-grow flex flex-col justify-center items-center space-y-4">
                    <h1 className="text-6xl font-bold">&#128517;</h1>
                    <h1 className="text-6xl font-bold">404 Not Found</h1>
                    <p>The resource you are looking for could not be found</p>
                    <p>
                        {' '}
                        <a className="font-semibold underline" href="/">
                            Click here
                        </a>{' '}
                        to go back somewhere familiar
                    </p>
                </div>
            </div>
            {' '}
        </React.Fragment>
    );
}

export default NotFound;
