import instance from "../../components/AxiosInterceptor";

const API_URL = "/api/compliance/";
// Get GroupS
const checkComplianceStatus = async (id) => {
  const token = JSON.parse(localStorage.getItem("user")).token;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await instance.get(API_URL + "check/" + id, config);

  return response.data;
};

const assignUserToGroup = async (id, team) => {
  const token = JSON.parse(localStorage.getItem("user")).token;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await instance.post(
    API_URL + "assign-to-team",
    { id, team },
    config
  );

  return response.data;
};

const complianceService = {
  checkComplianceStatus,
  assignUserToGroup,
};

export default complianceService;
