import React from 'react';
import {useSelector} from 'react-redux';
import Cygnet from '../components/AgentProfiles/Cygnet';

//import { PDFViewer } from '@react-pdf/renderer';

function CygnetProfile() {
    const {profile, isError, isSuccess, isLoading} = useSelector((state) => state.profile);
    const user = profile.user;

    return (
        <React.Fragment>
            <Cygnet user={user}/>
        </React.Fragment>
    );
}

export default CygnetProfile;
