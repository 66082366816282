import { saveAs } from "file-saver";
import { Buffer } from "buffer";
import {
  AlignmentType,
  Document,
  Header,
  HorizontalPositionAlign,
  HorizontalPositionRelativeFrom,
  ImageRun,
  Packer,
  Paragraph,
  SectionType,
  ShadingType,
  Table,
  TableCell,
  TableRow,
  TextRun,
  TextWrappingSide,
  TextWrappingType,
  VerticalPositionAlign,
  VerticalPositionRelativeFrom,
  WidthType,
  convertInchesToTwip,
} from "docx";
import imageToBase64 from "image-to-base64/browser";

const generateElysiumProfile = async (staff, logo) => {
  const basicLifeSupport = staff.staff.trainings.find(
    (training) => training.name == "Basic Life Support (HCW)"
  );
  const immediateLifeSupport = staff.staff.trainings.find(
    (training) => training.name == "Immediate Life support (RMN)"
  );
  const manualHandling = staff.staff.trainings.find(
    (training) => training.name == "Manual Handling"
  );
  const healthSafety = staff.staff.trainings.find(
    (training) => training.name == "Health and Safety"
  );
  const breakAway = staff.staff.trainings.find(
    (training) => training.name == "Breakaway"
  );
  const violenceAggression = staff.staff.trainings.find(
    (training) => training.name == "(GSA) Management of violence and aggression"
  );
  const infectionControl = staff.staff.trainings.find(
    (training) => training.name == "Infection Control"
  );
  const safeguard = staff.staff.trainings.find(
    (training) => training.name == "Safeguarding (adults and children Level 3)"
  );
  const dataProtection = staff.staff.trainings.find(
    (training) => training.name == "GDPR"
  );
  const mentalAct = staff.staff.trainings.find(
    (training) => training.name == "Mental Health Act"
  );
  const informationGovernance = staff.staff.trainings.find(
    (training) => training.name == "Information Governance"
  );

  const prevent = staff.staff.trainings.find(
    (training) => training.name == "Prevent Radicalisation"
  );

  const gdpr = staff.staff.trainings.find(
    (training) => training.name == "GDPR"
  );

  const conflict = staff.staff.trainings.find(
    (training) => training.name == "Conflict Management and Complaints Handling"
  );
  const depravation = staff.staff.trainings.find(
    (training) => training.name == "Deprivation of Liberties Safeguards"
  );
  const foodHygiene = staff.staff.trainings.find(
    (training) => training.name == "Food and Hand Hygiene"
  );
  const equality = staff.staff.trainings.find(
    (training) => training.name == "Equality and Diversity"
  );
  const loneworker = staff.staff.trainings.find(
    (training) => training.name == "Lone Worker"
  );
  const coshh = staff.staff.trainings.find(
    (training) => training.name == "Coshh"
  );
  const personCentered = staff.staff.trainings.find(
    (training) => training.name == "Person Centered Care"
  );
  const riddor = staff.staff.trainings.find(
    (training) => training.name == "Riddor"
  );
  const knife = staff.staff.trainings.find(
    (training) => training.name == "Ligature Knife Training"
  );
  const observations = staff.staff.trainings.find(
    (training) => training.name == "Observations"
  );
  const selfharm = staff.staff.trainings.find(
    (training) => training.name == "Working with those who self-harm"
  );
  const riskAssessment = staff.staff.trainings.find(
    (training) => training.name == "Risk Assessment"
  );
  const medicalManagement = staff.staff.trainings.find(
    (training) => training.name == "Medication Management"
  );

  const righttowork = staff.staff.documents.find(
    (document) => document.doctype == "Right to Work"
  );
  const companyId = staff.staff.documents.find(
    (document) => document.doctype == "Company ID"
  );

  const references = staff.staff.references.filter(
    (reference) => reference.terms == "accepted"
  );

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  function trainingRow(name, training) {
    return new TableRow({
      children: [
        new TableCell({
          width: {
            size: 50,
            type: WidthType.PERCENTAGE,
          },
          margins: { top: 50, bottom: 50, left: 50 },

          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: name,

                  font: "Calibri",
                  size: 22,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          width: {
            size: 25,
            type: WidthType.PERCENTAGE,
          },
          margins: { top: 50, bottom: 50, left: 50 },

          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: training
                    ? new Date(training.datetrained).toLocaleDateString(
                        "en-GB",
                        options
                      )
                    : "",

                  font: "Calibri",
                  size: 22,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          width: {
            size: 60,
            type: WidthType.PERCENTAGE,
          },
          margins: { top: 50, bottom: 50, left: 50 },

          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: training
                    ? new Date(training.expirydate).toLocaleDateString(
                        "en-GB",
                        options
                      )
                    : "",

                  font: "Calibri",
                  size: 22,
                }),
              ],
            }),
          ],
        }),
      ],
    });
  }

  const imageBase64 = await imageToBase64(staff.image.path) // Image URL
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error); // Logs an error if there was one
    });

  const imageLogo = await imageToBase64(logo) // Image URL
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error); // Logs an error if there was one
    });

  function textRun(text, bold = false) {
    return new TextRun({
      font: "Calibri",
      size: 20,
      text: text,
      bold: bold,
    });
  }

  function paragraph(text, bold = false) {
    return new Paragraph({
      children: [textRun(text, bold)],
    });
  }

  const shading = { type: ShadingType.SOLID, color: "C5E0B3" };

  function createTableCell(text, size, shading) {
    return new TableCell({
      width: {
        size: size ? size : 20,
        type: WidthType.PERCENTAGE,
      },
      margins: shading ? { top: 50, bottom: 50, left: 50 } : {},
      shading: shading ? shading : {},
      children: [
        new Paragraph({
          children: [
            new TextRun({
              font: "Calibri",
              text: text,
              size: 20,
              color: "000000",
              bold: shading ? true : false,
            }),
          ],
          alignment: AlignmentType.LEFT,
          color: "000000",
        }),
      ],
    });
  }

  const profile = new Document({
    sections: [
      {
        properties: {
          type: SectionType.CONTINUOUS,
        },

        headers: {
          default: new Header({
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Elysium Healthcare Agency Proforma – Temporary Workers",
                    font: "Calibri",
                    size: 20,
                    bold: true,
                  }),
                ],
                indent: {
                  left: convertInchesToTwip(1.5),
                },
              }),
              new Paragraph({
                children: [
                  new ImageRun({
                    data: Buffer.from(imageLogo, "base64"),
                    transformation: {
                      width: 200,
                      height: 150,
                    },
                    floating: {
                      horizontalPosition: {
                        offset: 2000,
                      },
                      verticalPosition: {
                        offset: 2000,
                      },
                    },
                    wrap: {
                      type: TextWrappingType.SQUARE,
                      side: TextWrappingSide.BOTH_SIDES,
                    },
                  }),
                ],
              }),
            ],
          }),
        },
        children: [
          new Paragraph({
            children: [
              new ImageRun({
                data: Buffer.from(imageBase64, "base64"),
                transformation: {
                  width: 200,
                  height: 200,
                },
                floating: {
                  horizontalPosition: {
                    relative: HorizontalPositionRelativeFrom.PARAGRAPH,
                    align: HorizontalPositionAlign.RIGHT,
                  },
                  verticalPosition: {
                    relative: VerticalPositionRelativeFrom.PARAGRAPH,
                    align: VerticalPositionAlign.CENTER,
                  },
                  wrap: {
                    type: TextWrappingType.SQUARE,
                    side: TextWrappingSide.BOTH_SIDES,
                  },
                },
                margins: {
                  top: 201440,
                  bottom: 201440,
                },
              }),
            ],
          }),
          new Paragraph({
            children: [],
          }),
          paragraph(
            "Before you supply an agency worker to Elysium Healthcare, you must complete a proforma for each individual agency worker confirming basic information on the worker before their first shift and when any updated documents are provided."
          ),
          paragraph(""),
          new Paragraph({
            children: [
              new TextRun({
                text: "Agency Worker Information",
                bold: true,
                underline: {},
                font: "Calibri",
                size: 20,
              }),
            ],
          }),
          paragraph(""),

          new Table({
            width: {
              size: 75,
              type: WidthType.PERCENTAGE,
            },
            rows: [
              new TableRow({
                children: [
                  createTableCell("Name of Agency", 40, shading),
                  createTableCell(staff.organisationName, 60),
                ],
              }),
            ],
          }),
          paragraph(""),
          new Table({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            rows: [
              new TableRow({
                children: [
                  createTableCell("Full Forename", 20, shading),
                  createTableCell(staff.name, 30),
                  createTableCell("Full Surname", 20, shading),
                  createTableCell(staff.surname, 30),
                ],
              }),
              new TableRow({
                children: [
                  createTableCell("Known As", 20, shading),
                  createTableCell(staff.name + " " + staff.surname, 30),
                  createTableCell("Date of Birth", 20, shading),
                  createTableCell(
                    staff.staff.dateofbirth
                      ? new Date(staff.staff.dateofbirth).toLocaleDateString(
                          "en-GB"
                        )
                      : "",
                    30
                  ),
                ],
              }),
              new TableRow({
                children: [
                  createTableCell("NI number", 20, shading),
                  createTableCell(staff.staff.ninumber, 30),
                  createTableCell("Assignment Role", 20, shading),
                  createTableCell(staff.staff.role, 30),
                ],
              }),
              new TableRow({
                children: [
                  createTableCell(
                    "Professional Registration Number*",
                    30,
                    shading
                  ),
                  createTableCell(
                    staff.staff.role != "HCA" ? staff.staff.nmcnumber : "",
                    20
                  ),
                  createTableCell("Expiry Date", 20, shading),
                  createTableCell(
                    staff.staff.nmcexpiry && staff.staff.role != "HCA"
                      ? new Date(staff.staff.nmcexpiry).toLocaleDateString(
                          "en-GB"
                        )
                      : "",
                    30
                  ),
                ],
              }),
            ],
          }),
          //   ADD HERE
          paragraph(""),
          new Table({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 70,
                      type: WidthType.PERCENTAGE,
                    },
                    margins: { top: 50, bottom: 50, left: 50 },
                    shading: {
                      type: ShadingType.SOLID,
                      color: "F2F2F2",
                    },
                    children: [
                      paragraph(
                        "Has the agency obtained and retained copies of satisfactory evidence of the workers current Right to Work in the UK, in line with Home Office legislation and is not restricted to the work undertaken?"
                      ),
                    ],
                  }),
                  createTableCell(righttowork ? "Yes" : "No"),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 70,
                      type: WidthType.PERCENTAGE,
                    },
                    margins: { top: 50, bottom: 50, left: 50 },
                    shading: {
                      type: ShadingType.SOLID,
                      color: "F2F2F2",
                    },
                    children: [
                      paragraph("Date Certificate of Fitness was issued"),
                    ],
                  }),
                  createTableCell(""),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 70,
                      type: WidthType.PERCENTAGE,
                    },
                    margins: { top: 50, bottom: 50, left: 50 },
                    shading: {
                      type: ShadingType.SOLID,
                      color: "F2F2F2",
                    },
                    children: [
                      paragraph("Date Certificate of Fitness was issued"),
                    ],
                  }),
                  createTableCell(""),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 70,
                      type: WidthType.PERCENTAGE,
                    },
                    margins: { top: 50, bottom: 50, left: 50 },
                    shading: {
                      type: ShadingType.SOLID,
                      color: "F2F2F2",
                    },
                    children: [
                      paragraph(
                        "Has the agency obtained and retained copies of satisfactory references for the last 3 years, a full work history for the agency worker, with all gaps investigated and covered?"
                      ),
                    ],
                  }),
                  createTableCell(references.length >= 2 ? "Yes" : "No"),
                ],
              }),
            ],
          }),
          paragraph(""),
          new Table({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 70,
                      type: WidthType.PERCENTAGE,
                    },
                    margins: { top: 50, bottom: 50, left: 50 },
                    shading: {
                      type: ShadingType.SOLID,
                      color: "F2F2F2",
                    },
                    children: [
                      paragraph(
                        "Has the agency worker received both doses of a COVID-19 Vaccine?"
                      ),
                    ],
                  }),
                  createTableCell(staff.staff.isVaccinated ? "Yes" : "No"),
                ],
              }),
            ],
          }),
          paragraph(""),
          new Paragraph({
            children: [
              new TextRun({
                text: "Please provide evidence below of covid vaccination status ",
                bold: true,
                color: "ff0000",
                font: "Calibri",
              }),
            ],
          }),
          paragraph(""),
          new Table({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 60,
                      type: WidthType.PERCENTAGE,
                    },
                    margins: { top: 50, bottom: 50, left: 50 },
                    shading: {
                      type: ShadingType.SOLID,
                      color: "F2F2F2",
                    },
                    children: [
                      paragraph(
                        "If yes, and vaccinated in the UK please provide the agency workers COVID-19 Vaccination QR Code, accessed through the NHS COVID pass."
                      ),
                      paragraph(""),
                      paragraph(
                        "If yes, and vaccinated outside of the UK, please provide a self-certification letter."
                      ),
                      paragraph(""),
                      paragraph(
                        "If no, and the worker is medically exempt from receiving a vaccine, please provide proof of the agency workers medical exemption via the NHS COVID pass (QR code). "
                      ),
                    ],
                  }),
                  createTableCell(""),
                ],
              }),
            ],
          }),
          paragraph(""),
          new Table({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 60,
                      type: WidthType.PERCENTAGE,
                    },
                    margins: { top: 50, bottom: 50, left: 50 },
                    shading: {
                      type: ShadingType.SOLID,
                      color: "F2F2F2",
                    },
                    children: [
                      paragraph(
                        "Disclosure and Barring Service (DBS) certificate number (12 digits, no letters)"
                      ),
                    ],
                  }),
                  createTableCell(staff.staff.dbsnumber),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 60,
                      type: WidthType.PERCENTAGE,
                    },
                    margins: { top: 50, bottom: 50, left: 50 },
                    shading: {
                      type: ShadingType.SOLID,
                      color: "F2F2F2",
                    },
                    children: [
                      paragraph("Disclosure and Barring Service Issue Date"),
                    ],
                  }),
                  createTableCell(
                    staff.staff.dbsissue
                      ? new Date(staff.staff.dbsissue).toLocaleDateString(
                          "en-GB"
                        )
                      : ""
                  ),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 60,
                      type: WidthType.PERCENTAGE,
                    },
                    margins: { top: 50, bottom: 50, left: 50 },
                    shading: {
                      type: ShadingType.SOLID,
                      color: "F2F2F2",
                    },
                    children: [
                      paragraph(
                        "Level of DBS Certificate Issued (Basic, Standard, Enhanced)"
                      ),
                    ],
                  }),
                  createTableCell(
                    staff.staff.dbsnumber ? staff.staff.dbslevel : ""
                  ),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 60,
                      type: WidthType.PERCENTAGE,
                    },
                    margins: { top: 50, bottom: 50, left: 50 },
                    shading: {
                      type: ShadingType.SOLID,
                      color: "F2F2F2",
                    },
                    children: [
                      paragraph(
                        "Has this been checks against the Adults or Childrens Barred Lists or Both?"
                      ),
                    ],
                  }),
                  createTableCell(
                    staff.staff.dbsnumber ? "Adult and Childrens" : ""
                  ),
                ],
              }),
            ],
          }),
          paragraph(""),
          new Paragraph({
            children: [
              new TextRun({
                text: "Agency Worker Training Record",
                bold: true,
                underline: {},
                font: "Calibri",
                size: 20,
              }),
            ],
          }),
          paragraph(),
          new Paragraph({
            children: [
              new TextRun({
                text: "Mandatory Courses (Course must have been completed within the last 12 months)",
                bold: true,

                font: "Calibri",
                size: 20,
              }),
            ],
          }),
          paragraph(""),
          new Table({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 50,
                      type: WidthType.PERCENTAGE,
                    },
                    margins: { top: 50, bottom: 50, left: 50 },
                    shading: {
                      type: ShadingType.SOLID,
                      color: "F4B083",
                    },
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: "Courses",
                            bold: true,
                            font: "Calibri",
                            size: 22,
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: 25,
                      type: WidthType.PERCENTAGE,
                    },
                    margins: { top: 50, bottom: 50, left: 50 },
                    shading: {
                      type: ShadingType.SOLID,
                      color: "F4B083",
                    },
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: "Completion Date",
                            bold: true,
                            font: "Calibri",
                            size: 22,
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: 60,
                      type: WidthType.PERCENTAGE,
                    },
                    margins: { top: 50, bottom: 50, left: 50 },
                    shading: {
                      type: ShadingType.SOLID,
                      color: "F4B083",
                    },
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: "Revalidation Date",
                            bold: true,
                            font: "Calibri",
                            size: 22,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              //   TRAINING ROWS
              trainingRow("Basic life support**", basicLifeSupport),
              trainingRow(
                "Immediate Life Support (ILS) (qualified staff must be ILS trained)**",
                immediateLifeSupport
              ),
              trainingRow("Manual Handling", manualHandling),
              trainingRow("Health and Safety", healthSafety),

              trainingRow("Breakaway", breakAway),
              trainingRow(
                "Management of Violence and Aggression (Must be a GSA accredited course)**",
                violenceAggression
              ),
              trainingRow("Infection control", infectionControl),
              trainingRow(
                "Safeguarding (adults and children Level 3)",
                safeguard
              ),
              trainingRow("Confidentiality/Data protection", dataProtection),
              trainingRow("Mental Health Act", mentalAct),
              trainingRow("Information Governance", informationGovernance),
              trainingRow("NEWS2 training "),
              trainingRow("prevent", prevent),
              trainingRow("Person Centered Care", personCentered),
            ],
          }),

          paragraph(""),
          new Paragraph({
            children: [
              new TextRun({
                text: "Additional Courses required for site specific activity (the Elysium Site will confirm which courses are required)",
                bold: true,

                font: "Calibri",
                size: 20,
              }),
            ],
          }),
          paragraph(""),
          new Table({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 50,
                      type: WidthType.PERCENTAGE,
                    },
                    margins: { top: 50, bottom: 50, left: 50 },
                    shading: {
                      type: ShadingType.SOLID,
                      color: "F4B083",
                    },
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: "Courses",
                            bold: true,
                            font: "Calibri",
                            size: 22,
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: 25,
                      type: WidthType.PERCENTAGE,
                    },
                    margins: { top: 50, bottom: 50, left: 50 },
                    shading: {
                      type: ShadingType.SOLID,
                      color: "F4B083",
                    },
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: "Completion Date",
                            bold: true,
                            font: "Calibri",
                            size: 22,
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: 60,
                      type: WidthType.PERCENTAGE,
                    },
                    margins: { top: 50, bottom: 50, left: 50 },
                    shading: {
                      type: ShadingType.SOLID,
                      color: "F4B083",
                    },
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: "Revalidation Date",
                            bold: true,
                            font: "Calibri",
                            size: 22,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              //   TRAINING ROWS
              trainingRow("GDPR", gdpr),
              trainingRow(
                "Conflict Management and Complaints Handling",
                conflict
              ),
              trainingRow("Deprivation of Liberties Safeguards", depravation),
              trainingRow("Food Hygiene", foodHygiene),
              trainingRow("Equality and Diversity", equality),
              trainingRow("Lone Worker", loneworker),
              trainingRow("COSHH", coshh),
              trainingRow("RIDDOR", riddor),
              trainingRow("Ligature/Knife", knife),
              trainingRow("Observations", observations),
              trainingRow("Working with those who self-harm", selfharm),

              trainingRow("Risk Assessment", riskAssessment),
              trainingRow("Medication Management", medicalManagement),
              trainingRow("Specialist - Peg Feed (supply where required)"),
              trainingRow(
                "Specialist - Tracheostomy Care (supply where required)"
              ),
            ],
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: "** Courses must be undertaken face-to-face and practical training involved",

                font: "Calibri",
                size: 16,
              }),
            ],
          }),
          paragraph(""),
          new Paragraph({
            children: [
              new TextRun({
                text: "Further Information",
                bold: true,
                font: "Calibri",
                size: 22,
                underline: {},
              }),
            ],
          }),
          paragraph(""),
          new Paragraph({
            children: [
              new TextRun({
                text: `Please confirm that the agency worker has a valid photo ID issued by the agency with a photo that matches the photo attached:${
                  companyId ? "Yes" : "No"
                }`,

                font: "Calibri",
                size: 22,
              }),
            ],
          }),
          paragraph(""),
          new Paragraph({
            children: [
              new TextRun({
                text: "confirmation",
                bold: true,
                font: "Calibri",
                size: 22,
                underline: {},
              }),
            ],
          }),
          paragraph(""),
          new Paragraph({
            children: [
              new TextRun({
                text: `The agency worker named in this proforma has been supplied by the agency named on this proforma, to Elysium Healthcare (Elysium) for consideration to undertake temporary assignments with Elysium.`,

                font: "Calibri",
                size: 22,
              }),
            ],
          }),
          paragraph(""),
          new Paragraph({
            children: [
              new TextRun({
                text: `I confirm that by submitting this proforma that the information is correct and factual. I also understand that this proforma will also be used during any audit of the agency worker files to ensure compliance with CQC and Elysium requirements as legally and contractually required.`,

                font: "Calibri",
                size: 22,
              }),
            ],
          }),
          paragraph(""),
          new Paragraph({
            children: [
              new TextRun({
                text: `I also confirm that all information and documentation on this agency worker will be maintained and up to date and a new proforma will be provided to Elysium such as when Right to Work/DBS or Training documents are updated.`,

                font: "Calibri",
                size: 22,
              }),
            ],
          }),
          paragraph(""),
          new Table({
            width: {
              size: 75,
              type: WidthType.PERCENTAGE,
            },
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 25,
                      type: WidthType.PERCENTAGE,
                    },
                    margins: { top: 50, bottom: 50, left: 50 },
                    shading: {
                      type: ShadingType.SOLID,
                      color: "BDD6EE",
                    },
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: "Name",

                            font: "Calibri",
                            size: 22,
                          }),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    width: {
                      size: 25,
                      type: WidthType.PERCENTAGE,
                    },
                    margins: { top: 50, bottom: 50, left: 50 },
                    shading: {
                      type: ShadingType.SOLID,
                      color: "BDD6EE",
                    },
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                          new TextRun({
                            text: "Date",

                            font: "Calibri",
                            size: 22,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new TableRow({
                children: [
                  createTableCell(staff.staff.compliantBy?.name ?? "", 60),
                  createTableCell(new Date().toLocaleDateString("en-GB"), 40),
                ],
              }),
            ],
          }),
          paragraph(""),
          paragraph(
            "Once completed please email this proforma to the relevant contact within Elysium Healthcare prior to the agency worker starting."
          ),
          paragraph(""),
          new Paragraph({
            children: [
              new TextRun({
                text: `Data Protection Statement`,
                bold: true,
                font: "Calibri",
                size: 22,
              }),
            ],
          }),
          paragraph(""),
          paragraph(
            "All information will used in for the purposes of engagement and supply of agency workers and activities in relation to the engagement and supply of agency workers. All information will be treated as confidential and managed in accordance with the relevant data protect legislation and guidance."
          ),

          // END
        ],
      },
    ],
  });

  Packer.toBlob(profile)
    .then((blob) => {
      saveAs(blob, "Elysium Profile.docx");
    })
    .catch((error) => {
      console.log(error);
    });
};

export default generateElysiumProfile;
