import React, { useEffect, useState } from 'react';
import Modal from '../UI/Modal';
import { useDispatch, useSelector } from 'react-redux';
import {
	addTraining,
	deleteTraining,
	reset,
	revokeTraining,
	verifyTraining,
} from '../../features/Profile/profileSlice';
import { ThemeProvider, createTheme } from '@mui/material';
import MaterialTable from 'material-table';
import { toast } from 'react-toastify';

function Trainings() {
	const dispatch = useDispatch();

	const [isOpen, setIsOpen] = useState(false);
	const [image, setImage] = useState('');

	const { profile, isSuccess, isLoading, message, isError } = useSelector((state) => state.profile);
	const id = profile.user._id;

	const { user } = useSelector((state) => state.user);

	function removeTraining(id, trainingId) {
		const userData = { id, trainingId };

		toast.success(`Training deleted successfully`);
		dispatch(deleteTraining(userData));
	}

	const [formData, setFormData] = useState({
		image,
		name: '',
		datetrained: '',
		expirydate: '',
	});

	const onImageChange = (e) => {
		const uploadedImage = e.target.files[0];
		var reader = new FileReader();
		reader.readAsDataURL(uploadedImage);
		reader.onload = function () {
			setImage(reader.result);
		};
		reader.onerror = function (error) {
			console.log('Error: ', error);
		};
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const userId = { id };

		const toAdd = {
			image: image,
			...userId,
			name: formData.name,
			datetrained: formData.datetrained,
			expirydate: formData.expirydate,
		};

		// const toAdd = { ...formData, ...userId };

		const response = await dispatch(addTraining(toAdd));

		if (response.error?.message === 'Rejected') {
			toast.error(response.payload);
		} else {
			toast.success(`${toAdd.name} added successfully.`);
		}
	};

	const handleChange = (input) => (e) => {
		setFormData({ ...formData, [input]: e.target.value });
	};

	useEffect(() => {
		if (isError) {
			toast.error(message);
		}

		return () => {
			reset();
		};
	}, [profile, isSuccess, isError]);

	const defaultMaterialTheme = createTheme();
	const trainings = profile.user.staff.trainings.map((training) => ({
		...training,
	}));

	const userCopy = { ...profile.user };

	const loggedInUser = { ...user.user };

	const hideVerified = (data) => {
		return loggedInUser.role === 'staff' || data.verified;
	};

	const hideUnverified = (data) => {
		return loggedInUser.role === 'staff' || !data.verified;
	};

	const body = () => {
		return (
			<div className="space-y-4 ">
				<div className="w-full">
					<label htmlFor="name" className="form-label">
						Training Name
					</label>
					<br />
					<select id="name" class="form-select" name="name" onChange={handleChange('name')} className="w-full" required>
						<option>Select Training</option>

						<option value="Asbestos">Asbestos</option>
						<option value="Basic Life Support (HCW)">Basic Life Support (HCW)</option>
						<option value="Bundled Trainings">Bundled Trainings</option>
						<option value="Breakaway">Breakaway</option>
						<option value="Conflict Management and Complaints Handling">
							Conflict Management and Complaints Handling
						</option>
						<option value="Coshh">Coshh</option>
						<option value="DOLS">DOLS</option>
						<option value="Deprivation of Liberties Safeguards">Deprivation of Liberties Safeguards</option>
						<option value="Dysphagia">Dysphagia</option>
						<option value="Equality and Diversity">Equality and Diversity</option>
						<option value="Epilepsy Awareness">Epilepsy Awareness</option>
						<option value="Fire Awareness">Fire Awareness/Safety</option>
						<option value="First Aid Awareness">First Aid Awareness</option>
						<option value="Fitness to Work">Fitness to Work</option>
						<option value="Food and Hand Hygiene">Food and Hand Hygiene</option>
						<option value="General Security">General Security</option>
						<option value="GDPR">GDPR</option>

						<option value="(GSA) Management of violence and aggression">
							(GSA) Management of violence and aggression
						</option>
						<option value="Health and Safety">Health and Safety</option>
						<option value="Health Care Support Worker certificate and mental health inpatient settings (CAMHS)">
							Health Care Support Worker certificate and mental health inpatient settings (CAMHS)
						</option>
						<option value="Immediate Life support (RMN)">Immediate Life support (RMN)</option>
						<option value="Infection Control">Infection Control</option>

						<option value="Information Governance">Information Governance</option>
						<option value="Ladder Safety">Ladder Safety</option>
						<option value="Legionella">Legionella</option>
						<option value="Ligature Knife Training">Ligature Knife Training</option>
						<option value="Lone Worker">Lone Worker</option>
						<option value="Manual Handling">Manual Handling</option>
						<option value="MAYBO">MAYBO</option>
						<option value="Medication Management">Medication Management</option>
						<option value="Mental Health Act">Mental Health Act</option>
						<option value="MAPA">MAPA</option>
						<option value="MHA/MCA Awareness">MHA/MCA Awareness</option>
						<option value="Moving & Handling">Moving & Handling</option>
						<option value="News 2">News 2</option>
						<option value="Observations">Observations</option>
						<option value="Oliver McGowan LD & Autism">Oliver McGowan LD & Autism</option>
						<option value="OMLDA">OMLDA</option>
						<option value="Patient Safety Level 1">Patient Safety Level 1</option>
						<option value="Person Centered Care">Person Centered Care</option>
						<option value="PMVA">PMVA</option>
						<option value="POCA">POCA</option>
						<option value="Prevent Radicalisation">Prevent Radicalisation</option>
						<option value="Relational Security">Relational Security</option>
						<option value="Riddor">Riddor</option>
						<option value="Risk Assessment">Risk Assessment</option>
						<option value="Safeguarding (adults and children Level 3)">
							Safeguarding (adults and children Level 3){' '}
						</option>
						<option value="SI (compliant with Mental Health Units Use of Force 2018 Act)">
							SI (compliant with Mental Health Units Use of Force 2018 Act)
						</option>
						<option value="SOVA">SOVA</option>
						<option value="SOVA & SOCA level 3">SOVA & SOCA level 3</option>
						<option value="Working of Height">Working of Height</option>
						<option value="Working with those who self-harm">Working with those who self-harm</option>
					</select>
				</div>
				<div className="flex space-x-4">
					<div className="w-1/2">
						<label for="datetrainined" class="form-label">
							Date Trained
						</label>
						<br />
						<input
							className="w-full"
							type="date"
							name="datetrained"
							id="datetrained"
							onChange={handleChange('datetrained')}
							required
						/>
					</div>
					<div className="w-1/2">
						<label for="expirydate" class="form-label">
							Expiry Date
						</label>
						<br />
						<input
							className="w-full"
							type="date"
							name="expirydate"
							id="expirydate"
							onChange={handleChange('expirydate')}
							required
						/>
					</div>
				</div>
				<div className="w-full">
					<label for="image" class="form-label">
						Training Certificate
					</label>
					<br />
					<input values={image} type="file" name="image" id="image" onChange={onImageChange} />
				</div>
			</div>
		);
	};

	return (
		<React.Fragment>
			<div className="space-y-4">
				<button
					onClick={() => setIsOpen(true)}
					className="font-semibold shadow   rounded-lg  bg-main hover:bg-main2 py-2 px-5 text-white">
					New Training
				</button>
				<div style={{ maxWidth: '100%' }}>
					<link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
					<ThemeProvider theme={defaultMaterialTheme}>
						<MaterialTable
							columns={[
								{ title: 'Name', field: 'name' },
								{ title: 'Date Trained', field: 'datetrained', type: 'date' },
								{ title: 'Expiry Date', field: 'expirydate', type: 'date' },
								{ title: 'Verified', field: 'verified' },
							]}
							data={trainings}
							options={{
								actionsColumnIndex: -1,
								rowStyle: (rowData) => {
									return rowData.status === 'Expired'
										? {
												padding: '0 1rem',
												textAlign: 'center',
												backgroundColor: '#ffcccb',
										  }
										: { padding: '0 1rem', textAlign: 'center' };
								},
								headerStyle: {
									padding: '0 1rem',
									fontWeight: 'bold',
									fontSize: '1.3rem',
								},
								pageSizeOptions: [10, 20, 50],
								pageSize: 10,
							}}
							actions={[
								(rowData) => ({
									icon: 'check',
									tooltip: 'Verify',
									hidden: hideVerified(rowData),
									onClick: (event, rowData) => {
										dispatch(verifyTraining(rowData._id));
										toast.success(`Training verified successfully`);
									},
								}),
								(rowData) => ({
									icon: 'clear',
									tooltip: 'Revoke',
									hidden: hideUnverified(rowData),
									onClick: (event, rowData) => {
										dispatch(revokeTraining(rowData._id));
										toast.success(`Training revoked successfully`);
									},
								}),
								(rowData) => ({
									icon: 'save_alt',
									tooltip: 'View Certificate',
									onClick: (event, rowData) => {
										window.open(rowData.certificate.path);
									},
									hidden: !rowData.certificate,
								}),
								{
									icon: 'delete',
									tooltip: 'Delete Training',
									onClick: (event, rowData) => {
										removeTraining(userCopy._id, rowData._id);
									},
								},
							]}
							title="All Trainings"
						/>
					</ThemeProvider>
				</div>
				<Modal
					open={isOpen}
					closeModal={() => {
						setIsOpen(false);
					}}
					title={'Adding New Training'}
					body={body()}
					submitForm={handleSubmit.bind(this)}
				/>
			</div>
		</React.Fragment>
	);
}

export default Trainings;
