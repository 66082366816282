import React, { useEffect } from "react";
import { GrClose } from "react-icons/gr";
import { useSelector } from "react-redux";

function Modal({ closeModal, body, title, submitForm, open }) {
  const { profile, isLoading } = useSelector((state) => state.profile);

  useEffect(() => {
    if (isLoading) {
      return null;
    }
  }, [profile]);

  if (!open) return null;
  return (
    <div className="   fixed flex justify-center items-center  w-full sm:-left-10 sm:-top-16 left-8 z-50  ">
      <div className=" bg-white shadow-md sm:w-3/4 p-5 space-y-5 border-black border-1 ">
        <div className="text-2xl ">
          <GrClose
            onClick={() => closeModal(false)}
            className="hover:scale-110"
          />
        </div>

        <div className="text-center text-2xl bold">{title}</div>
        <form onSubmit={submitForm}>
          <div className="p-2">{body}</div>
          <div className=" sm:flex sm:space-x-3 space-y-3 sm:space-y-0 justify-center mx-auto">
            <div className="sm:w-1/2">
              <button
                onClick={() => {
                  closeModal(false);
                }}
                className="font-semibold shadow  w-full   rounded-lg  bg-main2 hover:bg-main py-2 px-3 text-[#FFF]"
              >
                Cancel
              </button>
            </div>

            <div className="sm:w-1/2">
              <button
                onClick={() => submitForm()}
                className="font-semibold shadow w-full   rounded-lg  bg-main hover:bg-main2 py-2 px-3 text-[#FFF]"
              >
                Continue
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Modal;
