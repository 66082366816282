import React, { useEffect, useState } from "react";
import { register } from "../features/Auth/userSlice";
import Spinner from "../components/UI/Spinner";
import { getOrganisationReg, reset } from "../features/Organisation/organisationSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

function ShortRegistrationPage() {
  const { id } = useParams();

  const orgId = id.toUpperCase();

  const [image, setImage] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    phone: "",
    email: "",
    organisation: orgId,
  });

  const handleChange = (input) => (e) => {
    setFormData({ ...formData, [input]: e.target.value });
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.user);
  const { organisation } = useSelector((state) => state.organisation);

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
    if (isSuccess || user) {
      navigate("/");
    }

    dispatch(getOrganisationReg(id));
    dispatch(reset());
  }, [user, isLoading, isError, isSuccess, message, dispatch, navigate]);

  const HandleSubmit = (e) => {
    e.preventDefault();

    const userData = {
      name: formData.name.charAt(0).toUpperCase() + formData.name.slice(1),
      surname: formData.surname.charAt(0).toUpperCase() + formData.surname.slice(1),
      email: formData.email,
      phone: formData.phone,
      organisation: formData.organisation,
    };

    dispatch(register(userData));
  };

  if (!organisation) {
    return <Spinner />;
  }

  const data = organisation.organisation;
  return (
    <React.Fragment>
      <section className="flex items-center justify-center mt-1 p-4 sm:w-full">
        <div className="space-y-3 sm:w-full">
          <div className="d-flex justify-center">
            {data.image && (
              <img
                src={data.image.path}
                alt="{data.organisation} logo"
                srcSet=""
                width="200px"
                height="auto"
                crossOrigin="anonymous"
              />
            )}
          </div>
          <h2 className="text-center text-xl font-bold">{data.organisationName} Register.</h2>
          <p className="text-center">Powered by Compliance Aide</p>
          <form className="space-y-3 sm:px-4" onSubmit={HandleSubmit}>
            <div>
              <p className="text-center">
                Please note all fields with a <span className="text-red">*</span> are required
              </p>

              <div className="sm:flex  space-y-3 sm:space-y-0 sm:space-x-3">
                <div className="w-full sm:w-1/2">
                  <label className=" text-xl" htmlFor="name">
                    First name<span className="text-red">*</span>
                  </label>
                  <br />
                  <input
                    className="w-full"
                    type="text"
                    name="name"
                    id="name"
                    required
                    onChange={handleChange("name")}
                    value={formData.name}
                  />
                </div>
                <div className="w-full sm:w-1/2">
                  <label className=" text-xl" htmlFor="surname">
                    Surname<span className="text-red">*</span>
                  </label>
                  <br />
                  <input
                    className="w-full"
                    type="text"
                    name="surname"
                    id="surname"
                    required
                    onChange={handleChange("surname")}
                    value={formData.surname}
                  />
                </div>
              </div>
              <div className="sm:flex  space-y-3 sm:space-y-0 sm:space-x-3">
                <div className="w-full sm:w-1/2">
                  <label className=" text-xl" htmlFor="email">
                    Email Address<span className="text-red">*</span>
                  </label>
                  <br />
                  <input
                    className="w-full"
                    type="email"
                    name="email"
                    id="email"
                    required
                    onChange={handleChange("email")}
                    value={formData.email}
                  />
                </div>
                <div className="w-full sm:w-1/2">
                  <label className=" text-xl" htmlFor="phone">
                    UK contact number<span className="text-red">*</span>
                  </label>
                  <br />
                  <input
                    className="w-full"
                    type="tel"
                    name="phone"
                    id="phone"
                    required
                    onChange={handleChange("phone")}
                    value={formData.phone}
                  />
                </div>
              </div>
            </div>

            <div className=" flex space-x-3">
              <button
                className="w-full font-semibold text-white bg-main2  hover:bg-main px-3 py-3 rounded shadow-sm"
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </section>
    </React.Fragment>
  );
}

export default ShortRegistrationPage;
