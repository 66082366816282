import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editStaff, reset } from "../../features/Profile/profileSlice";

function EditProfile({ close }) {
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.profile);
  const staff = profile.user;
  const { user } = useSelector((state) => state.user);
  useEffect(() => {
    return () => {
      reset();
    };
  }, [profile]);

  const [formData, setFormData] = useState({
    name: staff.name,
    surname: staff.surname,
    email: staff.email,
    phone: staff.phone,
    ninumber: staff.staff.ninumber,
    nationality: staff.staff.nationality,
    gender: staff.staff.gender,
    dateofbirth: staff.staff.dateofbirth,
    role: staff.staff.role,
    nmcnumber: staff.staff.nmcnumber,
    nmcexpiry: staff.staff.nmcexpiry,
    visanumber: staff.staff.visanumber,
    visarestrictions: staff.staff.visarestrictions,
    visaexpiry: staff.staff.visaexpiry,
    houseaddress: staff.staff.houseaddress,
    streetaddress: staff.staff.streetaddress,
    city: staff.staff.city,
    province: staff.staff.province,
    country: "UK",
    zip: staff.staff.zip,
    proofOfId: staff.staff.proofOfId,
    dbsservice: staff.staff.dbsservice,
    dbscert: staff.staff.dbscert,
    dbsnumber: staff.staff.dbsnumber,
    dbslevel: staff.staff.dbslevel,
    dbsissue: staff.staff.dbsissue,
    righttowork: staff.staff.righttowork,
    region: staff.staff.region,
    passportissue: staff.staff.passportissue,
    passportnumber: staff.staff.passportnumber,
    passportstart: staff.staff.passportstart,
    passportexpiry: staff.staff.passportexpiry,
  });

  const handleChange = (input) => (e) => {
    setFormData({ ...formData, [input]: e.target.value });
  };

  const HandleSubmit = (e) => {
    e.preventDefault();

    const userData = {
      id: staff._id,
      name: formData.name.charAt(0).toUpperCase() + formData.name.slice(1),
      surname:
        formData.surname.charAt(0).toUpperCase() + formData.surname.slice(1),
      email: formData.email,
      phone: formData.phone,

      staff: {
        role: formData.role,
        dateofbirth: formData.dateofbirth,
        gender: formData.gender,
        nationality: formData.nationality,
        nmcnumber: formData.nmcnumber,
        nmcexpiry: formData.nmcexpiry,
        proofOfId: formData.proofOfId,
        houseaddress: formData.houseaddress,
        streetaddress: formData.streetaddress,
        city: formData.city,
        province: formData.province,
        country: formData.country,
        zip: formData.zip,
        region: formData.region,
        righttowork: formData.righttowork,
        righttoworkproof: formData.righttoworkproof,
        passportnumber: formData.passportnumber,
        passportstart: formData.passportstart,
        passportissue: formData.passportissue,
        passportexpiry: formData.passportexpiry,
        visanumber: formData.visanumber,
        visaexpiry: formData.visaexpiry,
        visarestrictions: formData.visarestrictions,
        ninumber: formData.ninumber,
        dbscert: formData.dbscert,
        dbsservice: formData.dbsservice,
        dbsnumber: formData.dbsnumber,
        dbsissue: formData.dbsissue,

        dbslevel: formData.dbslevel,
      },
    };

    dispatch(editStaff(userData));

    close();
  };

  let idLabel;
  let idIssueOffice;

  switch (formData.proofOfId) {
    case "Passport":
      idLabel = "Passport number";
      break;
    case "ID":
      idLabel = "ID number";
      break;
    case "Drivers license":
      idLabel = "Drivers license number";
      break;

    default:
      idLabel = "Select ID type";
      break;
  }

  switch (formData.proofOfId) {
    case "Passport":
      idIssueOffice = "Passport issue office";
      break;
    case "ID":
      idIssueOffice = "ID issue office";
      break;
    case "Drivers license":
      idIssueOffice = "Drivers license issue office";
      break;

    default:
      idIssueOffice = "Select ID type";
      break;
  }

  return (
    <div className="space-y-4 w-full">
      <h1 className="text-2xl text-center font-bold">
        Editing {staff.name} {staff.surname}'s details
      </h1>
      <form onSubmit={HandleSubmit} className="space-y-2">
        <h2 className="text-center ">
          Please make sure all relevant field are completed.
        </h2>
        <div className="sm:flex sm:space-x-3 space-y-3 sm:space-y-0">
          <div className="sm:w-1/2">
            <label className="font-semibold text-xl" htmlFor="name">
              First name
            </label>
            <br />
            <input
              className="w-full"
              type="text"
              name="name"
              id="name"
              onChange={handleChange("name")}
              value={formData.name}
            />
          </div>
          <div className="sm:w-1/2">
            <label className="font-semibold text-xl" htmlFor="surname">
              Surname
            </label>
            <br />
            <input
              className="w-full"
              type="text"
              name="surname"
              id="surname"
              onChange={handleChange("surname")}
              value={formData.surname}
            />
          </div>
        </div>
        <div className="sm:flex sm:space-x-3 space-y-3 sm:space-y-0">
          <div className="sm:w-1/2">
            <label className="font-semibold text-xl" htmlFor="email">
              Email
            </label>
            <br />
            <input
              className="w-full"
              type="email"
              name="email"
              id="email"
              onChange={handleChange("email")}
              value={formData.email}
            />
          </div>
          <div className="sm:w-1/2">
            <label className="font-semibold text-xl" htmlFor="phone">
              UK Contact Number
            </label>
            <br />
            <input
              className="w-full"
              type="text"
              name="phone"
              id="phone"
              onChange={handleChange("phone")}
              value={formData.phone}
            />
          </div>
        </div>
        <div className="sm:flex sm:space-x-3 space-y-3 sm:space-y-0">
          <div className="sm:w-1/2">
            <label className="font-semibold text-xl" htmlFor="ninumber">
              NI Number
            </label>
            <br />
            <input
              className="w-full"
              type="text"
              name="ninumber"
              id="ninumber"
              onChange={handleChange("ninumber")}
              value={formData.ninumber}
            />
          </div>
          <div className="sm:w-1/2">
            <label className="font-semibold text-xl" htmlFor="nationality">
              Nationality
            </label>
            <br />

            <select
              name="nationality"
              id="nationality"
              onChange={handleChange("nationality")}
              className="w-full"
            >
              <option selected value={formData.nationality}>
                {formData.nationality}
              </option>
              <option value="Afghanistan">Afghanistan</option>
              <option value="Åland Islands">Åland Islands</option>
              <option value="Albania">Albania</option>
              <option value="Algeria">Algeria</option>
              <option value="American Samoa">American Samoa</option>
              <option value="Andorra">Andorra</option>
              <option value="Angola">Angola</option>
              <option value="Anguilla">Anguilla</option>
              <option value="Antarctica">Antarctica</option>
              <option value="Antigua and Barbuda">Antigua and Barbuda</option>
              <option value="Argentina">Argentina</option>
              <option value="Armenia">Armenia</option>
              <option value="Aruba">Aruba</option>
              <option value="Australia">Australia</option>
              <option value="Austria">Austria</option>
              <option value="Azerbaijan">Azerbaijan</option>
              <option value="Bahamas">Bahamas</option>
              <option value="Bahrain">Bahrain</option>
              <option value="Bangladesh">Bangladesh</option>
              <option value="Barbados">Barbados</option>
              <option value="Belarus">Belarus</option>
              <option value="Belgium">Belgium</option>
              <option value="Belize">Belize</option>
              <option value="Benin">Benin</option>
              <option value="Bermuda">Bermuda</option>
              <option value="Bhutan">Bhutan</option>
              <option value="Bolivia">Bolivia</option>
              <option value="Bosnia and Herzegovina">
                Bosnia and Herzegovina
              </option>
              <option value="Botswana">Botswana</option>
              <option value="Bouvet Island">Bouvet Island</option>
              <option value="Brazil">Brazil</option>
              <option value="British Indian Ocean Territory">
                British Indian Ocean Territory
              </option>
              <option value="Brunei Darussalam">Brunei Darussalam</option>
              <option value="Bulgaria">Bulgaria</option>
              <option value="Burkina Faso">Burkina Faso</option>
              <option value="Burundi">Burundi</option>
              <option value="Cambodia">Cambodia</option>
              <option value="Cameroon">Cameroon</option>
              <option value="Canada">Canada</option>
              <option value="Cape Verde">Cape Verde</option>
              <option value="Cayman Islands">Cayman Islands</option>
              <option value="Central African Republic">
                Central African Republic
              </option>
              <option value="Chad">Chad</option>
              <option value="Chile">Chile</option>
              <option value="China">China</option>
              <option value="Christmas Island">Christmas Island</option>
              <option value="Cocos (Keeling) Islands">
                Cocos (Keeling) Islands
              </option>
              <option value="Colombia">Colombia</option>
              <option value="Comoros">Comoros</option>
              <option value="Congo">Congo</option>
              <option value="Congo, The Democratic Republic of The">
                Congo, The Democratic Republic of The
              </option>
              <option value="Cook Islands">Cook Islands</option>
              <option value="Costa Rica">Costa Rica</option>
              <option value="Cote D'ivoire">Cote D'ivoire</option>
              <option value="Croatia">Croatia</option>
              <option value="Cuba">Cuba</option>
              <option value="Cyprus">Cyprus</option>
              <option value="Czech Republic">Czech Republic</option>
              <option value="Denmark">Denmark</option>
              <option value="Djibouti">Djibouti</option>
              <option value="Dominica">Dominica</option>
              <option value="Dominican Republic">Dominican Republic</option>
              <option value="Ecuador">Ecuador</option>
              <option value="Egypt">Egypt</option>
              <option value="El Salvador">El Salvador</option>
              <option value="Equatorial Guinea">Equatorial Guinea</option>
              <option value="Eritrea">Eritrea</option>
              <option value="Estonia">Estonia</option>
              <option value="Ethiopia">Ethiopia</option>
              <option value="Falkland Islands (Malvinas)">
                Falkland Islands (Malvinas)
              </option>
              <option value="Faroe Islands">Faroe Islands</option>
              <option value="Fiji">Fiji</option>
              <option value="Finland">Finland</option>
              <option value="France">France</option>
              <option value="French Guiana">French Guiana</option>
              <option value="French Polynesia">French Polynesia</option>
              <option value="French Southern Territories">
                French Southern Territories
              </option>
              <option value="Gabon">Gabon</option>
              <option value="Gambia">Gambia</option>
              <option value="Georgia">Georgia</option>
              <option value="Germany">Germany</option>
              <option value="Ghana">Ghana</option>
              <option value="Gibraltar">Gibraltar</option>
              <option value="Greece">Greece</option>
              <option value="Greenland">Greenland</option>
              <option value="Grenada">Grenada</option>
              <option value="Guadeloupe">Guadeloupe</option>
              <option value="Guam">Guam</option>
              <option value="Guatemala">Guatemala</option>
              <option value="Guernsey">Guernsey</option>
              <option value="Guinea">Guinea</option>
              <option value="Guinea-bissau">Guinea-bissau</option>
              <option value="Guyana">Guyana</option>
              <option value="Haiti">Haiti</option>
              <option value="Heard Island and Mcdonald Islands">
                Heard Island and Mcdonald Islands
              </option>
              <option value="Holy See (Vatican City State)">
                Holy See (Vatican City State)
              </option>
              <option value="Honduras">Honduras</option>
              <option value="Hong Kong">Hong Kong</option>
              <option value="Hungary">Hungary</option>
              <option value="Iceland">Iceland</option>
              <option value="India">India</option>
              <option value="Indonesia">Indonesia</option>
              <option value="Iran, Islamic Republic of">
                Iran, Islamic Republic of
              </option>
              <option value="Iraq">Iraq</option>
              <option value="Ireland">Ireland</option>
              <option value="Isle of Man">Isle of Man</option>
              <option value="Israel">Israel</option>
              <option value="Italy">Italy</option>
              <option value="Jamaica">Jamaica</option>
              <option value="Japan">Japan</option>
              <option value="Jersey">Jersey</option>
              <option value="Jordan">Jordan</option>
              <option value="Kazakhstan">Kazakhstan</option>
              <option value="Kenya">Kenya</option>
              <option value="Kiribati">Kiribati</option>
              <option value="Korea, Democratic People's Republic of">
                Korea, Democratic People's Republic of
              </option>
              <option value="Korea, Republic of">Korea, Republic of</option>
              <option value="Kuwait">Kuwait</option>
              <option value="Kyrgyzstan">Kyrgyzstan</option>
              <option value="Lao People's Democratic Republic">
                Lao People's Democratic Republic
              </option>
              <option value="Latvia">Latvia</option>
              <option value="Lebanon">Lebanon</option>
              <option value="Lesotho">Lesotho</option>
              <option value="Liberia">Liberia</option>
              <option value="Libyan Arab Jamahiriya">
                Libyan Arab Jamahiriya
              </option>
              <option value="Liechtenstein">Liechtenstein</option>
              <option value="Lithuania">Lithuania</option>
              <option value="Luxembourg">Luxembourg</option>
              <option value="Macao">Macao</option>
              <option value="Macedonia, The Former Yugoslav Republic of">
                Macedonia, The Former Yugoslav Republic of
              </option>
              <option value="Madagascar">Madagascar</option>
              <option value="Malawi">Malawi</option>
              <option value="Malaysia">Malaysia</option>
              <option value="Maldives">Maldives</option>
              <option value="Mali">Mali</option>
              <option value="Malta">Malta</option>
              <option value="Marshall Islands">Marshall Islands</option>
              <option value="Martinique">Martinique</option>
              <option value="Mauritania">Mauritania</option>
              <option value="Mauritius">Mauritius</option>
              <option value="Mayotte">Mayotte</option>
              <option value="Mexico">Mexico</option>
              <option value="Micronesia, Federated States of">
                Micronesia, Federated States of
              </option>
              <option value="Moldova, Republic of">Moldova, Republic of</option>
              <option value="Monaco">Monaco</option>
              <option value="Mongolia">Mongolia</option>
              <option value="Montenegro">Montenegro</option>
              <option value="Montserrat">Montserrat</option>
              <option value="Morocco">Morocco</option>
              <option value="Mozambique">Mozambique</option>
              <option value="Myanmar">Myanmar</option>
              <option value="Namibia">Namibia</option>
              <option value="Nauru">Nauru</option>
              <option value="Nepal">Nepal</option>
              <option value="Netherlands">Netherlands</option>
              <option value="Netherlands Antilles">Netherlands Antilles</option>
              <option value="New Caledonia">New Caledonia</option>
              <option value="New Zealand">New Zealand</option>
              <option value="Nicaragua">Nicaragua</option>
              <option value="Niger">Niger</option>
              <option value="Nigeria">Nigeria</option>
              <option value="Niue">Niue</option>
              <option value="Norfolk Island">Norfolk Island</option>
              <option value="Northern Mariana Islands">
                Northern Mariana Islands
              </option>
              <option value="Norway">Norway</option>
              <option value="Oman">Oman</option>
              <option value="Pakistan">Pakistan</option>
              <option value="Palau">Palau</option>
              <option value="Palestinian Territory, Occupied">
                Palestinian Territory, Occupied
              </option>
              <option value="Panama">Panama</option>
              <option value="Papua New Guinea">Papua New Guinea</option>
              <option value="Paraguay">Paraguay</option>
              <option value="Peru">Peru</option>
              <option value="Philippines">Philippines</option>
              <option value="Pitcairn">Pitcairn</option>
              <option value="Poland">Poland</option>
              <option value="Portugal">Portugal</option>
              <option value="Puerto Rico">Puerto Rico</option>
              <option value="Qatar">Qatar</option>
              <option value="Reunion">Reunion</option>
              <option value="Romania">Romania</option>
              <option value="Russian Federation">Russian Federation</option>
              <option value="Rwanda">Rwanda</option>
              <option value="Saint Helena">Saint Helena</option>
              <option value="Saint Kitts and Nevis">
                Saint Kitts and Nevis
              </option>
              <option value="Saint Lucia">Saint Lucia</option>
              <option value="Saint Pierre and Miquelon">
                Saint Pierre and Miquelon
              </option>
              <option value="Saint Vincent and The Grenadines">
                Saint Vincent and The Grenadines
              </option>
              <option value="Samoa">Samoa</option>
              <option value="San Marino">San Marino</option>
              <option value="Sao Tome and Principe">
                Sao Tome and Principe
              </option>
              <option value="Saudi Arabia">Saudi Arabia</option>
              <option value="Senegal">Senegal</option>
              <option value="Serbia">Serbia</option>
              <option value="Seychelles">Seychelles</option>
              <option value="Sierra Leone">Sierra Leone</option>
              <option value="Singapore">Singapore</option>
              <option value="Slovakia">Slovakia</option>
              <option value="Slovenia">Slovenia</option>
              <option value="Solomon Islands">Solomon Islands</option>
              <option value="Somalia">Somalia</option>
              <option value="South Africa">South Africa</option>
              <option value="South Georgia and The South Sandwich Islands">
                South Georgia and The South Sandwich Islands
              </option>
              <option value="Spain">Spain</option>
              <option value="Sri Lanka">Sri Lanka</option>
              <option value="Sudan">Sudan</option>
              <option value="Suriname">Suriname</option>
              <option value="Svalbard and Jan Mayen">
                Svalbard and Jan Mayen
              </option>
              <option value="Swaziland">Swaziland</option>
              <option value="Sweden">Sweden</option>
              <option value="Switzerland">Switzerland</option>
              <option value="Syrian Arab Republic">Syrian Arab Republic</option>
              <option value="Taiwan, Province of China">
                Taiwan, Province of China
              </option>
              <option value="Tajikistan">Tajikistan</option>
              <option value="Tanzania, United Republic of">
                Tanzania, United Republic of
              </option>
              <option value="Thailand">Thailand</option>
              <option value="Timor-leste">Timor-leste</option>
              <option value="Togo">Togo</option>
              <option value="Tokelau">Tokelau</option>
              <option value="Tonga">Tonga</option>
              <option value="Trinidad and Tobago">Trinidad and Tobago</option>
              <option value="Tunisia">Tunisia</option>
              <option value="Turkey">Turkey</option>
              <option value="Turkmenistan">Turkmenistan</option>
              <option value="Turks and Caicos Islands">
                Turks and Caicos Islands
              </option>
              <option value="Tuvalu">Tuvalu</option>
              <option value="Uganda">Uganda</option>
              <option value="Ukraine">Ukraine</option>
              <option value="United Arab Emirates">United Arab Emirates</option>
              <option value="United Kingdom">United Kingdom</option>
              <option value="United States">United States</option>
              <option value="United States Minor Outlying Islands">
                United States Minor Outlying Islands
              </option>
              <option value="Uruguay">Uruguay</option>
              <option value="Uzbekistan">Uzbekistan</option>
              <option value="Vanuatu">Vanuatu</option>
              <option value="Venezuela">Venezuela</option>
              <option value="Viet Nam">Viet Nam</option>
              <option value="Virgin Islands, British">
                Virgin Islands, British
              </option>
              <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
              <option value="Wallis and Futuna">Wallis and Futuna</option>
              <option value="Western Sahara">Western Sahara</option>
              <option value="Yemen">Yemen</option>
              <option value="Zambia">Zambia</option>
              <option value="Zimbabwe">Zimbabwe</option>
            </select>
          </div>
        </div>
        <div className="flex space-x-3">
          <div className="w-1/2">
            <label className="font-semibold text-xl" htmlFor="dateofbirth">
              Date of birth
            </label>
            <br />
            <input
              className="w-full"
              type="date"
              name="dateofbirth"
              id="dateofbirth"
              onChange={handleChange("dateofbirth")}
              value={new Date(formData.dateofbirth).toISOString().split("T")[0]}
            />
          </div>
          <div className="w-1/2">
            <label className="font-semibold text-xl" htmlFor="gender">
              Sex
            </label>
            <br />

            <select
              name="gender"
              id="gender"
              onChange={handleChange("gender")}
              className="w-full"
            >
              <option selected value={formData.gender}>
                {formData.gender}
              </option>
              <option value="Female">Female</option>
              <option value="Male">Male</option>
              <option value="Other">Other</option>
              <option value="I prefer not to say">I prefer not to say</option>
            </select>
          </div>
        </div>
        <div className="flex space-x-3">
          <div className="w-full">
            <label className="font-semibold text-xl" htmlFor="role">
              Role
            </label>
            <br />

            <select
              name="role"
              id="role"
              onChange={handleChange("role")}
              className="w-full"
            >
              <option selected value={formData.role}>
                {formData.role}
              </option>
              <option value="HCA">HCA</option>
              <option value="RGN">RGN</option>
              <option value="RMN">RMN</option>
            </select>
          </div>
        </div>
        {formData.role != "HCA" && (
          <div className="sm:flex sm:space-x-3 space-y-3 sm:space-y-0">
            <div className="sm:w-1/2">
              <label className="font-semibold text-xl" htmlFor="nmcnumber">
                NMC Number
              </label>
              <br />
              <input
                className="w-full"
                type="text"
                name="nmcnumber"
                id="nmcnumber"
                onChange={handleChange("nmcnumber")}
                value={formData.nmcnumber}
              />
            </div>
            <div className="sm:w-1/2">
              <label className="font-semibold text-xl" htmlFor="nmcexpiry">
                NMC expiry date
              </label>
              <br />
              <input
                className="w-full"
                type="date"
                name="nmcexpiry"
                id="nmcexpiry"
                onChange={handleChange("nmcexpiry")}
                value={
                  formData.nmcexpiry
                    ? new Date(formData.nmcexpiry).toISOString().split("T")[0]
                    : ""
                }
              />
            </div>
          </div>
        )}
        <div className="sm:flex sm:space-x-3 space-y-3 sm:space-y-0">
          <div className="sm:w-1/2">
            <label className="font-semibold text-xl" htmlFor="righttowork">
              Right to work
            </label>
            <br />

            <select
              name="righttowork"
              id="righttowork"
              onChange={handleChange("righttowork")}
              className="w-full"
            >
              <option selected value={formData.righttowork}>
                {formData.righttowork}
              </option>
              <option value="UK Citizen">UK Citizen</option>
              <option value="EU/EEA Citizen">EU/EEA Citizen</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div className="sm:w-1/2">
            <label className="font-semibold text-xl" htmlFor="region">
              Prefered region
            </label>
            <br />

            <select
              name="region"
              id="postcode"
              onChange={handleChange("region")}
              required
              className="w-full"
            >
              <option value={formData.region}>{formData.region}</option>
              <option selected>select</option>
              <option value="Eastern England">Eastern England</option>
              <option value="North East">North East</option>
              <option value="North West">North West</option>
              <option value="South East">South East</option>
              <option value="South West">South West</option>
              <option value="South Yorkshire">South Yorkshire</option>
              <option value="East Midlands">East Midlands</option>
              <option value="London">London</option>
              <option value="West Yorkshire">West Yorkshire</option>
              <option value="West Midlands">West Midlands</option>
              <option value="Wales">Wales</option>
              <option value="Wessex">Wessex</option>
            </select>
          </div>
        </div>
        {formData.righttowork != "UK Citizen" && (
          <div>
            <div className="sm:flex sm:space-x-3 space-y-3 sm:space-y-0">
              <div className="w-full">
                <label className="font-semibold text-xl" htmlFor="visanumber">
                  Visa Number
                </label>
                <br />

                <input
                  type="text"
                  name="visanumber"
                  id="visanumber"
                  onChange={handleChange("visanumber")}
                  value={formData.visanumber}
                  className="w-full"
                />
              </div>
              <div className="w-full">
                <label className="font-semibold text-xl" htmlFor="righttowork">
                  Visa Restrictions
                </label>
                <br />

                <select
                  name="visarestrictions"
                  id="visarestrictions"
                  onChange={handleChange("visarestrictions")}
                  className="w-full"
                >
                  <option selected value={formData.visarestrictions}>
                    {formData.visarestrictions}
                  </option>
                  <option value="N/A">N/A</option>

                  <option value="Other">Other</option>
                </select>
              </div>
            </div>
            <div className="sm:flex sm:space-x-3 space-y-3 sm:space-y-0">
              <div className="w-full">
                <label className="font-semibold text-xl" htmlFor="visaexpiry">
                  Visa Expiry
                </label>
                <br />

                <input
                  className="w-full"
                  type="date"
                  name="visaexpiry"
                  id="visaexpiry"
                  onChange={handleChange("visaexpiry")}
                  value={
                    new Date(formData.visaexpiry).toISOString().split("T")[0]
                  }
                />
              </div>
            </div>
          </div>
        )}
        <div className="sm:flex sm:space-x-3 space-y-3 sm:space-y-0">
          <div className="w-full">
            <label className="font-semibold text-xl" htmlFor="housenumber">
              House Number
            </label>
            <br />

            <input
              type="text"
              name="houseaddress"
              id="houseaddress"
              onChange={handleChange("houseaddress")}
              value={formData.houseaddress}
              className="w-full"
              autoComplete="off"
            />
          </div>
          <div className="w-full">
            <label className="font-semibold text-xl" htmlFor="streetaddress">
              Street name
            </label>
            <br />

            <input
              type="text"
              name="streetaddress"
              id="streetaddress"
              onChange={handleChange("streetaddress")}
              value={formData.streetaddress}
              className="w-full"
              autoComplete="off"
            />
          </div>
        </div>
        <div className="sm:flex sm:space-x-3 space-y-3 sm:space-y-0">
          <div className="w-full">
            <label className="font-semibold text-xl" htmlFor="city">
              City
            </label>
            <br />

            <input
              type="text"
              name="city"
              id="city"
              onChange={handleChange("city")}
              value={formData.city}
              className="w-full"
              autoComplete="off"
            />
          </div>
          <div className="w-full">
            <label className="font-semibold text-xl" htmlFor="province">
              County
            </label>
            <br />

            <input
              type="text"
              name="province"
              id="province"
              onChange={handleChange("province")}
              value={formData.province}
              className="w-full"
              autoComplete="off"
            />
          </div>
        </div>
        <div className="w-full">
          <label className="font-semibold text-xl" htmlFor="zip">
            Postal code
          </label>
          <br />

          <input
            type="text"
            name="zip"
            id="zip"
            onChange={handleChange("zip")}
            value={formData.zip}
            className="w-full"
            autoComplete="off"
          />
        </div>
        <div className="sm:flex sm:space-x-3 space-y-3 sm:space-y-0">
          <div className="w-full">
            <label className="font-semibold text-xl" htmlFor="proofOfId">
              Proof of ID
            </label>
            <br />
            {staff.staff.proofOfId && (
              <div className=" flex items-center ">
                <input
                  onChange={handleChange("proofOfId")}
                  type="radio"
                  name="proofOfId"
                  id="default"
                  value={formData.proofOfId}
                  className=" mr-2 cursor-pointer rounded-full"
                  checked
                />
                <label className="font-semibold text-xl" htmlFor="proofOfId">
                  {formData.proofOfId}
                </label>
              </div>
            )}

            <div className=" flex items-center ">
              <input
                onChange={handleChange("proofOfId")}
                type="radio"
                name="proofOfId"
                id="passport"
                value="Passport"
                className=" mr-2 cursor-pointer rounded-full"
              />
              <label className="font-semibold text-xl" htmlFor="proofOfId">
                Passport
              </label>
            </div>
            <div className=" flex items-center ">
              <input
                onChange={handleChange("proofOfId")}
                type="radio"
                name="proofOfId"
                id="Driverslicense"
                value="Drivers license"
                className=" mr-2 cursor-pointer rounded-full"
              />
              <label className="font-semibold text-xl" htmlFor="proofOfId">
                Driver's license
              </label>
            </div>
            <div className=" flex items-center ">
              <input
                onChange={handleChange("proofOfId")}
                type="radio"
                name="proofOfId"
                id="ID"
                value="ID"
                className=" mr-2 cursor-pointer rounded-full"
              />
              <label className="font-semibold text-xl" htmlFor="proofOfId">
                ID
              </label>
            </div>
          </div>
        </div>
        <div className="sm:flex sm:space-x-3 space-y-3 sm:space-y-0">
          <div className="w-full">
            <label className="font-semibold text-xl" htmlFor="passportnumber">
              {idLabel}
            </label>
            <br />

            <input
              type="text"
              name="passportnumber"
              id="passportnumber"
              onChange={handleChange("passportnumber")}
              value={formData.passportnumber}
              className="w-full"
              autoComplete="off"
            />
          </div>
          <div className="w-full">
            <label className="font-semibold text-xl" htmlFor="passportissue">
              {idIssueOffice}
            </label>
            <br />

            <input
              type="text"
              name="passportissue"
              id="passportissue"
              onChange={handleChange("passportissue")}
              value={formData.passportissue}
              className="w-full"
              autoComplete="off"
            />
          </div>
        </div>
        {formData.proofOfId === "Passport" && (
          <div className="sm:flex sm:space-x-3 space-y-3 sm:space-y-0">
            <div className="w-full">
              <label className="font-semibold text-xl" htmlFor="passportstart">
                Passport Issue Date
              </label>
              <br />

              <input
                type="date"
                name="passportstart"
                id="passportstart"
                onChange={handleChange("passportstart")}
                className="w-full"
                autoComplete="off"
                value={
                  formData.passportstart
                    ? new Date(formData.passportstart)
                        .toISOString()
                        .split("T")[0]
                    : ""
                }
              />
            </div>
            <div className="w-full">
              <label className="font-semibold text-xl" htmlFor="passportexpiry">
                Passport expiry
              </label>
              <br />

              <input
                type="date"
                name="passportexpiry"
                id="passportexpiry"
                onChange={handleChange("passportexpiry")}
                value={
                  formData.passportexpiry
                    ? new Date(formData.passportexpiry)
                        .toISOString()
                        .split("T")[0]
                    : ""
                }
                className="w-full"
                autoComplete="off"
              />
            </div>
          </div>
        )}
        <div className="sm:flex sm:space-x-3 space-y-3 sm:space-y-0">
          <div className="sm:w-1/2">
            <label className="font-semibold text-xl" htmlFor="dbscert">
              DBS available?
            </label>
            <br />

            <select
              name="dbscert"
              id="dbscert"
              onChange={handleChange("dbscert")}
              className="w-full"
            >
              <option value={formData.dbscert}>
                {formData.dbscert ? "Yes" : "No"}
              </option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </div>
          <div className="sm:w-1/2">
            <label className="font-semibold text-xl" htmlFor="dbsservice">
              DBS update service?
            </label>
            <br />

            <select
              name="dbsservice"
              id="dbsservice"
              onChange={handleChange("dbsservice")}
              className="w-full"
            >
              <option selected value={formData.dbsservice}>
                {formData.dbsservice ? "Yes" : "No"}
              </option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </div>
        </div>

        {formData.dbscert === true && (
          <div>
            <div className="sm:flex sm:space-x-3 space-y-3 sm:space-y-0">
              <div className="w-1/3">
                <label className="font-semibold text-xl" htmlFor="dbsnumber">
                  DBS number
                </label>
                <br />

                <input
                  type="text"
                  name="dbsnumber"
                  id="dbsnumber"
                  onChange={handleChange("dbsnumber")}
                  value={formData.dbsnumber}
                  className="w-full"
                  autoComplete="off"
                />
              </div>
              <div className="w-1/3">
                <label className="font-semibold text-xl" htmlFor="dbslevel">
                  DBS level
                </label>
                <br />

                <select
                  name="dbslevel"
                  id="dbslevel"
                  onChange={handleChange("dbslevel")}
                  className="w-full"
                >
                  <option selected value={formData.dbslevel}>
                    {formData.dbslevel}
                  </option>
                  <option value="Basic">Basic</option>
                  <option value="Standard">Standard</option>
                  <option value="Enhanced">Enhanced</option>
                </select>
              </div>
              <div className="w-1/3">
                <label className="font-semibold text-xl" htmlFor="dbsissue">
                  DBS valid from
                </label>
                <br />

                <input
                  type="date"
                  name="dbsissue"
                  id="dbsissue"
                  onChange={handleChange("dbsissue")}
                  className="w-full"
                  autoComplete="off"
                  value={
                    formData.dbsissue
                      ? new Date(formData.dbsissue).toISOString().split("T")[0]
                      : ""
                  }
                />
              </div>
            </div>
          </div>
        )}
        <div className="mt-5 flex space-x-4">
          <button
            className="w-full font-semibold text-white bg-main2 hover:scale-105 hover:bg-main px-3 py-3 rounded shadow-sm"
            type="button"
            onClick={close}
          >
            Cancel
          </button>
          <button
            className="w-full font-semibold text-white bg-main hover:scale-105 hover:bg-main2 px-3 py-3 rounded shadow-sm"
            type="submit"
          >
            Confirm
          </button>
        </div>
      </form>
    </div>
  );
}

export default EditProfile;
